import React from 'react';
import '../UBFC/UBFCCurated.css';
import { useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import limitIcons from '../../acess/images/NewIcons/limit.svg';
import Approve from '../../acess/images/NewIcons/approve.svg';
import Reject from '../../acess/images/NewIcons/reject.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import HomeData from '../common/HomeData';
import { Button, TextField } from '@mui/material';
import { useMemo } from 'react';
import { join } from 'lodash';
import UBFCAssignLimit from './UBFCAssignLimit';
import UBFCReject from './UBFCReject';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function UBFCCurateds({ isOpen }) {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState();
  const [search, setSearch] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openReject, setOpenReject] = useState(false);
  const handleRejectClose = () => setOpenReject(false);
  const [singleOrder, setSingleOrder] = useState();
  // =========================== Ayescore color code functions ==========================
  function pathColorselector(row) {
    if (row <= 120 && row > 0) {
      return '#dc3545';
    }
    if (row <= 200 && row > 221) {
      return '#ffc107';
    }
    if (row <= 280 && row > 201) {
      return '#17a2b8';
    }
    if (row <= 360 && row > 280) {
      return '#007bff';
    }
    if (row <= 460 && row > 361) {
      return '#28a745';
    }
  }

  function trailColorselector(row) {
    if (row <= 120 && row > 0) {
      return 'rgb(238 165 172)';
    }
    if (row <= 200 && row > 221) {
      return '#e6c769';
    }
    if (row <= 280 && row > 201) {
      return '#a4dae3';
    }
    if (row <= 360 && row > 280) {
      return '#9cbee3';
    }
    if (row <= 460 && row > 361) {
      return '#89eb9f';
    }
  }

  // ======================== Get UBFC Merchant List ==============================================================
  const getData = async () => {
    if (page === '') {
      return;
    }
    const query = [];
    if (search) {
      query.push(`search=${search}&`);
    }
    const res = await axios.get(
      `/merchant_details_main/curated?${
        query?.length ? query?.join('&') : ''
      }start=${page * perPage}&length=${perPage}`
    );
    if (res?.data) {
      console.log(res?.data);
      setData(res?.data?.data);
      setTotalRecords(Number(res?.data?.recordsTotal));
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [page]);

  // ----------pagination Handler--------------//
  const handlePrevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if ((page + 1) * perPage < totalRecords) {
      setPage(page + 1);
    }
  };
  const showingRecords = useMemo(() => {
    const from = 1 + perPage * page;
    let to = perPage * (page + 1);
    if (totalRecords <= to) {
      to = totalRecords;
    }
    return `Showing ${from}-${to} of ${totalRecords}`;
  }, [perPage, page, totalRecords]);

  // ========================== View Documents for Merchant
  const orderClickHandalr = (id) => {
    navigate(`/ubfc/curatedmerchant/merchentDocuments/${id}`);
  };

  // ========================== Search Function =============================
  const searchHandler = (e) => {
    e.persist();
    setTimeout(() => {
      setSearch(e.target.value?.trim());
    }, 1500);
  };

  useEffect(() => {
    setPage('');
    setTimeout(() => {
      setPage(0);
    }, 100);
  }, [search]);
  const LimitHandler = (row) => {
    setOpen(true);
    setSingleOrder(row);
  };
  const RejectHandler = (row) => {
    console.log(openReject);
    setOpenReject(true);
    setSingleOrder(row);
  };
  //Number Calculater in Lac or Cr
  function amountFormat(value, options) {
    let nf = new Intl.NumberFormat('en-US');

    var val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lac';
    } else {
      val = nf.format(value);
    }
    return val;
  }
  return (
    <div
      style={{ paddingTop: '60px' }}
      className={
        isOpen
          ? 'ubfc-main-page-with-sidebar container-fluid pe-0 '
          : ' ubfc-main-page  container-fluid pe-3 '
      }
    >
      <div className="d-flex justify-content-between">
        <div className="m-1 ms-2 ">
          <div className="ubfc-merchant-title mt-3 fw-bolder ">
            Merchant Lists
          </div>
          <span className="order-tracker-sub-title">
            {' '}
            {totalRecords} orders found
          </span>
        </div>

        <div className="mb-4 mt-3">
          {/* <TextField
          label="Search by name.."
          onChange={(e) => searchHandler(e)}
        /> */}
          <div className="order-tracker-serach d-flex">
            <input
              type="text"
              placeholder="Search by name"
              style={{ borderColor: 'transparent', background: '#e3e5ea' }}
              onChange={(e) => searchHandler(e)}
            />
            <SearchIcon />
          </div>
        </div>
      </div>

      <TableContainer component={Paper} className="m-0 p-0 mx-0">
        <Table size="large" aria-label="a dense table">
          <TableHead style={{ color: '#fff' }} className="bg-slate-600">
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: '#fff',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell
                // align="center"
                style={{ color: '#fff' }}
                className="pt-3 pb-3"
              >
                <b>Merchant Id </b>
              </TableCell>
              <TableCell align="center" style={{ color: '#fff' }}>
                <b>Merchant Name</b>
              </TableCell>
              <TableCell align="center" style={{ color: '#fff' }}>
                <b>Merchant Type</b>
              </TableCell>
              <TableCell align="left" style={{ color: '#fff' }}>
                <b>Ayescore</b>
              </TableCell>
              <TableCell align="center" style={{ color: '#fff' }}>
                <b>Assigned limit</b>
              </TableCell>
              <TableCell align="center" style={{ color: '#fff' }}>
                <b>Action</b>
              </TableCell>
              {/* <TableCell align="center" style={{ color: '#fff' }}>
                <b>View</b>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <HomeData />
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
              </>
            ) : (
              <>
                {data?.map((row, index) => (
                  <TableRow
                    key={row.user_id}
                    className="p-0 m-0"
                    style={{
                      backgroundColor: index % 2 == 0 ? '#fff' : '#ededed',
                    }}
                  >
                    <TableCell>{row?.user_id}</TableCell>
                    <TableCell align="center" className="p-0 m-0">
                      {row?.business_name}
                    </TableCell>
                    <TableCell align="center" className="p-0 m-0">
                      {row?.user_category_name}
                    </TableCell>
                    <TableCell align="center" className="p-2 m-0">
                      <div style={{ width: 50, height: 50 }}>
                        <CircularProgressbar
                          value={row.aye_score}
                          size={500}
                          maxValue={460}
                          text={`${row.aye_score}`}
                          strokeWidth={15}
                          styles={buildStyles({
                            textColor: 'black',
                            pathColor: pathColorselector(row?.aye_score),
                            trailColor: trailColorselector(row?.aye_score),
                            width: '60px',
                          })}
                        />
                      </div>
                    </TableCell>

                    <TableCell align="center" className="p-0 m-0">
                      {row?.assign_credit_limit ? (
                        <div className="fw-bolder">
                          {' '}
                          <span className="m-2" style={{ color: 'green' }}>
                            ₹ {amountFormat(row?.assign_credit_limit)}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align="center" className="p-0 m-0">
                      <button
                        className="mt-2 p-1"
                        variant="contained"
                        style={{
                          width: '135px',
                          fontSize: '12px',
                          color: '#fff',
                          fontWeight: '600',
                          border: 'none',
                          borderRadius: '12px',
                          backgroundColor: '#3f6944',
                        }}
                        onClick={() => LimitHandler(row)}
                      >
                        {/* <img
                          className="me-2"
                          src={Approve}
                          alt=""
                          style={{ height: '16px', width: '18px' }}
                        /> */}
                        Accept
                      </button>
                      <br />
                      <button
                        className="mt-2 p-1"
                        variant="contained"
                        style={{
                          width: '135px',
                          fontSize: '12px',
                          color: '#fff',
                          fontWeight: '600',
                          border: 'none',
                          borderRadius: '12px',
                          backgroundColor: '#d16458',
                        }}
                        onClick={() => RejectHandler(row)}
                      >
                        {/* <img
                          className="me-2"
                          src={Reject}
                          alt=""
                          style={{ height: '16px', width: '18px' }}
                        /> */}
                        Reject{' '}
                      </button>
                      <br />
                      <button
                        // variant="contained"
                        className=" p-1 pe-3 ps-3 mt-2 mb-2"
                        style={{
                          width: '135px',
                          fontSize: '12px',
                          fontWeight: '600',
                          backgroundColor: '#212529',
                          color: '#fff',
                          borderRadius: '16px',
                          border: 'none',
                        }}
                        onClick={() => orderClickHandalr(row?.user_id)}
                      >
                        {/* <VisibilityIcon
                          style={{
                            color: '#fff',
                            fontSize: '18px',
                            marginRight: '4px',
                          }}
                        /> */}
                        {'  '}
                        View
                      </button>
                    </TableCell>
                    {/* <TableCell align="center" className="p-0 m-0">
                      <button
                        // variant="contained"
                        className=" p-1 pe-3 ps-3"
                        style={{
                          // backgroundImage:
                          //   'linear-gradient(to top, #224197, #7390BF)',
                          backgroundColor: '#060226',
                          color: '#fff',
                          borderRadius: '16px',
                          border: 'none',
                        }}
                        onClick={() => orderClickHandalr(row?.user_id)}
                      >
                        <VisibilityIcon
                          style={{
                            color: '#fff',
                            fontSize: '18px',
                            marginRight: '4px',
                          }}
                        />
                        {'  '}
                        View
                      </button>
                    </TableCell> */}
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {loading ? (
        ''
      ) : (
        <div style={{ display: 'flex', gap: '3' }} className="mx-3">
          <span
            style={{ color: '#87898e', margin: '20px 2px 2px 2px ' }}
            className="fw-bolder"
          >
            {showingRecords}
          </span>
          <button className="prev-next-btn" onClick={handlePrevPage}>
            Prev
          </button>
          <button className="prev-next-btn" onClick={handleNextPage}>
            Next
          </button>
        </div>
      )}
      {singleOrder ? (
        <>
          <UBFCAssignLimit
            open={open}
            handleClose={handleClose}
            singleOrder={singleOrder}
            getData={getData}
          />
        </>
      ) : (
        ''
      )}
      {singleOrder ? (
        <>
          <UBFCReject
            openReject={openReject}
            handleRejectClose={handleRejectClose}
            singleOrder={singleOrder}
            getData={getData}
          />
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default UBFCCurateds;
