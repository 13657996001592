import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid gray',
  boxShadow: 44,
  p: 0,
  borderRadius: '12px',
};

function UBFCAssignLimit({ open, handleClose, singleOrder, getData }) {
  console.log(singleOrder, open);
  const [limitData, setLimitData] = useState({
    assigin_credit_limit: '',
    user_id: singleOrder?.user_id ? singleOrder?.user_id : '',
    credit_limit_status: 'approved',
    Comments: 'UBFC assing limit',
  });
  const [limitError, setLimitError] = useState('');
  const token = localStorage.getItem('token');

  const assignLimitHandler = () => {
    if (limitData?.assigin_credit_limit) {
      axios
        .post(`/api/ubfc/ubfc_assign_limit`, limitData, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response?.data);
          toast.info(`${response?.data?.message}`, {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          handleClose('');
          setLimitData({ ...limitData, assigin_credit_limit: '', user_id: '' });
          setLimitError('');
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLimitError('Please enter assign limit');
    }
  };

  useEffect(() => {
    setLimitData({ ...limitData, user_id: singleOrder?.user_id });
  }, [singleOrder]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            className="fw-bolder text-center p-3"
            component="h5"
            style={{ backgroundColor: '#F3F3F3', color: '#100C5D' }}
          >
            Assign Limit
          </Typography>
          <hr className="p-0 m-0" />
          <div className="mt-2 ps-3 pe-3">
            <TextField
              classes="fw-bolder"
              type="number"
              placeholder="Enter limit"
              onChange={(e) =>
                setLimitData({
                  ...limitData,
                  assigin_credit_limit: e.target.value,
                })
              }
              fullWidth
              className="m-2"
              style={{ color: '#000' }}
            />
            <span style={{ color: 'red', fontSize: '12px' }} className="ms-2">
              {limitError}
            </span>
          </div>
          <Box className="m-2 d-flex justify-content-between " fullWidth>
            <Button
              variant="contained"
              className="mt-2  p-2 ps-5 pe-5 fw-bolder"
              style={{ backgroundColor: '#224197' }}
              onClick={assignLimitHandler}
            >
              Assign
            </Button>
            <Button
              variant="contained"
              className="mt-2   p-2 ps-5 pe-5 fw-bolder"
              style={{ backgroundColor: '#A22531' }}
              onClick={() => {
                handleClose();
                setLimitData({ ...limitData, assigin_credit_limit: '' });
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default UBFCAssignLimit;
