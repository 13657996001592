import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@material-ui/core/Button';
import { Box } from '@mui/material/';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import fileDownload from 'js-file-download';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ImageModel({ open, handleClose, imgAddress, imgName }) {
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {imgAddress ? (
          <Box sx={style}>
            <div className="d-flex justify-content-between">
              <h3 id="parent-modal-title">{imgName}</h3>

              <div>
                <ClearIcon onClick={handleClose} role="button" />
              </div>
            </div>
            <hr />
            <div className="row d-flex justify-content-center">
              <img
                src={imgAddress}
                alt=""
                style={{ width: '450px', height: '300px' }}
              />
            </div>

            <div className=" d-flex justify-content-center  pt-3">
              <Button
                style={{
                  borderRadius: '24.3px',
                  boxShadow: '0px 2px 4.8px 0.2px #000',
                  color: '#fff',
                  backgroundImage:
                    'linear-gradient(to top, #9bb0da, #617db6, #183d8a)',
                }}
                variant="outlined"
                onClick={() => {
                  handleDownload(imgAddress, `${imgName}.jpg`);
                }}
              >
                Download File
              </Button>
            </div>
          </Box>
        ) : (
          <h3>Missing file</h3>
        )}
      </Modal>
    </div>
  );
}

export default ImageModel;
