import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  //   border: "2px solid #000",
  boxShadow: 24,
  borderRadius: '12px',
  backgroundColor: '#F5F5F5',
  p: 4,
};
function ClientAssignLimit({
  openlimitModel,
  closeLimitModel,
  getAllData,
  camId,
}) {
  const [error, setError] = useState('');
  const [assignLimit, setAssignLimit] = useState(null);
  const [tenure, setTenure] = useState(null);
  console.log(camId, 'cam');
  const token = localStorage.getItem('token');

  const notify = () => {
    toast.success(' Approved Sucessfully', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  const isUser = () => {
    toast.error('Error', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  const yesApproveInprogress = () => {
    console.log(assignLimit, 'sddsffgv');
    console.log(camId, 'camid');
    if (!assignLimit) {
      setError('Please Enter Limit');
    } else if (!tenure) {
      setError('Please Enter Tenure in Days');
    } else {
      if (camId) {
        setError('');
        console.log(camId, 'camid');
        axios
          .patch(
            `/api/client_management/change_inprogess_status`,
            {
              user_id: camId,
              prospect_status: 'approved',
              assigin_credit_limit: assignLimit,
              tenure_in_days: tenure,
              Comments: 'just approved limit',
            },
            { headers: { Authorization: `Bearee ${token}` } }
          )
          .then((response) => {
            getAllData();
            closeLimitModel();
            setAssignLimit();
          })
          .catch((error) => {
            console.log(error);
            closeLimitModel();
          });
      }
    }
  };

  return (
    <div>
      <Modal
        open={openlimitModel}
        onClose={closeLimitModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2, textAlign: 'center' }}
          >
            <h2>Are You Sure, You Want To Approve This ?</h2>
          </Typography>

          <div className="flex flex-col items-center">
            <div>
              <p>Enter Assign Limit</p>
              <TextField
                type="number"
                placeholder="Assign limit .."
                onChange={(e) => setAssignLimit(e.target.value)}
                sx={{ mb: 2, fontSize: '15px' }}
              />
            </div>

            <div>
              <p>Enter Tenure in Days</p>
              <TextField
                type="number"
                placeholder="Enter Tenure"
                onChange={(e) => setTenure(e.target.value)}
              />
            </div>
          </div>

          <br />
          <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>
          <br />
          <div className="flex items-center justify-center">
            <Button
              variant="contained"
              onClick={closeLimitModel}
              sx={{ mr: 2 }}
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                yesApproveInprogress();
              }}
            >
              Yes, Approve it!
            </Button>
          </div>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default ClientAssignLimit;
