import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabsContext from '@mui/lab/TabContext';
import { TabList } from '@mui/lab';
import '../Client Management/clientManagement.css';
import Select from 'react-select';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  // Select,
  TextField,
} from '@mui/material';
import viewMapIcon from '../Client Management/Images/ViewmapIcon.svg';
import TabPanel from '@mui/lab/TabPanel';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useNavigate } from 'react-router-dom';
import excelIcon from '../Client Management/Images/ExcelIcon.svg';
import ClientTabel from './ClientTabel';
import MapPage from '../Client Management/MapPage';
function ClientManagement({ refreshHandler }) {
  const navigate = useNavigate();
  const options = [{ value: 'Program', label: 'Program' }];
  const options2 = [{ value: 'by_date', label: 'By Date' }];
  const [tabValue, setTabValue] = useState('prospects_orders');
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    // setAge(event.target.value);
  };
  const [from_date, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [search, setSearch] = useState();
  const [isFlag, setIsFlag] = useState(false);
  const [filtervalue, setFilterValue] = React.useState('available');
  const [filterType, setFilterType] = useState();
  const handleFilterChange = (event, newValue) => {
    console.log('-------------------', newValue, '----------------------');
    setFilterValue(newValue);
  };

  // ===================== Search function =========================
  const searchByName = (e) => {
    e.persist();
    // setLoading(true);
    setTimeout(() => {
      setSearch(e.target.value?.trim());
    }, 1500);
  };

  useEffect(() => {
    refreshHandler();
  }, []);
  useEffect(() => {
    if (tabValue === 'prospects_orders') {
      setFilterValue('available');
    }
    if (tabValue === 'In_progess_orders') {
      setFilterValue('wip');
    }
    if (tabValue === 'closed_orders') {
      setFilterValue('approved');
    }
  }, [tabValue]);

  useEffect(() => {
    const path = window.location.pathname;
    if (path?.includes('inProgress')) {
      setTabValue('In_progess_orders');
    }
  }, []);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '10px',
      borderColor: '#0C3094',
      backgroundColor: '#F5F5F5',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#0C3094',
      backgroundColor: '#F5F5F5',
    }),
  };
  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '10px',
      border: 'none',

      // borderColor: "gray",
      boxShadow: '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
      backgroundColor: '#FFF',
      fontWeight: '600',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'gray',
      backgroundColor: '#FFF',
    }),
  };
  const handleSelectChange = (selectedOption) => {
    // Handle selected option change here
    console.log(selectedOption);
  };
  const handleSelectChangeFilter = (selectedOption) => {
    // Handle selected option change here
    console.log(selectedOption?.value);
    setFilterType(selectedOption?.value);
  };

  console.log(from_date, toDate);
  return (
    <div
      className="dashboard-page-with-sidebar  container-fluid pe-0 me-5"
      style={{ paddingTop: '80px' }}
    >
      <div className="ms-0  ">
        <div className="">
          <div className="d-flex justify-content-between">
            {' '}
            <div className="mt-0">
              <h2 style={{ color: '#0C3094' }} className="text-3xl font-bold">
                Client Management{' '}
              </h2>
              <div className="text-muted mt-2" role="button">
                <span
                  onClick={() => navigate('/home')}
                  style={{ color: '#000' }}
                  className="fw-bolder"
                >
                  Home /{' '}
                </span>{' '}
                <span className="fw-bolder" style={{ color: '#0C3094' }}>
                  Client Management
                </span>
              </div>
            </div>
            <div className="mt-2 ms-2">
              {' '}
              <Select
                options={options}
                onChange={handleSelectChange}
                placeholder="Program Name"
                styles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Button
          variant="contained"
          className="tab-btn me-3"
          onClick={() => setTabValue('prospects_orders')}
          style={{
            backgroundColor:
              tabValue === 'prospects_orders' ? '#0C3094' : '#FFF',
            color: tabValue === 'prospects_orders' ? '#FFFFFF' : '#0C3094',
            borderRadius: '12px',
          }}
        >
          Prospect
        </Button>
        <Button
          variant="contained"
          className="tab-btn me-3"
          onClick={() => setTabValue('In_progess_orders')}
          style={{
            backgroundColor:
              tabValue === 'In_progess_orders' ? '#0C3094' : '#FFF',
            color: tabValue === 'In_progess_orders' ? '#FFFFFF' : '#0C3094',
            borderRadius: '12px',
          }}
        >
          In Progress
        </Button>
        <Button
          variant="contained"
          onClick={() => setTabValue('closed_orders')}
          style={{
            backgroundColor: tabValue === 'closed_orders' ? '#0C3094' : '#FFF',
            color: tabValue === 'closed_orders' ? '#FFFFFF' : '#0C3094',
            borderRadius: '12px',
          }}
        >
          Closed
        </Button>
      </div>
      {tabValue === 'prospects_orders' ? (
        <div className="ms-0 ps-0 mt-3">
          <TabsContext value={filtervalue} className="p-0 m-0 mb-0">
            <div className="d-flex justify-content-between">
              <div>
                <TabList
                  onChange={handleFilterChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Available"
                    value="available"
                    className="table-tab-text"
                  />
                  <Tab
                    label="Expired"
                    value="expired"
                    className="ms-3 table-tab-text"
                  />
                </TabList>
              </div>
              <div>
                {isFlag ? (
                  <>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#0C3094',
                        borderRadius: '12px',
                      }}
                      onClick={() => {
                        setIsFlag(false);
                      }}
                    >
                      <ReorderIcon className="me-2" /> List view
                    </Button>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    <img src={excelIcon} alt="" className="me-2 mt-2" />
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#0C3094',
                        borderRadius: '12px',
                      }}
                      onClick={() => {
                        setIsFlag(true);
                      }}
                    >
                      <img src={viewMapIcon} alg="" className="me-2" /> Map view
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <hr
              style={{
                height: '1px',
                color: '#000000',
              }}
              className="mt-0 "
            />
            {isFlag ? (
              ''
            ) : (
              <div className="mt-0 d-flex justify-content-between">
                <div className="d-flex mt-3">
                  {' '}
                  <div className="fw-bolder mt-1" style={{ fontSize: '16px' }}>
                    Ayescore Legends:
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#D22F4C',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                    >
                      Poor
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 0 to 120</span>
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#FFBF02',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                    >
                      Fair
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 121 to 200</span>
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#11A5AF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                    >
                      Good
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 201 to 280</span>
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#007CFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                    >
                      Very Good
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 281 to 360</span>
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#2DA446',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                      // className="p-2"
                    >
                      Excellent
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 361 to 460</span>
                  </div>
                </div>
              </div>
            )}
            {isFlag ? (
              ''
            ) : (
              <div className="d-flex justify-content-end">
                <div>
                  <div className="d-flex">
                    <div className="d-flex ">
                      <div style={{ display: 'flex', gap: '2' }}>
                        <span style={{ margin: '30px 5px 0 20px' }}>From:</span>
                        <div
                          className="p-0 m-0 mt-4"
                          style={{ boxSizing: 'none', padding: '0px 0px' }}
                        >
                          <input
                            className="p-2"
                            id="date"
                            type="date"
                            value={from_date}
                            onChange={(e) => {
                              setFromDate(e.target.value);
                            }}
                            style={{
                              margin: 1,
                              maxWidth: 170,
                              backgroundColor: '#fff',
                              border: 'none',
                              borderRadius: '10px',
                              boxShadow:
                                '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                              // border: "1px solid gray",
                            }}
                          />
                        </div>
                        {/* </Stack> */}
                      </div>

                      <div style={{ display: 'flex', gap: '2px' }}>
                        <span style={{ margin: '30px 5px 0 20px' }}>To: </span>

                        <div
                          style={{ boxSizing: 'none', padding: '0px 0px' }}
                          className="p-0 m-0 mt-4"
                        >
                          <input
                            className="p-2"
                            // id="date"
                            type="date"
                            value={toDate}
                            onChange={(e) => {
                              setToDate(e.target.value);
                            }}
                            style={{
                              margin: 1,
                              maxWidth: 170,
                              backgroundColor: '#fff',
                              border: 'none',
                              borderRadius: '10px',
                              boxShadow:
                                '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                              // border: "1px solid gray",
                            }}
                            // style={{ width: "20px" }}
                          />
                        </div>
                        {/* </Stack> */}
                        {from_date || toDate ? (
                          <Button
                            className="mt-4"
                            onClick={() => {
                              setFromDate(''), setToDate('');
                            }}
                          >
                            Clear date
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="ms-4 mt-4">
                        <input
                          className="p-2 fw-bolder"
                          style={{
                            margin: 1,
                            maxWidth: 170,
                            backgroundColor: '#fff',
                            border: 'none',
                            borderRadius: '10px',
                            boxShadow:
                              '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                            // border: "1px solid gray",
                          }}
                          placeholder="Search..."
                          // label="Search by name "
                          onChange={searchByName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <TabPanel
              className="mt-2 p-0"
              value="available"
              style={{ paddingTop: '0px' }}
            >
              {isFlag ? (
                <MapPage tabValue={tabValue} filtervalue={filtervalue} />
              ) : (
                <ClientTabel
                  tabValue={tabValue}
                  filtervalue={filtervalue}
                  search={search}
                  from_date={from_date}
                  toDate={toDate}
                />
              )}
            </TabPanel>
            <TabPanel
              className="mt-2 p-0"
              value="expired"
              style={{ paddingTop: '0px' }}
            >
              {isFlag ? (
                <MapPage tabValue={tabValue} filtervalue={filtervalue} />
              ) : (
                <ClientTabel
                  tabValue={tabValue}
                  filtervalue={filtervalue}
                  search={search}
                />
              )}
            </TabPanel>
          </TabsContext>
        </div>
      ) : (
        ''
      )}
      {tabValue === 'In_progess_orders' ? (
        <div className="ms-0 ps-0 mt-3">
          <TabsContext value={filtervalue} className="p-0 m-0 mb-0">
            <div className="d-flex justify-content-between">
              <div>
                <TabList
                  onChange={handleFilterChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="WIP" value="wip" className="table-tab-text" />
                  <Tab
                    label="Query"
                    value="query"
                    className="ms-3 table-tab-text"
                  />
                </TabList>
              </div>
              <div>
                {isFlag ? (
                  <>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#0C3094',
                        borderRadius: '12px',
                      }}
                      onClick={() => {
                        setIsFlag(false);
                      }}
                    >
                      <ReorderIcon className="me-2" /> List view
                    </Button>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    <img src={excelIcon} alt="" className="me-2 mt-2" />
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#0C3094',
                        borderRadius: '12px',
                      }}
                      onClick={() => {
                        setIsFlag(true);
                      }}
                    >
                      <img src={viewMapIcon} alg="" className="me-2" /> Map view
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <hr
              style={{
                height: '1px',
                color: '#000000',
              }}
              className="mt-0 "
            />
            {isFlag ? (
              ''
            ) : (
              <div className="mt-0 d-flex justify-content-between">
                <div className="d-flex mt-3">
                  {' '}
                  <div className="fw-bolder mt-1" style={{ fontSize: '16px' }}>
                    Ayescore Legends:
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#D22F4C',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                    >
                      Poor
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 0 to 120</span>
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#FFBF02',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                    >
                      Fair
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 121 to 200</span>
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#11A5AF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                    >
                      Good
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 201 to 280</span>
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#007CFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                    >
                      Very Good
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 281 to 360</span>
                  </div>
                  <div className="p-1 me-1">
                    <button
                      className="ps-1 pe-1"
                      style={{
                        backgroundColor: '#2DA446',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '14px',
                      }}
                      disabled
                      // className="p-2"
                    >
                      Excellent
                    </button>{' '}
                    <span style={{ fontSize: '14px' }}>Range 361 to 460</span>
                  </div>
                </div>
                <div>
                  {/* <div>
                    <div className="d-flex">
                      <div className="mt-0">
                        {" "}
                        <Select
                          className="pt-2 pb-2"
                          options={options2}
                          onChange={handleSelectChangeFilter}
                          placeholder="Filter by"
                          styles={customStyles2}
                        />
                      </div>
                      <div className="ms-2 mt-2">
                        <input
                          className="p-2 fw-bolder"
                          style={{
                            margin: 1,
                            maxWidth: 170,
                            backgroundColor: "#fff",
                            border: "none",
                            borderRadius: "10px",
                            boxShadow:
                              "0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)",
                            // border: "1px solid gray",
                          }}
                          placeholder="Search..."
                          // label="Search by name "
                          onChange={searchByName}
                        />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="d-flex">
                    <div className="ms-2">
                      <TextField
                        sx={{
                          m: 1,
                          // maxWidth: 120,
                          backgroundColor: "#fff",
                        }}
                        placeholder="Search by name..."
                        // label="Search by name "
                        onChange={searchByName}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            {isFlag ? (
              ''
            ) : (
              <div className="d-flex justify-content-end">
                <div>
                  <div className="d-flex">
                    <div className="d-flex ">
                      <div style={{ display: 'flex', gap: '2' }}>
                        <span style={{ margin: '30px 5px 0 20px' }}>From:</span>
                        <div
                          className="p-0 m-0 mt-4"
                          style={{ boxSizing: 'none', padding: '0px 0px' }}
                        >
                          <input
                            className="p-2"
                            id="date"
                            type="date"
                            value={from_date}
                            onChange={(e) => {
                              setFromDate(e.target.value);
                            }}
                            style={{
                              margin: 1,
                              maxWidth: 170,
                              backgroundColor: '#fff',
                              border: 'none',
                              borderRadius: '10px',
                              boxShadow:
                                '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                              // border: "1px solid gray",
                            }}
                          />
                        </div>
                        {/* </Stack> */}
                      </div>

                      <div style={{ display: 'flex', gap: '2px' }}>
                        <span style={{ margin: '30px 5px 0 20px' }}>To: </span>

                        <div
                          style={{ boxSizing: 'none', padding: '0px 0px' }}
                          className="p-0 m-0 mt-4"
                        >
                          <input
                            className="p-2"
                            // id="date"
                            type="date"
                            value={toDate}
                            onChange={(e) => {
                              setToDate(e.target.value);
                            }}
                            style={{
                              margin: 1,
                              maxWidth: 170,
                              backgroundColor: '#fff',
                              border: 'none',
                              borderRadius: '10px',
                              boxShadow:
                                '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                              // border: "1px solid gray",
                            }}
                            // style={{ width: "20px" }}
                          />
                        </div>
                        {/* </Stack> */}
                        {from_date || toDate ? (
                          <Button
                            className="mt-4"
                            onClick={() => {
                              setFromDate(''), setToDate('');
                            }}
                          >
                            Clear date
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="ms-4 mt-4">
                        <input
                          className="p-2 fw-bolder"
                          style={{
                            margin: 1,
                            maxWidth: 170,
                            backgroundColor: '#fff',
                            border: 'none',
                            borderRadius: '10px',
                            boxShadow:
                              '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                            // border: "1px solid gray",
                          }}
                          placeholder="Search..."
                          // label="Search by name "
                          onChange={searchByName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <TabPanel
              className="mt-2 p-0"
              value="wip"
              style={{ paddingTop: '0px' }}
            >
              {isFlag ? (
                <MapPage tabValue={tabValue} filtervalue={filtervalue} />
              ) : (
                <ClientTabel
                  tabValue={tabValue}
                  filtervalue={filtervalue}
                  search={search}
                />
              )}
            </TabPanel>
            <TabPanel
              className="mt-2 p-0"
              value="query"
              style={{ paddingTop: '0px' }}
            >
              {isFlag ? (
                <MapPage tabValue={tabValue} filtervalue={filtervalue} />
              ) : (
                <ClientTabel
                  tabValue={tabValue}
                  filtervalue={filtervalue}
                  search={search}
                />
              )}
            </TabPanel>
          </TabsContext>
        </div>
      ) : (
        ''
      )}
      {tabValue === 'closed_orders' ? (
        <div className="ms-0 ps-0 mt-3">
          <TabsContext value={filtervalue} className="p-0 m-0 mb-0">
            <div className="d-flex justify-content-between">
              <div>
                <TabList
                  onChange={handleFilterChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Approved"
                    value="approved"
                    className="table-tab-text"
                  />
                  <Tab
                    label="Declined"
                    value="declined"
                    className="ms-3 table-tab-text"
                  />
                </TabList>
              </div>
              <div>
                {isFlag ? (
                  <>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#0C3094',
                        borderRadius: '12px',
                      }}
                      onClick={() => {
                        setIsFlag(false);
                      }}
                    >
                      <ReorderIcon className="me-2" /> List view
                    </Button>
                  </>
                ) : (
                  <div className="d-flex align-items-center">
                    <img src={excelIcon} alt="" className="me-2 mt-2" />
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#0C3094',
                        borderRadius: '12px',
                      }}
                      onClick={() => {
                        setIsFlag(true);
                      }}
                    >
                      <img src={viewMapIcon} alg="" className="me-2" /> Map view
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <hr
              style={{
                height: '1px',
                color: '#000000',
              }}
              className="mt-0 "
            />
            {isFlag ? (
              ''
            ) : (
              <div className="mt-0 d-flex justify-content-end">
                {/* <div className="d-flex mt-3">
                {' '}
                <div className="fw-bolder mt-1" style={{ fontSize: '16px' }}>
                  Ayescore Legends:
                </div>
                <div className="p-1 me-1">
                  <button
                    style={{
                      backgroundColor: '#D22F4C',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '8px',
                      fontSize: '12px',
                    }}
                    disabled
                  >
                    Poor
                  </button>{' '}
                  <span style={{ fontSize: '12px' }}>Range 0 to 120</span>
                </div>
                <div className="p-1 me-1">
                  <button
                    style={{
                      backgroundColor: '#FFBF02',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '8px',
                      fontSize: '12px',
                    }}
                    disabled
                  >
                    Fair
                  </button>{' '}
                  <span style={{ fontSize: '12px' }}>Range 121 to 200</span>
                </div>
                <div className="p-1 me-1">
                  <button
                    style={{
                      backgroundColor: '#11A5AF',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '8px',
                      fontSize: '12px',
                    }}
                    disabled
                  >
                    Good
                  </button>{' '}
                  <span style={{ fontSize: '12px' }}>Range 201 to 280</span>
                </div>
                <div className="p-1 me-1">
                  <button
                    style={{
                      backgroundColor: '#007CFF',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '8px',
                      fontSize: '12px',
                    }}
                    disabled
                  >
                    Very Good
                  </button>{' '}
                  <span style={{ fontSize: '12px' }}>Range 281 to 360</span>
                </div>
                <div className="p-1 me-1">
                  <button
                    style={{
                      backgroundColor: '#2DA446',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '8px',
                      fontSize: '12px',
                    }}
                    disabled
                    // className="p-2"
                  >
                    Excellent
                  </button>{' '}
                  <span style={{ fontSize: '12px' }}>Range 361 to 460</span>
                </div>
              </div> */}
                <div>
                  {/* <div className="d-flex ">
                    <div className="ms-2">
                      <TextField
                        sx={{
                          m: 1,
                          backgroundColor: "#fff",
                          // maxWidth: 120,
                        }}
                        placeholder="Search by name..."
                        // label="Search by name "
                        onChange={searchByName}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            {isFlag ? (
              ''
            ) : (
              <div className="d-flex justify-content-end">
                <div>
                  <div className="d-flex">
                    <div className="d-flex ">
                      <div style={{ display: 'flex', gap: '2' }}>
                        <span style={{ margin: '30px 5px 0 20px' }}>From:</span>
                        <div
                          className="p-0 m-0 mt-4"
                          style={{ boxSizing: 'none', padding: '0px 0px' }}
                        >
                          <input
                            className="p-2"
                            id="date"
                            type="date"
                            value={from_date}
                            onChange={(e) => {
                              setFromDate(e.target.value);
                            }}
                            style={{
                              margin: 1,
                              maxWidth: 170,
                              backgroundColor: '#fff',
                              border: 'none',
                              borderRadius: '10px',
                              boxShadow:
                                '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                              // border: "1px solid gray",
                            }}
                          />
                        </div>
                        {/* </Stack> */}
                      </div>

                      <div style={{ display: 'flex', gap: '2px' }}>
                        <span style={{ margin: '30px 5px 0 20px' }}>To: </span>

                        <div
                          style={{ boxSizing: 'none', padding: '0px 0px' }}
                          className="p-0 m-0 mt-4"
                        >
                          <input
                            className="p-2"
                            // id="date"
                            type="date"
                            value={toDate}
                            onChange={(e) => {
                              setToDate(e.target.value);
                            }}
                            style={{
                              margin: 1,
                              maxWidth: 170,
                              backgroundColor: '#fff',
                              border: 'none',
                              borderRadius: '10px',
                              boxShadow:
                                '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                              // border: "1px solid gray",
                            }}
                            // style={{ width: "20px" }}
                          />
                        </div>
                        {/* </Stack> */}
                        {from_date || toDate ? (
                          <Button
                            className="mt-4"
                            onClick={() => {
                              setFromDate(''), setToDate('');
                            }}
                          >
                            Clear date
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="ms-4 mt-4">
                        <input
                          className="p-2 fw-bolder"
                          style={{
                            margin: 1,
                            maxWidth: 170,
                            backgroundColor: '#fff',
                            border: 'none',
                            borderRadius: '10px',
                            boxShadow:
                              '0px 4px 4px 0px rgba(0.10, 0.10, 0.10, 0.10)',
                            // border: "1px solid gray",
                          }}
                          placeholder="Search..."
                          // label="Search by name "
                          onChange={searchByName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <TabPanel
              className="mt-2 p-0"
              value="approved"
              style={{ paddingTop: '0px' }}
            >
              {isFlag ? (
                <MapPage tabValue={tabValue} filtervalue={filtervalue} />
              ) : (
                <ClientTabel
                  tabValue={tabValue}
                  filtervalue={filtervalue}
                  search={search}
                />
              )}
            </TabPanel>
            <TabPanel
              className="mt-2 p-0"
              value="declined"
              style={{ paddingTop: '0px' }}
            >
              {isFlag ? (
                <MapPage tabValue={tabValue} filtervalue={filtervalue} />
              ) : (
                <ClientTabel
                  tabValue={tabValue}
                  filtervalue={filtervalue}
                  search={search}
                  filterType={filterType}
                  from_date={from_date ? from_date : ''}
                  toDate={toDate ? toDate : ''}
                />
              )}
            </TabPanel>
          </TabsContext>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default ClientManagement;
