import {
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import '../Client Management/clientManagement.css';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import HomeData from '../common/HomeData';
import OrderQuery from './OrderQuery';
function ClientTabel({ tabValue, filtervalue, search }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [camId, setCamId] = useState();
  const [camData, setCamData] = useState();
  const token = localStorage.getItem('token');
  const [openQueryModel, setOpenQueryModel] = useState(false);
  const closeQueryModel = () => setOpenQueryModel(false);
  const [userName, setUserName] = useState();

  const getAllData = async () => {
    const query = [];
    if (page === '') return;
    if (search) {
      query.push(`search=${search}`);
    }
    const response = await axios.get(
      `/api/order_management/${tabValue}?start=${
        page * perPage
      }&length=${perPage}&prospect_type=${filtervalue}${
        query?.length ? '&' + query.join('&') : ''
      }`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response?.data) {
      if (response?.data?.message === 'JSON Web Token Is Expired, Try Again')
        navigate('/');
      // console.log(response?.data);
      setTotalRecords(response?.data?.count);
      setData(response?.data?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, [page]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    // console.log(user, "Login-user");
    if (user) {
      setUserName(user?.user_category);
    }
  }, []);

  function pathColorselector(row) {
    if (row <= 120 && row > 0) {
      return 'rgb(210, 47, 76)';
    }
    if (row <= 200 && row > 121) {
      return 'rgb(255, 191, 2)';
    }
    if (row <= 280 && row > 201) {
      return 'rgb(17, 165, 175)';
    }
    if (row <= 360 && row > 280) {
      return 'rgb(0, 124, 255)';
    }
    if (row <= 460 && row > 361) {
      return 'rgb(45, 164, 70)';
    }
  }

  function trailColorselector(row) {
    if (row <= 120 && row > 0) {
      return 'rgb(238 165 172)';
    }
    if (row <= 200 && row > 121) {
      return '#f0d892';
    }
    if (row <= 280 && row > 201) {
      return '#b6cfd1';
    }
    if (row <= 360 && row > 280) {
      return '#d7e6f5';
    }
    if (row <= 460 && row > 361) {
      return '#89eb9f';
    }
  }

  // ================== Pagination ===================================
  const prevHandler = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const nextHandler = () => {
    if ((page + 1) * perPage < totalRecords) {
      setPage(page + 1);
    }
  };

  const showingRecords = useMemo(() => {
    const from = 1 + perPage * page;
    let to = perPage * (page + 1);
    if (totalRecords <= to) {
      to = totalRecords;
    }
    return `Showing ${from}-${to} of ${totalRecords}`;
  }, [perPage, page, totalRecords]);

  // ========================== Search Function =========================
  useEffect(() => {
    setPage('');
    setTimeout(() => {
      setPage(0);
    }, 100);
  }, [search, tabValue, filtervalue]);

  const orderClickHandalr = (id) => {
    navigate(`/orderManagement/details/${id}`);
  };

  function bgcolor(val) {
    if (val === 'Partially Paid') return '#2A9090';
    if (val === 'Closed') return '#C42525';
    if (val === 'Not Paid but Closed') return '#535353';
    if (val === 'Fully Paid') return '#2DA446';
  }

  const approvelimit = (cam) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: '#F5F5F5',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <h2>Are you sure?</h2>
            <p>You want to approve this?</p>
            <Button variant="contained" onClick={onClose} className="me-2 m-3">
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                yesApprove(cam);
                onClose();
              }}
            >
              Yes, Approve it!
            </Button>
          </div>
        );
      },
    });
  };

  const fundingApprove = (cam) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: '#F5F5F5',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <h2>Are you sure?</h2>
            <p>You want to approve this?</p>
            <Button variant="contained" onClick={onClose} className="me-2 m-3">
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                yesApproveFunding(cam);
                onClose();
              }}
            >
              Yes, Approve it!
            </Button>
          </div>
        );
      },
    });
  };

  const notifyDecline = () => {
    toast.success(' Declined Sucessfully', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  const notify = () => {
    toast.error(
      'available credit limit should me greater then or equel to total value',
      {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      }
    );
  };

  const yesApproveFunding = (cam) => {
    if (cam) {
      axios
        .patch(
          `/api/order_management/funding_status`,
          {
            order_id: cam?.order_id,
            user_id: cam?.user_id,
            funding_status: 'approved',
            lending_partner: `${userName}`,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          getAllData();
          toast.success('Approved Successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const yesApprove = (cam) => {
    if (cam) {
      // if (
      //   Number(camData?.available_credit_limit) >= Number(camData?.total_value)
      // ) {
      axios
        .patch(
          `/api/order_management/opportunity_status/approved`,
          {
            order_id: cam?.order_id,
            user_id: cam?.user_id,
            opportunity_status: 'approved',
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          // show success toast
          getAllData();
          toast.success('Approved Successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        })
        .catch((error) => {
          console.log(error);
        });
      // } else {
      //   notify();
      // }
    }
  };

  function formatNumber(number) {
    if (number >= 100000 && number < 10000000) {
      return (number / 100000).toFixed(2) + 'L';
    } else if (number >= 10000000) {
      return (number / 10000000).toFixed(2) + 'Cr';
    } else {
      return number;
    }
  }
  // ===================================== query and decline ====================================
  const yesDicline = (cam) => {
    // console.log(camData, "Decline");
    if (cam) {
      axios
        .patch(
          `/api/order_management/funding_status`,
          {
            order_id: `${cam?.order_id}`,
            user_id: `${cam?.user_id}`,
            funding_status: 'rejected',
            lending_partner: `${userName}`,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          notifyDecline();
          getAllData();
        })
        .catch((error) => {
          console.log(error);
          // isUser();
        });
    }
  };
  const declineInprogressFunction = (cam) => {
    setCamData(cam);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: '#F5F5F5',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <p>You want to query or decline this?</p>
            <Button
              variant="contained"
              className="me-2"
              onClick={() => {
                yesDicline(cam);
                onClose();
              }}
            >
              Decline
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenQueryModel(true);
                onClose();
              }}
            >
              Query
            </Button>
          </div>
        );
      },
    });
  };
  return (
    <div>
      <ToastContainer />
      <TableContainer
        className=" mt-4"
        // style={{ backgroundColor: "#fff", borderRadius: "8px" }}
      >
        <Table
          size="large"
          aria-label="a dense table"
          // style={{ borderRadius: "5px", backgroundColor: "#F6FAFF" }}
          style={{ borderRadius: '5px', backgroundColor: '#f1f1f1' }}
          className="p-3"
        >
          <TableHead className=" bg-slate-600 text-white">
            <TableRow className="pt-4 pb-4" style={{ borderRadius: '5px' }}>
              <TableCell className="fw-bolder pt-3 pb-3 text-white">
                Order Date
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white">
                Order ID
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white">
                Party
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white">
                Commodity
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white">
                Quantity
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white">
                Total Value
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white">
                Advance Payment Amt
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white">
                Tenure Requested (Days)
              </TableCell>
              {tabValue === 'repayment_orders' ? (
                <TableCell align="center" className="fw-bolder text-white">
                  Credit Granted
                </TableCell>
              ) : (
                ''
              )}
              {tabValue === 'repayment_orders' ? (
                <>
                  <TableCell align="center" className="fw-bolder text-white">
                    Repaid Amt
                  </TableCell>{' '}
                  <TableCell align="center" className="fw-bolder text-white">
                    Days Due
                  </TableCell>{' '}
                  <TableCell align="center" className="fw-bolder text-white">
                    Payment Status
                  </TableCell>
                </>
              ) : (
                ''
              )}
              {tabValue === 'opportunity_orders' ? (
                <>
                  <TableCell align="center" className="fw-bolder text-white">
                    Limit Available
                  </TableCell>
                  <TableCell align="center" className="fw-bolder text-white">
                    Order Status
                  </TableCell>
                </>
              ) : (
                ''
              )}

              <TableCell align="center" className="fw-bolder text-white">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <HomeData />
          ) : (
            <>
              {data?.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell>

                    <TableCell className="pt-4 pb-4 " align="center">
                      <h2 className="fw-bolder">No data found</h2>
                    </TableCell>
                    {/* <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell>
                    <TableCell className="pt-4 pb-4"></TableCell> */}
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {data?.map((row, index) => (
                    <TableRow
                      style={{
                        backgroundColor: index % 2 == 0 ? '#fff' : '#ededed',
                      }}
                    >
                      <TableCell className="pt-4 pb-4">
                        {row.order_date
                          ? moment(row?.order_date).format('ll')
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row.order_id ? row?.order_id : ''}
                      </TableCell>

                      <TableCell align="center">
                        {row.party_name ? row?.party_name : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row.commodity_name
                          ? row?.commodity_name.toString()
                          : ''}
                      </TableCell>

                      <TableCell align="center">
                        {row.quantity ? row?.quantity : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row?.total_value ? row?.total_value : '0'}
                      </TableCell>
                      <TableCell align="center">
                        {row.advance_payment_amount
                          ? row?.advance_payment_amount
                          : '0'}
                      </TableCell>
                      <TableCell align="center">
                        {row.tenure_requested_days}
                      </TableCell>
                      {/* {tabValue !== "opportunity_orders" ? (
                        <TableCell align="center">
                          {row.tenure_requested_days}
                          
                        </TableCell>
                      ) : (
                        ""
                      )} */}
                      {tabValue === 'repayment_orders' ? (
                        <>
                          <TableCell align="center">
                            {row?.repaid_amount}
                          </TableCell>{' '}
                          <TableCell align="center">{row?.due_days}</TableCell>{' '}
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              style={{
                                borderRadius: '12px',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                border: 'none',
                                width: '180px',
                                backgroundColor: bgcolor(row?.payment_status),
                              }}
                            >
                              {row?.payment_status}
                            </Button>
                          </TableCell>
                        </>
                      ) : (
                        ''
                      )}

                      {tabValue === 'opportunity_orders' ? (
                        <>
                          <TableCell align="center">
                            <div style={{ width: 60, height: 60 }}>
                              <CircularProgressbar
                                className="fw-bolder"
                                value={
                                  row?.limit_available < 0
                                    ? 0
                                    : row?.limit_available
                                }
                                size={500}
                                maxValue={460}
                                text={`${
                                  formatNumber(row?.limit_available) || '0'
                                }`}
                                strokeWidth={15}
                                styles={buildStyles({
                                  textColor: 'black',
                                  pathColor: pathColorselector(
                                    row?.limit_available
                                  ),
                                  trailColor: trailColorselector(
                                    row?.limit_available
                                  ),
                                  width: '60px',
                                })}
                              />
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <button
                              style={{
                                borderRadius: '12px',
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                border: 'none',
                                width: '100px',
                                backgroundColor:
                                  row.order_status === 'Order Delivered'
                                    ? 'green'
                                    : row.order_status === 'In Process'
                                    ? 'brown'
                                    : row.order_status === 'Pending'
                                    ? 'blue'
                                    : 'gray',
                                color: 'white',
                              }}
                            >
                              {row.order_status}
                            </button>
                          </TableCell>
                        </>
                      ) : (
                        ''
                      )}

                      {tabValue === 'closed_orders' &&
                      filtervalue === 'approved' ? (
                        <TableCell>
                          <div style={{ width: 60, height: 60 }}>
                            <CircularProgressbar
                              className="fw-bolder"
                              value={
                                row?.utilize_credit_limit
                                  ? row?.utilize_credit_limit
                                  : 0
                              }
                              size={500}
                              maxValue={row?.assign_credit_limit}
                              text={`${
                                row.utilize_credit_limit
                                  ? row?.utilize_credit_limit
                                  : 0
                              }`}
                              strokeWidth={15}
                              styles={buildStyles({
                                textColor: 'black',
                                pathColor: pathColorselector(
                                  row?.utilize_credit_limit
                                    ? row?.utilize_credit_limit
                                    : 0
                                ),
                                trailColor: trailColorselector(
                                  row?.utilize_credit_limit
                                    ? row?.utilize_credit_limit
                                    : 0
                                ),
                                width: '60px',
                              })}
                            />
                          </div>
                        </TableCell>
                      ) : (
                        ''
                      )}

                      {tabValue === 'closed_orders' &&
                      filtervalue === 'declined' ? (
                        <TableCell align="center" style={{ color: 'red' }}>
                          {row?.declined_reason ? row?.declined_reason : ''}
                        </TableCell>
                      ) : (
                        ''
                      )}
                      <TableCell align="center">
                        {(tabValue === 'opportunity_orders' &&
                          filtervalue === 'available') ||
                        tabValue === 'funding_orders' ||
                        filtervalue === 'disbursed' ? (
                          <>
                            {filtervalue !== 'approved' && (
                              <button
                                type="button"
                                // class="btn btn-secondary"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Approve"
                                style={{
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                }}
                              >
                                <CheckIcon
                                  role="button"
                                  style={{ color: 'green' }}
                                  onClick={() => {
                                    tabValue === 'funding_orders'
                                      ? fundingApprove(row)
                                      : approvelimit(row);
                                    setCamData(row ? row : '');
                                  }}
                                />
                              </button>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                        <br />
                        <button
                          type="button"
                          // class="btn btn-secondary"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="View"
                          style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <VisibilityIcon
                            role="button"
                            style={{ color: '#0C3094' }}
                            onClick={() => orderClickHandalr(row?.user_id)}
                          />
                        </button>
                        <br />
                        {tabValue === 'closed_orders' &&
                        filtervalue === 'declined' ? (
                          <button
                            type="button"
                            // class="btn btn-secondary"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Reopen"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                          >
                            <SettingsBackupRestoreIcon
                              role="button"
                              style={{ color: '#0C3094' }}
                              // onClick={() => orderClickHandalr(row?.user_id)}
                            />
                          </button>
                        ) : (
                          ''
                        )}
                        {tabValue === 'funding_orders' &&
                        filtervalue !== 'rejected' ? (
                          <>
                            {filtervalue !== 'approved' && (
                              <button
                                type="button"
                                // class="btn btn-secondary"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Clear"
                                style={{
                                  border: 'none',
                                  backgroundColor: 'transparent',
                                }}
                              >
                                <ClearIcon
                                  role="button"
                                  style={{ color: '#B93D16' }}
                                  onClick={() =>
                                    tabValue === 'funding_orders'
                                      ? declineInprogressFunction(row)
                                      : ''
                                  }
                                />
                              </button>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </>
          )}
        </Table>
        {data?.length !== 0 && !loading ? (
          <div className="">
            <span className="fw-bolder">{showingRecords}</span>
            <button
              className="next-prev-btn me-2 p-1 ms-2 ps-2 pe-2"
              onClick={prevHandler}
            >
              Prev
            </button>
            <button
              className="next-prev-btn p-1 ps-2 pe-2"
              onClick={nextHandler}
              disabled={totalRecords === (page + 1) * perPage ? true : false}
            >
              Next
            </button>
          </div>
        ) : (
          ''
        )}
      </TableContainer>
      <OrderQuery
        openQueryModel={openQueryModel}
        closeQueryModel={closeQueryModel}
        getAllData={getAllData}
        camData={camData}
        userName={userName}
      />
    </div>
  );
}

export default ClientTabel;
