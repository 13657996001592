import React, { useEffect } from "react";
import "../Client Management/clientManagement.css";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DatamapsIndia from "react-datamaps-india";
import { useState } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import Loading from "../common/Loader";
import HomeData from "../common/HomeData";
import { array } from "yup";
function MapPage({ isOpen, tabValue, filtervalue, }) {
  const navigate = useNavigate();
  const [state, setState] = useState("all");
  const [loading, setLoading] = useState(true);
  const [loadingBar , setloadingBar] = useState(true)
  const [pieLoader, setPieLoader] = useState(true);
  const [pieFilter, setPieFilter] = useState("by_volume");
  const [prospectCount, setProspectCount] = useState();
  const [inProgressCount, setInProgressCount] = useState();
  const [fromDate, setFromDate] = useState("2020-04-01");
  const [toDate, setToDate] = useState("2023-07-30");
  const [repaymentStackbar, setRepaymentStackBar] = useState({
    series: [
      {
        name: "Paid Early",
        data: [44, 55, 41, 37, 22, 43, 21],
      },
      {
        name: "Paid on Time",
        data: [53, 32, 33, 52, 13, 43, 32],
      },
      {
        name: "Paid Late",
        data: [12, 17, 11, 9, 15, 11, 20],
      },
      {
        name: "Not Paid",
        data: [9, 7, 5, 8, 6, 9, 4],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "",
      },
      xaxis: {
        categories: [2008, 2009, 2010, 2011, 2012, 2013, 2014],
        labels: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      fill: {
        opacity: 1,
      },

      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  });
  const [stackBar, setStackBar] = useState({
    series: [
      {
        name: "Available",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "",
      },
      xaxis: {
        categories: ["Available", "Declined", "Approved", "Expired"],
        // labels: {
        //   formatter: function (val) {
        //     return val + "K";
        //   },
        // },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      fill: {
        opacity: 1,
      },

      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  });
  const [stateData, setStateData] = useState({
    // 'ANDHRA PRADESH': {
    //   id: 'ANDHRA PRADESH',
    //   value: 3,
    // },
    // Bihar: {
    //   id: 'BIHAR',
    //   value: 12,
    // },
    // CHHATTISGARH: {
    //   id: 'CHHATTISGARH',
    //   value: 1,
    // },
    // DELHI: {
    //   id: 'DELHI',
    //   value: 23,
    // },
    // GAZIPUR: {
    //   id: 'GAZIPUR',
    //   value: 1,
    // },
  });
  console.log(prospectCount, "count131");
  const handleChange = (e) => {
    setPieLoader(true);
    setState(e.target.value);
  };
  const [data, setData] = useState();
  const [closedPieChart, setClosedPieChart] = useState({
    series: [0, 0],
    options: {
      chart: {
        width: 500,
        height: "100%",
        type: "pie",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "10px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          padding: "5px",
        },
      },
      labels: ["Disbursed", "Paid"],
      colors: ["#329DC9", "#FF603D"],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            },
          },
        },
      ],
    },
  });
  const [pieState, setPieState] = useState({
    series: [10, 10],
    options: {
      chart: {
        width: 500,
        height: "100%",
        type: "pie",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "10px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          padding: "5px",
        },
      },
      labels: ["WIP", "Query"],
      colors: ["#329DC9", "#FF603D"],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            },
          },
        },
      ],
    },
  });

  const [pieStateProspects, setPieStateProspects] = useState({
    series: [],
    options: {
      chart: {
        width: 500,
        height: "100%",
        type: "pie",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "10px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          padding: "5px",
        },
      },
      labels: ["Available", "Expired"],
      colors: ["#329DC9", "#FFA63D"],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            },
          },
        },
      ],
    },
  });
  //========================== Bar chart =============================================
  const [barStateClosed, setBarStateClosed] = useState({
    series: [
      {
        name: "",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: ["#2A9090"],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      legend: {
        show: false,
        showForSingleSeries: true,
        customLegendItems: ["Month on Month Number of Transactions"],
      },
    },
  });
  const [barStateProspects, setBarStateProspects] = useState({
    series: [
      {
        name: "",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: ["#2A9090"],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      legend: {
        show: false,
        showForSingleSeries: true,
        customLegendItems: ["Month on Month Number of Transactions"],
      },
    },
  });

  const [queryBar, setQueryBar] = useState({
    series: [
      {
        name: "",
        data: [41, 54, 52, 14],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: ["#2A9090"],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
      },
      legend: {
        show: false,
        showForSingleSeries: true,
        customLegendItems: ["Month on Month Number of Transactions"],
      },
    },
  });
  const token = localStorage.getItem("token");
  // ================================= get map data =============================
  const getMapdata = async () => {
    if (state === "all") {
      const response = await axios.get(
        `/api/order_management/order_heat_maps/${tabValue}?prospect_type=${filtervalue}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.message === "JSON Web Token Is Expired, Try Again") {
        navigate("/");
      } else {
        if (response?.data) {
          let temp = response?.data?.data;

          setStateData({ ...temp });
          setLoading(false);
        }
      }
    } else {
      const response = await axios.get(
        `/api/order_management/order_heat_maps/${tabValue}?prospect_type=${filtervalue}&state=${state}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.message === "JSON Web Token Is Expired, Try Again") {
        navigate("/");
      } else {
        if (response?.data) {
          let temp = response?.data?.data;

          setStateData({ ...temp });
          setLoading(false);
        }
      }
    }
  };

  // ====================== all Pie data ========================================
  const getPieChartData = async () => {
    const query = [];

    if (pieFilter === "by_value") {
      query.push("by_value=true");
    }
    if (pieFilter === "by_volume") {
      query.push("by_volume=true");
    }
    if (state !== "all") [query.push(`state=${state}`)];

    // if (state === "all") {
    //   const response = await axios.get(
    //     `/api/client_management/client_total_count?prospect_type=${tabValue}${
    //       query?.length ? "&" + query.join("&") : ""
    //     }`,
    //     {
    //       headers: {
    //         authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    // }
    const response = await axios.get(
      `/api/order_management/order_total_count?prospect_type=${tabValue}${
        query?.length ? "&" + query.join("&") : ""
      }`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    if (response?.data?.message === "JSON Web Token Is Expired, Try Again")
      navigate("/");
    if (response?.data) {
      setProspectCount(response?.data);

      const arr = [];
      if (tabValue === "repayment_orders") {
        arr.push(
          response?.data?.rep_total_paid
            ? Number(response?.data?.rep_total_paid)
            : 0
        );
        arr.push(
          response?.data?.rep_disbursed_count
            ? Number(response?.data?.rep_disbursed_count)
            : 0
        );
        setClosedPieChart({ ...closedPieChart, series: [...arr] });
      }

      if (tabValue === "funding_orders") {
        arr.push(
          response?.data?.funding_query_count !== "NaN"
            ? Number(response?.data?.funding_query_count)
            : 0
        );
        arr.push(
          response?.data?.funding_query_count !== "NaN"
            ? Number(response?.data?.funding_query_count)
            : 0
        );
        setPieState({ ...pieState, series: [...arr] });
      }
      if (pieFilter === "by_value") {
        // setPieStateProspects({ ...pieStateProspects, series: [...arr] });

        if (tabValue === "opportunity_orders") {
          arr.push(
            response?.data?.value[0]?.by_value_in_lakhs_available !== "NaN"
              ? Number(response?.data?.value[0]?.by_value_in_lakhs_available)
              : 0
          );
          arr.push(
            response?.data?.value[1]?.by_value_in_lakhs_expired !== "NaN"
              ? Number(response?.data?.value[1]?.by_value_in_lakhs_expired)
              : 0
          );

          setPieStateProspects({ ...pieStateProspects, series: [...arr] });
        }
        // if (tabValue === "repayment_orders") {
        //   arr.push(
        //     response?.data?.value[0]?.by_value_in_lakhs_approved
        //       ? Number(response?.data?.value[0]?.by_value_in_lakhs_approved)
        //       : 0
        //   );
        //   arr.push(
        //     response?.data?.value[1]?.by_value_in_lakhs_declined
        //       ? Number(response?.data?.value[1]?.by_value_in_lakhs_declined)
        //       : 0
        //   );
        //   setClosedPieChart({ ...closedPieChart, series: [...arr] });
        // }
      }
      if (pieFilter === "by_volume") {
        if (tabValue === "opportunity_orders") {
          arr.push(
            response?.data?.volume[0]?.by_volume_qty_available !== "NaN"
              ? Number(response?.data?.volume[0]?.by_volume_qty_available)
              : 0
          );
          arr.push(
            response?.data?.volume[1]?.by_volume_qty_expired !== "NaN"
              ? Number(response?.data?.volume[1]?.by_volume_qty_expired)
              : 0
          );

          setPieStateProspects({ ...pieStateProspects, series: [...arr] });
        }
        // if (tabValue === "repayment_orders") {
        //   arr.push(
        //     response?.data?.volume[0]?.by_volume_qty_approved
        //       ? Number(response?.data?.volume[0]?.by_volume_qty_approved)
        //       : 0
        //   );
        //   arr.push(
        //     response?.data?.volume[1]?.by_volume_qty_declined
        //       ? Number(response?.data?.volume[1]?.by_volume_qty_declined)
        //       : 0
        //   );
        //   setClosedPieChart({ ...closedPieChart, series: [...arr] });
        // }
      }
      setPieLoader(false);
    }
  };

  useEffect(() => {
    getMapdata();
    getPieChartData();
  }, [pieFilter, state]);

  // ========================================== get Bar data =====================
  const getInpgressBar = async () => {
    if (tabValue === "funding_orders") {
      const query = [];
      if (state !== "all") {
        query.push(`state=${state}`);
      }
      // /api/order_management/bar_chart_wip/funding_orders?prospect_type=query
      const response = await axios.get(
        filtervalue === "wip"
          ? `/api/order_management/bar_chart_wip/${tabValue}?from_date=${fromDate}&to_date=${toDate}&prospect_type=${filtervalue}${
              query.length ? "&" + query.join("&") : ""
            }`
          : `/api/order_management/bar_chart_wip/${tabValue}?from_date=${fromDate}&to_date=${toDate}&prospect_type=${filtervalue}${
              query.length ? "&" + query.join("&") : ""
            }`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        const arr = [];
        const label = [];
        if (filtervalue === "wip") {
          response?.data?.inproges_bar_data?.map((item, index) => {
            arr.push(Number(item?.funding_wip_count));
            label.push(item?.txn_month);
          });
          setBarStateProspects((Prev) => ({
            ...Prev,
            series: [{ ...Prev.series[0], data: [...arr] }],
            options: {
              ...Prev.options,
              xaxis: {
                ...Prev.options.xaxis,
                categories: [...label],
              },
            },
          }));
        }

        if (filtervalue === "approved") {
          response?.data?.inproges_bar_data?.map((item, index) => {
            arr.push(Number(item?.funding_wip_count));
            label.push(item?.txn_month);
          });
          setApproveBarState((Prev) => ({
            ...Prev,
            series: [{ ...Prev.series[0], data: [...arr] }],
            options: {
              ...Prev.options,
              xaxis: {
                ...Prev.options.xaxis,
                categories: [...label],
              },
            },
          }));
        }

        if (filtervalue === "rejected") {
          response?.data?.inproges_bar_data?.map((item, index) => {
            arr.push(Number(item?.funding_wip_count));
            label.push(item?.txn_month);
          });
          setRejectedBarState((Prev) => ({
            ...Prev,
            series: [{ ...Prev.series[0], data: [...arr] }],
            options: {
              ...Prev.options,
              xaxis: {
                ...Prev.options.xaxis,
                categories: [...label],
              },
            },
          }));
        }
        if (filtervalue === "query") {
          arr.push(Number(response?.data?.low_ayescore_count));
          arr.push(Number(response?.data?.incomeplete_document_count));
          arr.push(Number(response?.data?.privious_dues_count));
        }
        if (filtervalue === "query")
          [
            setBarState((Prev) => ({
              ...Prev,
              series: [{ ...Prev.series[0], data: [...arr] }],
            })),
          ];
        if (filtervalue === "approved") {
        }
      }
    }
  };

  // const getClosedBar = async () => {
  //   const query = [];
  //   if (state !== "all") {
  //     query.push(`state=${state}`);
  //   }
  //   if (filtervalue === "approved") {
  //     const arr = [];
  //     const label = [];
  //     const response = await axios.get(
  //       `/api/order_management/bar_chart_fytd/${tabValue}?from_date=${fromDate}&to_date=${toDate}&prospect_type=${filtervalue}${
  //         query?.length ? "&" + query.join("&") : ""
  //       }`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response?.data) {
  //       console.log(response?.data?.closed_order_data);

  //       {
  //         response?.data?.closed_order_data?.map((item, index) => {
  //           arr.push(Number(item?.approved_count));
  //           label.push(item?.login_date_txt);
  //         });
  //       }
  //       setBarStateClosed((Prev) => ({
  //         ...Prev,
  //         series: [{ ...Prev.series[0], data: [...arr] }],
  //         options: {
  //           ...Prev.options,
  //           xaxis: {
  //             ...Prev.options.xaxis,
  //             categories: [...label],
  //           },
  //         },
  //       }));
  //     }
  //   }
  //   if (filtervalue === "declined") {
  //     const arr = [];
  //     const label = [];
  //     const response = await axios.get(
  //       `/api/order_management/bar_chart_fytd/${tabValue}?prospect_type=${filtervalue}${
  //         query?.length ? "&" + query.join("&") : ""
  //       }`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response?.data) {
  //       console.log(response);
  //       arr.push(Number(response?.data?.low_ayescore_count));
  //       arr.push(Number(response?.data?.incomeplete_document_count));
  //       arr.push(Number(response?.data?.privious_dues_count));

  //       [
  //         setDeclinedBar((Prev) => ({
  //           ...Prev,
  //           series: [{ ...Prev.series[0], data: [...arr] }],
  //         })),
  //       ];
  //     }
  //   }
  // };

  useEffect(() => {
    getInpgressBar();
    // getClosedBar();
  }, [tabValue, filtervalue, fromDate, toDate, state]);
  console.log(barStateProspects); // ========================================= Bar chart ================================
  const [declinedBar, setDeclinedBar] = useState({
    series: [
      {
        name: "",
        data: [],
      },
    ],
    options: {
      chart: {
        width: 500,
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false, // <--- HERE
      },
      labels: ["Low Ayescore", "Incomplete Documents", "Previous Dues"],
      colors: ["#2A9090"],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            },
          },
        },
      ],
    },
  });
  const [rejectedBarState, setRejectedBarState] = useState({
    series: [
      {
        name: "",
        data: [72, 9, 19],
      },
    ],
    options: {
      chart: {
        width: 500,
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false, // <--- HERE
      },
      labels: ["jan", "feb", "march"],
      colors: ["#2A9090"],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            },
          },
        },
      ],
    },
  });
  const [approveBarState, setApproveBarState] = useState({
    series: [
      {
        name: "",
        data: [72, 9, 19],
      },
    ],
    options: {
      chart: {
        width: 500,
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false, // <--- HERE
      },
      labels: ["jan", "feb", "march"],
      colors: ["#2A9090"],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            },
          },
        },
      ],
    },
  });
  const [barState, setBarState] = useState({
    series: [
      {
        name: "",
        data: [72, 9, 19],
      },
    ],
    options: {
      chart: {
        width: 500,
        type: "bar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false, // <--- HERE
      },
      labels: ["Low Ayescore", "Incomplete Documents", "Previous Dues"],
      colors: ["#2A9090"],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            },
          },
        },
      ],
    },
  });

  //======================================= opportunitiees bar grap =====================
  useEffect(() => {
    axios
      .get(`/api/order_management/bar_chart_fytd/opportunity_orders`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const arr = [];
        console.log(response?.data?.data, "bar opp");
        arr.push(Number(response?.data?.data?.available_count));
        arr.push(Number(response?.data?.data?.declined_count));
        arr.push(Number(response?.data?.data?.approved_count));
        arr.push(Number(response?.data?.data?.expired_count));
        setStackBar((Prev) => ({
          ...Prev,
          series: [{ ...Prev?.series[0], data: [...arr] }],
        }));
        setloadingBar(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

 
  return (
    <div>
      <div className="ms-0 mt-4 ">
        <div className="row mt-3 ">
          <div className="col col-md-6 col-lg-6 col-sm-6">
            <div className="map-box p-4">
              <div className="d-flex justify-content-between mt-3">
                <div className="map-box-title mt-3">Map View</div>
                <div>
                  {" "}
                  <FormControl
                    fullWidth
                    sx={{
                      m: 1,
                      minWidth: 80,
                      // zIndex: '-0.01',
                      zIndex: "0",
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className="fw-bolder"
                    >
                      Select state
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state}
                      label="State state"
                      onChange={handleChange}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      <MenuItem value={"maharashtra"}>Maharashtra</MenuItem>
                      <MenuItem value={"madhyaPradesh"}>
                        Madhya Pradesh
                      </MenuItem>
                      <MenuItem value={"punjab"}>Punjab</MenuItem>
                      <MenuItem value={"rajasthan"}>Rajasthan</MenuItem>
                      <MenuItem value={"tamilNadu"}>Tamil Nadu</MenuItem>
                      <MenuItem value={"telangana"}>Telangana</MenuItem>

                      <MenuItem value={"andhraPradesh"}>
                        Andhra Pradesh
                      </MenuItem>
                      <MenuItem value={"arunachalPradesh"}>
                        Arunachal Pradesh
                      </MenuItem>
                      <MenuItem value={"assam"}>Assam</MenuItem>
                      <MenuItem value={"bihar"}>Bihar</MenuItem>
                      <MenuItem value={"chhattisgarh"}>Chhattisgarh</MenuItem>

                      <MenuItem value={"goa"}>Goa</MenuItem>
                      <MenuItem value={"gujarat"}>Gujarat</MenuItem>
                      <MenuItem value={"haryana"}>Haryana</MenuItem>
                      <MenuItem value={"himachalPradesh"}>
                        Himachal Pradesh
                      </MenuItem>
                      <MenuItem value={"jharkhand"}>Jharkhand</MenuItem>
                      <MenuItem value={"karnataka"}>Karnataka</MenuItem>
                      <MenuItem value={"kerala"}>Kerala</MenuItem>

                      <MenuItem value={"manipur"}>Manipur</MenuItem>
                      <MenuItem value={"meghalaya"}>Meghalaya</MenuItem>
                      <MenuItem value={"mizoram"}>Mizoram</MenuItem>
                      <MenuItem value={"nagaland"}>Nagaland</MenuItem>
                      <MenuItem value={"odisha"}>Odisha</MenuItem>
                      <MenuItem value={"sikkim"}>Sikkim</MenuItem>
                      <MenuItem value={"tripura"}>Tripura</MenuItem>
                      <MenuItem value={"uttarPradesh"}>Uttar Pradesh</MenuItem>
                      <MenuItem value={"uttarakhand"}>Uttarakhand</MenuItem>
                      <MenuItem value={"westBengal"}>West Bengal</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  width: "500px",
                  height: "550px",
                  margin: "auto",
                }}
                className="text-center mt-5"
              >
                {loading ? (
                  <Loading />
                ) : (
                  <DatamapsIndia
                    style={{ position: "relative" }}
                    regionData={stateData}
                    hoverComponent={({ value }) => (
                      <div>
                        <div>
                          {value.name}:{value.value}{" "}
                        </div>
                      </div>
                    )}
                    mapLayout={{
                      floating: false,
                      startColor: "#b3d1ff",
                      endColor: "#005ce6",
                      hoverTitle: "Count",
                      noDataColor: "#fff",
                      borderColor: "#000",
                      hoverColor: "green",
                      height: 100,
                      width: 60,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col col-md-6 col-lg-6 col-sm-6">
            {tabValue === "opportunity_orders" ? (
              <>
                {" "}
                <div className="map-box p-4">
                  <div className="map-box-title" style={{ fontSize: "14px" }}>
                    Orders for {state}
                  </div>
                  {pieLoader ? (
                    <HomeData />
                  ) : (
                    <div className="row">
                      <div className="col col-md-6 col-lg-6 col-sm-6 mt-3">
                        <div className="">
                          <ReactApexChart
                            options={pieStateProspects.options}
                            series={pieStateProspects.series}
                            type="pie"
                          />
                        </div>
                        <div
                          className="p-2 mt-2"
                          style={{
                            backgroundImage:
                              "linear-gradient(302.97deg, #0C3094 3.52%, #889FDD 80.16%)",
                            borderRadius: "12px",
                            color: "#fff",
                          }}
                        >
                          <div className="text-center fw-bolder">
                            Total Available
                          </div>
                          <div
                            className="text-center fw-bolder"
                            style={{ color: "#fff", fontSize: "18px" }}
                          >
                            {/* {
                              prospectCount?.total_available_count[0]
                                ?.total_available_count
                            } */}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="text-center">
                          <FormControl
                            className="p-3 ps-5 pe-5"
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            }}
                          >
                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              onChange={(e) => {
                                setPieFilter(e.target.value);
                                setPieLoader(true);
                              }}
                              value={pieFilter}
                            >
                              <FormControlLabel
                                value="by_volume"
                                control={<Radio />}
                                label="By Volume"
                              />
                              <FormControlLabel
                                value="by_value"
                                control={<Radio />}
                                label="By Value"
                              />
                            </RadioGroup>
                          </FormControl>

                          <div className="d-flex gap-2 mt-3">
                            <div
                              className="legend-color-box ms-5"
                              style={{ backgroundColor: "#329DC9" }}
                            ></div>
                            <div className=""> Available</div>
                          </div>
                          <div className="d-flex mt-2 gap-2">
                            <div
                              className="legend-color-box ms-5"
                              style={{ backgroundColor: "#FFA63D" }}
                            ></div>
                            <div className="me-2">Expired</div>
                          </div>
                        </div>
                        <div
                          className=" mt-4 p-2"
                          style={{
                            backgroundImage:
                              "linear-gradient(302.97deg, #0C3094 3.52%, #889FDD 80.16%)",
                            borderRadius: "12px",
                            color: "#fff",
                          }}
                        >
                          <div className="text-center fw-bolder">Expired</div>
                          <div
                            className="text-center fw-bolder"
                            style={{ color: "#fff", fontSize: "18px" }}
                          >
                            {/* {
                              prospectCount?.expired_count[0]
                                ?.total_expired_count
                            } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mb-1 mt-1 ms-0">
                  <input
                    className="p-2"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "rgb(12, 48, 148)",
                      border: "1px solid rgb(12, 48, 148)",
                      borderRadius: "8px",
                    }}
                    id="date"
                    value={fromDate}
                    type="date"
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                  />
                  <span
                    className=" fw-bolder p-2 pt-3"
                    style={{ marginTop: "18px", fontSize: "30px" }}
                  >
                    {" "}
                    -{" "}
                  </span>
                  <input
                    className="p-2"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "rgb(12, 48, 148)",
                      border: "1px solid rgb(12, 48, 148)",
                      borderRadius: "8px",
                    }}
                    id="date"
                    value={toDate}
                    type="date"
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                  />
                  {/* {fromDate || toDate ? (
                    <Button
                      className="ms-3 p-1"
                      variant="outlined"
                      onClick={() => {
                        setFromDate("2020-04-01");
                        setToDate("2023-03-31");
                      }}
                    >
                      Clear date
                    </Button>
                  ) : (
                    ""
                  )} */}
                </div>
                <div className="map-box  mt-2">
                  <div className=" mt-2">
                    <div className="map-box-title ms-3 mt-2 mb-1 p-2">
                      Available Opportunities
                    </div>
                  </div>
                  {/* <div className="d-flex p-2">
                    <div className="d-flex me-2">
                      <div
                        className="stack-bar-label me-1"
                        style={{ backgroundColor: "rgb(0, 143, 251)" }}
                      ></div>
                      <span>Available</span>
                    </div>
                    <div className="d-flex me-2">
                      <div
                        className="stack-bar-label me-1"
                        style={{ backgroundColor: "rgb(0, 227, 150)" }}
                      ></div>
                      <span>Declined</span>
                    </div>
                    <div className="d-flex me-2">
                      <div
                        className="stack-bar-label me-2"
                        style={{ backgroundColor: "rgb(254, 176, 25)" }}
                      ></div>
                      <span>Approved</span>
                    </div>
                    <div className="d-flex me-2">
                      <div
                        className="stack-bar-label me-2"
                        style={{ backgroundColor: "rgb(255, 69, 96)" }}
                      ></div>
                      <span>Expired</span>
                    </div>
                  </div> */}
                  {loadingBar ? (
                    <HomeData />
                  ) : (
                    <div id="chart">
                      <ReactApexChart
                        options={stackBar.options}
                        series={stackBar.series}
                        type="bar"
                        height={280}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              ""
            )}

            {tabValue === "funding_orders" ? (
              <>
                {" "}
                <div className="map-box p-4">
                  <div className="map-box-title" style={{ fontSize: "14px" }}>
                    Orders for {state}
                  </div>
                  {pieLoader ? (
                    <HomeData />
                  ) : (
                    <div className="row">
                      <div className="col col-md-6 col-lg-6 col-sm-6 mt-3">
                        <div className="">
                          <ReactApexChart
                            options={pieState.options}
                            series={pieState.series}
                            type="pie"
                          />
                        </div>
                        <div
                          className="p-2 mt-2"
                          style={{
                            backgroundImage:
                              "linear-gradient(302.97deg, #0C3094 3.52%, #889FDD 80.16%)",
                            borderRadius: "12px",
                            color: "#fff",
                          }}
                        >
                          <div className="text-center fw-bolder">WIP</div>
                          <div
                            className="text-center fw-bolder"
                            style={{ color: "#fff", fontSize: "18px" }}
                          >
                            {prospectCount?.funding_wip_count}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="text-center">
                          <FormControl
                            className="p-3 ps-5 pe-5"
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            }}
                          >
                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              onChange={(e) => {
                                setPieFilter(e.target.value);
                                setPieLoader(true);
                              }}
                              value={pieFilter}
                            >
                              <FormControlLabel
                                value="by_volume"
                                control={<Radio />}
                                label="By Volume"
                              />
                              <FormControlLabel
                                value="by_value"
                                control={<Radio />}
                                label="By Value"
                              />
                            </RadioGroup>
                          </FormControl>

                          <div className="d-flex gap-2 mt-3">
                            <div
                              className="legend-color-box ms-5"
                              style={{ backgroundColor: "#329DC9" }}
                            ></div>
                            <div className=""> WIP</div>
                          </div>
                          <div className="d-flex mt-2 gap-2">
                            <div
                              className="legend-color-box ms-5"
                              style={{ backgroundColor: "#FF603D" }}
                            ></div>
                            <div className="me-2">Query</div>
                          </div>
                        </div>
                        <div
                          className=" mt-4 p-2"
                          style={{
                            backgroundImage:
                              "linear-gradient(302.97deg, #0C3094 3.52%, #889FDD 80.16%)",
                            borderRadius: "12px",
                            color: "#fff",
                          }}
                        >
                          <div className="text-center fw-bolder">Query</div>
                          <div
                            className="text-center fw-bolder"
                            style={{ color: "#fff", fontSize: "18px" }}
                          >
                            {prospectCount?.funding_query_count}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {tabValue === "funding_orders" ? (
                  <>
                    <div className="mb-1 mt-2 ms-0">
                      <input
                        className="p-2"
                        style={{
                          backgroundColor: "#ffffff",
                          color: "rgb(12, 48, 148)",
                          border: "1px solid rgb(12, 48, 148)",
                          borderRadius: "8px",
                        }}
                        id="date"
                        value={fromDate}
                        type="date"
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                      <span
                        className=" fw-bolder p-2 pt-3"
                        style={{ marginTop: "18px", fontSize: "30px" }}
                      >
                        {" "}
                        -{" "}
                      </span>
                      <input
                        className="p-2"
                        style={{
                          backgroundColor: "#ffffff",
                          color: "rgb(12, 48, 148)",
                          border: "1px solid rgb(12, 48, 148)",
                          borderRadius: "8px",
                        }}
                        id="date"
                        value={toDate}
                        type="date"
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                      />
                    </div>
                    {filtervalue === "wip" ? (
                      <div className="map-box  mt-3">
                        <div className="">
                          <div className="map-box-title ms-3 mt-1">WIP</div>
                        </div>

                        <div id="chart">
                          <ReactApexChart
                            options={barStateProspects.options}
                            series={barStateProspects.series}
                            type="bar"
                            height={270}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {filtervalue === "query" ? (
                  <div className="map-box  mt-4">
                    <div className="">
                      <div className="map-box-title ms-3 mt-1">
                        Query Reason
                      </div>
                    </div>

                    <div id="chart">
                      <ReactApexChart
                        options={barState.options}
                        series={barState.series}
                        type="bar"
                        height={260}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {filtervalue === "approved" ? (
                  <>
                    <div className="map-box  mt-4">
                      <div className="">
                        <div className="map-box-title ms-3 mt-1">Approved</div>
                      </div>

                      <div id="chart">
                        <ReactApexChart
                          options={approveBarState.options}
                          series={approveBarState.series}
                          type="bar"
                          height={260}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {filtervalue === "rejected" ? (
                  <>
                    <div className="map-box  mt-4">
                      <div className="">
                        <div className="map-box-title ms-3 mt-1">Rejected</div>
                      </div>

                      <div id="chart">
                        <ReactApexChart
                          options={rejectedBarState.options}
                          series={rejectedBarState.series}
                          type="bar"
                          height={260}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {tabValue === "repayment_orders" ? (
              <>
                <div className="map-box p-4">
                  <div className="map-box-title" style={{ fontSize: "14px" }}>
                    Orders for {state}
                  </div>
                  {pieLoader ? (
                    <HomeData />
                  ) : (
                    <div className="row">
                      <div className="col col-md-6 col-lg-6 col-sm-6 mt-3">
                        <div className="">
                          <ReactApexChart
                            options={closedPieChart.options}
                            series={closedPieChart.series}
                            type="pie"
                          />
                        </div>
                        <div
                          className="p-2 mt-2"
                          style={{
                            backgroundImage:
                              "linear-gradient(302.97deg, #0C3094 3.52%, #889FDD 80.16%)",
                            borderRadius: "12px",
                            color: "#fff",
                          }}
                        >
                          <div className="text-center fw-bolder">Disbursed</div>
                          <div
                            className="text-center fw-bolder"
                            style={{ color: "#FFF", fontSize: "18px" }}
                          >
                            {prospectCount?.rep_disbursed_count
                              ? prospectCount?.rep_disbursed_count
                              : 0}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="text-center">
                          <FormControl
                            className="p-3 ps-5 pe-5"
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "8px",
                            }}
                          >
                            <RadioGroup
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              onChange={(e) => {
                                setPieFilter(e.target.value);
                                setPieLoader(true);
                              }}
                              value={pieFilter}
                            >
                              <FormControlLabel
                                value="by_volume"
                                control={<Radio />}
                                label="By Volume"
                              />
                              <FormControlLabel
                                value="by_value"
                                control={<Radio />}
                                label="By Value"
                              />
                            </RadioGroup>
                          </FormControl>

                          <div className="d-flex gap-2 mt-3">
                            <div
                              className="legend-color-box ms-5"
                              style={{ backgroundColor: "#329DC9" }}
                            ></div>
                            <div className=""> Disbursed</div>
                          </div>
                          <div className="d-flex mt-2 gap-2">
                            <div
                              className="legend-color-box ms-5"
                              style={{ backgroundColor: "#FF603D" }}
                            ></div>
                            <div className="me-2">Paid</div>
                          </div>
                        </div>
                        <div
                          className=" mt-4 p-2"
                          style={{
                            backgroundImage:
                              "linear-gradient(302.97deg, #0C3094 3.52%, #889FDD 80.16%)",
                            borderRadius: "12px",
                            color: "#fff",
                          }}
                        >
                          <div className="text-center fw-bolder">Paid</div>
                          <div
                            className="text-center fw-bolder"
                            style={{ color: "#FFF", fontSize: "18px" }}
                          >
                            {prospectCount?.rep_total_paid
                              ? prospectCount?.rep_total_paid
                              : 0}
                          </div>
                        </div>

                        <div></div>
                      </div>
                    </div>
                  )}
                </div>

                {filtervalue === "repayment_orders" ? (
                  <>
                    <div className="mb-1 mt-2 ms-0">
                      <input
                        className="p-2"
                        style={{
                          backgroundColor: "#ffffff",
                          color: "rgb(12, 48, 148)",
                          border: "1px solid rgb(12, 48, 148)",
                          borderRadius: "8px",
                        }}
                        id="date"
                        value={fromDate}
                        type="date"
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                      <span
                        className=" fw-bolder p-2 pt-3"
                        style={{ marginTop: "18px", fontSize: "30px" }}
                      >
                        {" "}
                        -{" "}
                      </span>
                      <input
                        className="p-2"
                        style={{
                          backgroundColor: "#ffffff",
                          color: "rgb(12, 48, 148)",
                          border: "1px solid rgb(12, 48, 148)",
                          borderRadius: "8px",
                        }}
                        id="date"
                        value={toDate}
                        type="date"
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                      />
                      {/* {fromDate || toDate ? (
                    <Button
                      className="ms-3 p-1"
                      variant="outlined"
                      onClick={() => {
                        setFromDate("2020-04-01");
                        setToDate("2023-03-31");
                      }}
                    >
                      Clear date
                    </Button>
                  ) : (
                    ""
                  )} */}
                    </div>
                    <div className="map-box  mt-4">
                      <div className="">
                        <div className="map-box-title ms-3 mt-1">
                          Approved FYTD
                        </div>
                      </div>

                      <div id="chart">
                        <ReactApexChart
                          options={barStateClosed.options}
                          series={barStateClosed.series}
                          type="bar"
                          height={260}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {tabValue === "repayment_orders" ? (
                  <>
                    <div className="mb-1 mt-2 ms-0">
                      <input
                        className="p-2"
                        style={{
                          backgroundColor: "#ffffff",
                          color: "rgb(12, 48, 148)",
                          border: "1px solid rgb(12, 48, 148)",
                          borderRadius: "8px",
                        }}
                        id="date"
                        value={fromDate}
                        type="date"
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                      <span
                        className=" fw-bolder p-2 pt-3"
                        style={{ marginTop: "18px", fontSize: "30px" }}
                      >
                        {" "}
                        -{" "}
                      </span>
                      <input
                        className="p-2"
                        style={{
                          backgroundColor: "#ffffff",
                          color: "rgb(12, 48, 148)",
                          border: "1px solid rgb(12, 48, 148)",
                          borderRadius: "8px",
                        }}
                        id="date"
                        value={toDate}
                        type="date"
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                      />
                      {/* {fromDate || toDate ? (
                    <Button
                      className="ms-3 p-1"
                      variant="outlined"
                      onClick={() => {
                        setFromDate("2020-04-01");
                        setToDate("2023-03-31");
                      }}
                    >
                      Clear date
                    </Button>
                  ) : (
                    ""
                  )} */}
                    </div>
                    <div className="map-box  mt-4">
                      <div className="">
                        <div className="map-box-title ms-3 mt-1">Repayment</div>
                      </div>

                      <div id="chart">
                        <ReactApexChart
                          options={repaymentStackbar.options}
                          series={repaymentStackbar.series}
                          type="bar"
                          height={260}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapPage;
