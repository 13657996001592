import {
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableFooter,
} from '@mui/material';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../Client Management/clientManagement.css';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import HomeData from '../common/HomeData';
import ClientAssignLimit from './ClientAssignLimit';
import ClientQuery from './ClientQuery';
function ClientTabel({
  tabValue,
  filtervalue,
  search,
  filterType,
  from_date,
  toDate,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const token = localStorage.getItem('token');
  const [camId, setCamId] = useState();
  const [queryType, setQueryType] = useState();
  const [query, setQuery] = useState();
  const options = [
    { value: 'low_ayescore', label: 'Low ayescore' },
    { value: 'incomplete_documents', label: 'Incomplete documents' },
    { value: 'privious_dues', label: 'Privious dues' },
  ];
  const [openlimitModel, setOpenLimitModel] = useState(false);
  const closeLimitModel = () => setOpenLimitModel(false);
  const [openQueryModel, setOpenQueryModel] = useState(false);
  const closeQueryModel = () => setOpenQueryModel(false);
  const notify = () => {
    toast.success(' Move customer to prospect Sucessfully', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  const notifyDecline = () => {
    toast.success(' Declined Sucessfully', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  const notifyReopen = () => {
    toast.success(' Reopened Sucessfully', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  const isUser = () => {
    toast.error('Error', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  const getAllData = async () => {
    const query = [];
    if (page === '') return;
    if (search) {
      query.push(`search=${search}`);
    }
    if (from_date && toDate) {
      query.push(`&from_date=${from_date}&to_date=${toDate}`);
    }
    const response = await axios.get(
      `/api/client_management/${tabValue}?start=${
        page * perPage
      }&length=${perPage}&prospect_type=${filtervalue}${
        query?.length ? '&' + query.join('&') : ''
      }`,
      { headers: { Authorization: `Bearee ${token}` } }
    );
    if (response?.data) {
      if (response?.data?.message === 'JSON Web Token Is Expired, Try Again')
        navigate('/');
      // console.log(response?.data);
      setTotalRecords(response?.data?.count);
      setData(response?.data?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();

    // console.log(tabValue, filtervalue);
    // axios
    //   .get(
    //     `/api/client_management/${tabValue}?start=${
    //       page * perPage
    //     }&length=${perPage}&prospect_type=${filtervalue}`,
    //     { headers: { Authorization: `Bearee ${token}` } }
    //   )
    //   .then((response) => {
    //     if (response?.data?.message === "JSON Web Token Is Expired, Try Again")
    //       navigate("/");
    //     // console.log(response?.data);
    //     setTotalRecords(response?.data?.count);
    //     setData(response?.data?.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, [page]);

  function pathColorselector(row) {
    if (row <= 120 && row > 0) {
      return 'rgb(210, 47, 76)';
    }
    if (row <= 200 && row > 121) {
      return 'rgb(255, 191, 2)';
    }
    if (row <= 280 && row > 201) {
      return 'rgb(17, 165, 175)';
    }
    if (row <= 360 && row > 280) {
      return 'rgb(0, 124, 255)';
    }
    if (row <= 460 && row > 361) {
      return 'rgb(45, 164, 70)';
    }
  }

  function trailColorselector(row) {
    if (row <= 120 && row > 0) {
      return 'rgb(238 165 172)';
    }
    if (row <= 200 && row > 121) {
      return '#f0d892';
    }
    if (row <= 280 && row > 201) {
      return '#b6cfd1';
    }
    if (row <= 360 && row > 280) {
      return '#d7e6f5';
    }
    if (row <= 460 && row > 361) {
      return '#89eb9f';
    }
  }

  //==================== Number Calculater in Cr ,L ===============

  const formatCurrency = (value) => {
    let number = parseFloat(value);
    if (isNaN(number)) {
      return value;
    }
    if (number / 1e7 >= 1) {
      number = (number / 1e7).toFixed(2) + ' C';
    } else if (number / 1e5 >= 1) {
      number = (number / 1e5).toFixed(2) + ' L';
    } else if (number / 1e3 >= 1) {
      number = (number / 1e3).toFixed(2) + ' K';
    }

    return number;
  };
  // ================== Pagination ===================================
  const prevHandler = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const nextHandler = () => {
    if ((page + 1) * perPage < totalRecords) {
      setPage(page + 1);
    }
  };

  const showingRecords = useMemo(() => {
    const from = 1 + perPage * page;
    let to = perPage * (page + 1);
    if (totalRecords <= to) {
      to = totalRecords;
    }
    return `Showing ${from}-${to} of ${totalRecords}`;
  }, [perPage, page, totalRecords]);

  // ========================== Search Function =========================
  useEffect(() => {
    setPage('');
    setTimeout(() => {
      setPage(0);
    }, 100);
  }, [search, tabValue, filtervalue, from_date, toDate]);

  const orderClickHandalr = (id) => {
    if (id) navigate(`/clientManagement/View/${tabValue}/${id}`);
    // getCAMById(id)
  };

  const yesApprove = (id) => {
    if (id) {
      axios
        .patch(
          `/api/client_management/change_prospects_status`,
          {
            user_id: id,
            prospect_status: 'approved',
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          // onClose();
          notify();
          getAllData();
        })
        .catch((error) => {
          console.log(error);
          isUser();
        });
    }
  };
  const approveFunction = (id) => {
    setCamId(id);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: '#F5F5F5',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <h2>Are you sure?</h2>
            <p>You want to approve this?</p>
            <Button variant="contained" onClick={onClose} className="me-2 m-3">
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                yesApprove(id);
                onClose();
              }}
            >
              Yes, Approve it!
            </Button>
          </div>
        );
      },
    });
  };

  const approveInprogressFunction = (id) => {
    setCamId(id);
    setOpenLimitModel(true);
    // setCamId(id);
    // confirmAlert({
    //   customUI: ({ onClose }) => {
    //     return (
    //       <div
    //         className="custom-ui p-4"
    //         style={{
    //           backgroundColor: "#F5F5F5",
    //           boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    //         }}
    //       >
    //         <h2>Are you sure?</h2>
    //         <p>You want to approve this?</p>
    //         <p>Please enter assign limit</p>
    //         <input
    //           type="number"
    //           placeholder="Assign limit .."
    //           onClick={(e) => console.log(e.target.value)}
    //         />
    //         <span style={{ color: "red", fontSize: "12px" }}>{error}</span>
    //         <br />

    //         <Button
    //           variant="contained"
    //           onClick={onClose}
    //           className="me-2 m-3 ms-0"
    //         >
    //           No
    //         </Button>
    //         <Button
    //           variant="contained"
    //           onClick={() => {
    //             yesApproveInprogress();
    //             assignLimit ? onClose() : "";
    //           }}
    //         >
    //           Yes, Approve it!
    //         </Button>
    //       </div>
    //     );
    //   },
    // });
  };

  // const sendQuery = () => {
  //   setIsQuery(true);
  //   const err = {};
  //   if (!query) {
  //     err.query = "Please enter a query..";
  //   }
  //   if (!queryType) {
  //     err.queryType = " Please select query type..";
  //   }
  //   setQueryError(err);
  //   if (Object.keys(queryError).length === 0 && isQuery) {
  //     axios
  //       .patch(
  //         `/api/client_management/change_inprogess_status`,
  //         {
  //           user_id: "12995",
  //           prospect_status: "query",
  //           query_reason: query,
  //           query_type: queryType,
  //         },
  //         {
  //           headers: { Authorization: `Bearee ${token}` },
  //         }
  //       )
  //       .then((response) => {
  //         console.log(response);
  //         setIsQuery(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setIsQuery(false);
  //       });
  //   }
  // };

  // useEffect(() => {}, [isQuery]);
  // const askQuery = () => {
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div
  //           className="custom-ui p-4"
  //           style={{
  //             backgroundColor: "#F5F5F5",
  //             boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  //           }}
  //         >
  //           <h2>Are you sure?</h2>
  //           <p>You want to query this?</p>
  //           <p>Please select query reason</p>
  //           <Select
  //             options={options}
  //             onChange={handleSelectChange}
  //             placeholder="Program Name"
  //             styles={customStyles}
  //           />
  //           <br />
  //           <span style={{ color: "red", fontSize: "12px" }}>
  //             {queryError?.queryType}{" "}
  //           </span>
  //           <br />
  //           <input
  //             type="text"
  //             placeholder="Enter query..."
  //             className="p-2"
  //             style={{ borderRadius: "5px" }}
  //             onChange={(e) => setQuery(e.target.value)}
  //           />
  //           <br />
  //           <span style={{ color: "red", fontSize: "12px" }}>
  //             {queryError?.query}{" "}
  //           </span>
  //           <br />
  //           <Button
  //             variant="contained"
  //             onClick={onClose}
  //             className="me-2 m-3 ms-0"
  //           >
  //             No
  //           </Button>
  //           <Button
  //             variant="contained"
  //             onClick={() => {
  //               sendQuery();
  //               query && queueMicrotask ? onClose() : "";
  //             }}
  //           >
  //             Yes, Query it!
  //           </Button>
  //         </div>
  //       );
  //     },
  //   });
  // };
  const declineInprogressFunction = (id) => {
    setCamId(id);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: '#F5F5F5',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <p>You want to query or decline this?</p>
            {/* <h2>Are you sure?</h2>
            <p>You want to Reopen this?</p>
            <Button variant="contained" onClick={onClose} className="me-2 m-3">
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                // yesDicline({});
                onClose();
              }}
            >
              Yes, Reopen it!
            </Button> */}
            <Button
              variant="contained"
              className="me-2"
              // onClick={yesDicline()}
              onClick={() => {
                yesDicline(id);
                onClose();
              }}
            >
              Decline
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenQueryModel(true);
                onClose();
              }}
            >
              Query
            </Button>
          </div>
        );
      },
    });
  };
  const yesDicline = (id) => {
    if (id) {
      axios
        .patch(
          `/api/client_management/change_prospects_status`,
          {
            user_id: id,
            prospect_status: 'declined',
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          // onClose();
          notifyDecline();
          getAllData();
        })
        .catch((error) => {
          console.log(error);
          isUser();
        });
    }
  };
  const declineFunction = async (id) => {
    setCamId(id);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: '#F5F5F5',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <h2>Are you sure?</h2>
            <p>You want to Decline this?</p>
            <Button variant="contained" onClick={onClose} className="me-2 m-3">
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                yesDicline(id);
                onClose();
              }}
            >
              Yes, Decline it!
            </Button>
          </div>
        );
      },
    });
  };

  const yesReopen = () => {
    if (camId) {
      axios
        .patch(
          `/api/client_management/convert_expired_available `,
          {
            user_id: camId,
            prospect_status: 'available',
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          // onClose();
          notifyReopen();
          getAllData();
        })
        .catch((error) => {
          console.log(error);
          isUser();
        });
    }
  };
  const reopenFunction = (id) => {
    setCamId(id);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: '#F5F5F5',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <h2>Are you sure?</h2>
            <p>You want to Reopen this?</p>
            <Button variant="contained" onClick={onClose} className="me-2 m-3">
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                yesReopen({});
                onClose();
              }}
            >
              Yes, Reopen it!
            </Button>
          </div>
        );
      },
    });
  };

  // Filter by Date
  //console.log(filterType, "check");

  useEffect(() => {
    //console.log("date called");
    data?.sort(function (a, b) {
      return new Date(b.login_date) - new Date(a.login_date);
    });
    // console.log(res);

    // console.log(data);
  }, [filterType]);

  return (
    <div>
      <TableContainer
        className="mt-4 rounded-md overflow-hidden shadow-lg"
        // style={{ backgroundColor: "#fff" }}
      >
        <Table
          size="large"
          aria-label="a dense table"
          // style={{ borderRadius: "5px", backgroundColor: "#F6FAFF" }}
          style={{ borderRadius: '5px', backgroundColor: '#f1f1f1' }}
        >
          <TableHead className=" bg-slate-600 text-white p-2">
            <TableRow className="pt-4 pb-4" style={{ borderRadius: '5px' }}>
              <TableCell className="fw-bolder pt-3 pb-3 text-white ">
                Login Date
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white ">
                Partner name
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white ">
                Party Region
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white ">
                Business Nature
              </TableCell>
              {/* <TableCell align="center" className="fw-bolder">
                Business Type
              </TableCell> */}
              <TableCell align="center" className="fw-bolder text-white ">
                GST Turn over
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white ">
                Vintage (in yrs)
              </TableCell>
              <TableCell align="center" className="fw-bolder text-white ">
                Age on Network
              </TableCell>
              {tabValue === 'closed_orders' && filtervalue === 'approved' ? (
                <TableCell align="center" className="fw-bolder text-white ">
                  Limit Approved
                </TableCell>
              ) : (
                ''
              )}
              {tabValue === 'closed_orders' && filtervalue === 'declined' ? (
                <TableCell align="center" className="fw-bolder text-white ">
                  Decline Reason
                </TableCell>
              ) : (
                ''
              )}
              {(tabValue === 'closed_orders' && filtervalue === 'approved') ||
              filtervalue === 'declined' ? (
                ''
              ) : (
                <TableCell align="center" className="fw-bolder text-white ">
                  Ayescore
                </TableCell>
              )}

              <TableCell align="center" className="fw-bolder text-white ">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={9}>
                  <HomeData />
                </TableCell>
              </TableRow>
            </TableFooter>
          ) : (
            <>
              {data?.length === 0 ? (
                <>
                  <TableCell className="pt-4 pb-4"></TableCell>
                  <TableCell className="pt-4 pb-4"></TableCell>
                  <TableCell className="pt-4 pb-4"></TableCell>
                  <TableCell className="pt-4 pb-4"></TableCell>
                  <TableCell className="pt-4 pb-4">
                    <h2 className="fw-bolder">No data found</h2>
                  </TableCell>
                  <TableCell className="pt-4 pb-4"></TableCell>
                  <TableCell className="pt-4 pb-4"></TableCell>
                  <TableCell className="pt-4 pb-4"></TableCell>
                  <TableCell className="pt-4 pb-4"></TableCell>
                </>
              ) : (
                <TableBody>
                  {data?.map((row, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: index % 2 == 0 ? '#fff' : '#ededed',
                      }}
                    >
                      <TableCell className="pt-4 pb-4">
                        {}
                        {row?.login_date
                          ? moment(row.login_date).format('ll')
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row.party_name ? row?.party_name : ''}
                      </TableCell>

                      <TableCell align="center">
                        {row.party_region ? row?.party_region : ''}
                      </TableCell>
                      <TableCell align="center" className="pt-4 pb-4">
                        {row.business_name ? row?.business_name : ''}
                      </TableCell>
                      {/* <TableCell align="center">
                        {row.business_type ? row?.business_type : ""}
                      </TableCell> */}
                      <TableCell align="center">
                        {row?.annual_turnover ? row?.annual_turnover : '0'}
                      </TableCell>
                      <TableCell align="center">
                        {row.business_vintage ? row?.business_vintage : '0'}
                      </TableCell>
                      <TableCell align="center">
                        {row.age_on_network ? row.age_on_network : '0'}
                      </TableCell>

                      {tabValue !== 'closed_orders' ? (
                        <TableCell align="center">
                          <div style={{ width: 60, height: 60 }}>
                            <CircularProgressbar
                              className="fw-bolder"
                              value={row.ayescore}
                              size={500}
                              maxValue={460}
                              text={`${row.ayescore}`}
                              strokeWidth={15}
                              styles={buildStyles({
                                textColor: 'black',
                                pathColor: pathColorselector(row?.ayescore),
                                trailColor: trailColorselector(row?.ayescore),
                                width: '60px',
                              })}
                            />
                          </div>
                        </TableCell>
                      ) : (
                        ''
                      )}
                      {tabValue === 'closed_orders' &&
                      filtervalue === 'approved' ? (
                        <TableCell>
                          <div style={{ width: 60, height: 60 }}>
                            <CircularProgressbar
                              className="fw-bolder"
                              value={
                                row?.utilize_credit_limit
                                  ? Number(row?.utilize_credit_limit)
                                  : 0
                              }
                              size={500}
                              maxValue={Number(row?.assigin_credit_limit)}
                              text={`${
                                row.utilize_credit_limit
                                  ? formatCurrency(
                                      Number(row?.assigin_credit_limit)
                                    )
                                  : 0
                              }`}
                              strokeWidth={15}
                              styles={buildStyles({
                                textColor: 'black',
                                pathColor: pathColorselector(
                                  row?.utilize_credit_limit
                                    ? row?.utilize_credit_limit
                                    : 0
                                ),
                                trailColor: trailColorselector(
                                  row?.utilize_credit_limit
                                    ? row?.utilize_credit_limit
                                    : 0
                                ),
                                width: '60px',
                              })}
                            />
                          </div>
                        </TableCell>
                      ) : (
                        ''
                      )}

                      {tabValue === 'closed_orders' &&
                      filtervalue === 'declined' ? (
                        <TableCell align="center" style={{ color: 'red' }}>
                          {row?.declined_reason ? row?.declined_reason : ''}
                        </TableCell>
                      ) : (
                        ''
                      )}
                      <TableCell align="center">
                        {(tabValue === 'prospects_orders' &&
                          filtervalue === 'available') ||
                        tabValue === 'In_progess_orders' ? (
                          <>
                            <button
                              type="button"
                              className="p-1"
                              title="View"
                              style={{
                                minWidth: '130px',
                                border: 'none',
                                backgroundColor: '#218E4F',
                                color: 'white',
                                borderRadius: '5px',
                              }}
                              onClick={() =>
                                tabValue === 'prospects_orders'
                                  ? approveFunction(row?.user_id)
                                  : approveInprogressFunction(row?.user_id)
                              }
                            >
                              Move to prospect{' '}
                              {/* <CheckIcon
                                role="button"
                                style={{ color: 'green' }}
                                onClick={() =>
                                  tabValue === 'prospects_orders'
                                    ? approveFunction(row?.user_id)
                                    : approveInprogressFunction(row?.user_id)
                                }
                              /> */}
                            </button>
                          </>
                        ) : (
                          ''
                        )}
                        <br />
                        <button
                          type="button"
                          className="p-1 m-1"
                          title="View"
                          style={{
                            minWidth: '130px',
                            border: 'none',
                            backgroundColor: '#1B60A6',
                            color: 'white',
                            borderRadius: '5px',
                          }}
                          onClick={() => orderClickHandalr(row?.user_id)}
                        >
                          View
                          {/* <VisibilityIcon
                            role="button"
                            style={{ color: '#0C3094' }}
                            onClick={() => orderClickHandalr(row?.user_id)}
                          /> */}
                        </button>
                        <br />

                        {(tabValue === 'prospects_orders' &&
                          filtervalue === 'available') ||
                        tabValue === 'In_progess_orders' ? (
                          <>
                            <button
                              type="button"
                              className="p-1"
                              style={{
                                minWidth: '130px',
                                border: 'none',
                                backgroundColor: '#A70A21',
                                color: 'white',
                                borderRadius: '5px',
                              }}
                              onClick={() =>
                                tabValue === 'prospects_orders'
                                  ? declineFunction(row?.user_id)
                                  : declineInprogressFunction(row?.user_id)
                              }
                            >
                              Rejecte
                              {/* <ClearIcon
                                role="button"
                                style={{ color: '#B93D16' }}
                                onClick={() =>
                                  tabValue === 'prospects_orders'
                                    ? declineFunction(row?.user_id)
                                    : declineInprogressFunction(row?.user_id)
                                }
                              /> */}
                            </button>
                          </>
                        ) : (
                          ''
                        )}
                        {tabValue === 'closed_orders' &&
                        filtervalue === 'declined' ? (
                          <button
                            type="button"
                            title="Reopen"
                            className="p-1 m-1"
                            style={{
                              minWidth: '130px',
                              border: 'none',
                              backgroundColor: '#B77F08',
                              color: 'white',
                              borderRadius: '5px',
                            }}
                            onClick={() => reopenFunction(row?.user_id)}
                          >
                            Reopen{' '}
                            {/* <SettingsBackupRestoreIcon
                              role="button"
                              style={{ color: '#0C3094' }}
                              onClick={() => reopenFunction(row?.user_id)}
                            /> */}
                          </button>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      {/*------------------------------------ Action buttons for table end----------------------- */}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      {data?.length !== 0 && !loading ? (
        <div className="mt-3">
          <span className="fw-bolder">{showingRecords}</span>
          <button
            className="next-prev-btn me-2 p-1 ms-2 ps-2 pe-2"
            onClick={prevHandler}
          >
            Prev
          </button>
          <button
            className="next-prev-btn p-1 ps-2 pe-2"
            onClick={nextHandler}
            disabled={totalRecords === (page + 1) * perPage ? true : false}
          >
            Next
          </button>
        </div>
      ) : (
        ''
      )}
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ClientAssignLimit
        openlimitModel={openlimitModel}
        closeLimitModel={closeLimitModel}
        getAllData={getAllData}
        camId={camId}
      />
      <ClientQuery
        openQueryModel={openQueryModel}
        closeQueryModel={closeQueryModel}
        getAllData={getAllData}
        camId={camId}
      />
    </div>
  );
}

export default ClientTabel;
