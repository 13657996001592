import React, { useEffect, useState } from 'react';
import '../Client Management/clientManagement.css';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@mui/material';
import { MdOutlineExpandMore, MdPictureAsPdf } from 'react-icons/md';
import chatIcon from '../Client Management/Images/chat bubbles.svg';
import fileIcon from '../Client Management/Images/fille.svg';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import HomeData from '../common/HomeData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClientAssignLimit from './ClientAssignLimit';
import ClientQuery from './ClientQuery';
import ClientViewMerchantInsight from './ClientViewMerchantInsight';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function ClientView() {
  const { status, id } = useParams();
  const navigate = useNavigate();
  console.log(status);
  const [loading, setLoading] = useState(true);
  const [camData, setCamData] = useState();
  const token = localStorage.getItem('token');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [personalExpandFlag, setPersonalExpandFlag] = useState(true);
  const [personalInfo, setPersonalInfo] = useState(true);
  const [businessExpandFlag, setBusinessExpandFlag] = useState(true);
  const [residenceExpandFlag, setResidenceExpandFlag] = useState(true);
  const [additionalExpandFlag, setAdditionalExpandFlag] = useState(true);
  const [officeInfoFlag, setOfficeInfoFlag] = useState(true);
  const [exURL, setexURL] = useState();
  const [VehiclesFlag, setVehiclesFlag] = useState(true);
  const [businessDetailsExpandFlag, setBusinessDetailsExpandFlag] =
    useState(true);
  const [ViewAnalysisFlag, setViewAnalysisFlag] = useState(false);
  const [bankAnalysisFlag, setBankAnalysisFlag] = useState(false);
  const [ViewAnalysisData, setViewAnalysisData] = useState();
  const [RefExpandFlag, setRefExpandFlag] = useState(true);
  const [SurroundingExpandFlag, setSurroundingExpandFlag] = useState(true);
  const [FinancialExpandFlag, setFinancialExpandFlag] = useState(true);
  const [kycDocumentFlag, setKycDocumentFlag] = useState(true);
  const [financialDocFlag, setFinancialDocFlag] = useState(true);
  const [businessDocExpandFlag, setBusinessDocExpandDoc] = useState(true);
  const [otherDocExpandFlag, setOtherDocExpandFlag] = useState(true);
  const [banckStmtFlag, setBankStmtFlag] = useState(true);
  const [b2bId, setB2bId] = useState();

  const [fetchError, setFetchError] = useState(false);

  const personalClickHandler = () => {
    setPersonalExpandFlag(!personalExpandFlag);
  };
  const personalInfoClickHandler = () => {
    setPersonalInfo(!personalInfo);
  };
  const businessClickHandler = () => {
    setBusinessExpandFlag(!businessExpandFlag);
  };
  const residenceClickHandler = () => {
    setResidenceExpandFlag(!residenceExpandFlag);
  };

  const AdditionalClickHandler = () => {
    setAdditionalExpandFlag(!additionalExpandFlag);
  };
  const officeInfoClickHandler = () => {
    setOfficeInfoFlag(!officeInfoFlag);
  };
  const vehiclesClickHandler = () => {
    setVehiclesFlag(!VehiclesFlag);
  };
  const businessDetailsClickHandler = () => {
    setBusinessDetailsExpandFlag(!businessDetailsExpandFlag);
  };
  const surroundedClickHandler = () => {
    setSurroundingExpandFlag(!SurroundingExpandFlag);
  };
  const financialClickHandler = () => {
    setFinancialExpandFlag(!FinancialExpandFlag);
  };
  const kycDocClickHandler = () => {
    setKycDocumentFlag(!kycDocumentFlag);
  };
  const financialDocClickHandler = () => {
    setFinancialDocFlag(!financialDocFlag);
  };
  const banckStmtClickHandler = () => {
    setBankStmtFlag(!banckStmtFlag);
  };
  const businessDocClickHandler = () => {
    setBusinessDocExpandDoc(!businessDocExpandFlag);
  };
  const otherDocClickHandler = () => {
    setOtherDocExpandFlag(!otherDocExpandFlag);
  };
  const refDetailsClickHandler = () => {
    setRefExpandFlag(!RefExpandFlag);
  };
  const getCAMById = async () => {
    const response = await axios.get(
      `/api/client_management/mitra-cam-details?user_id=${id}`,
      { headers: { Authorization: `Bearee ${token}` } }
    );

    if (response.data.status === false) {
      setFetchError(true);
      setLoading(false);
      return;
    }

    if (response?.data) {
      console.log(response?.data);
      setCamData(response?.data?.data ? response?.data?.data[0] : '');
      setLoading(false);
    }
  };
  useEffect(() => {
    getCAMById();
  }, [id]);

  // console.log(status);
  // ===================================================== Download Image ========================================//
  const downloadImage = (imageURL, name) => {
    axios
      .get(imageURL, { responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      });
  };
  // ================================================== Download PDF =============================================//
  const downloadPDF = (pdfURL, name) => {
    if (pdfURL === 'null' || pdfURL === '' || pdfURL === null) {
    } else {
      axios
        .get(pdfURL, { responseType: 'arraybuffer' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        })
        .catch((error) => {
          console.error('Error downloading PDF:', error);
        });
    }
  };
  // =================================================== Documet view =========================================
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  //=================================================== Download xl file ==============================================//
  const getFileToDownload = (url) => {
    const finalURLS = [];
    camData?.bank_statement_excle_cam_link?.map((item) => {
      if (item.url === url) {
        finalURLS.push(item?.S3_excle_link);
      } else {
        return '';
      }
    });
    const fileUrl = finalURLS[0];
    if (!fileUrl) {
      console.log('File URL not available');
      return;
    }
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'file.xlsx';
    link.click();
  };
  //====================== Download All Documents ====================================//
  const handleAllDOCDownload = () => {
    for (let i = 0; i < allDoc?.length; i++) {
      let url, name;
      if (allDoc[i] !== null) {
        if (typeof allDoc[i] === 'object') {
          url = allDoc[i].url;
          name = `Doc${i}`;
        } else {
          url = allDoc[i];
          name = `Doc${i}`;
        }
      }

      if (url !== undefined)
        if (url.endsWith('.pdf')) {
          const link = document.createElement('a');
          link.href = url;
          link.download = name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (url.match(/\.(jpg|jpeg|png|gif)$/)) {
          const img = document.createElement('img');
          img.crossOrigin = 'anonymous'; // set CORS attribute
          img.src = url;
          img.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };
        }
    }
  };

  const ClickViewAnalysis = (item) => {
    window.scrollTo(0, 0);
    setViewAnalysisData(item);
    setViewAnalysisFlag(true);
  };
  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  //=================Calculate amount in Indian format====================//
  function amountFormat(x, defaultValue = 0) {
    if (!x) {
      return defaultValue;
    }
    x = x.toString();
    var afterPoint = '';
    if (x?.indexOf('.') > 0)
      afterPoint = x.substring(x?.indexOf('.'), x?.length);
    x = Math.floor(x);
    x = x?.toString();
    var lastThree = x?.substring(x?.length - 3);
    var otherNumbers = x?.substring(0, x?.length - 3);
    if (otherNumbers != '') lastThree = ',' + lastThree;
    var res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
      lastThree +
      afterPoint;
    return res;
  }

  // =================================== check file id pdf file or not ======================================//
  function isPDForJPEG(url) {
    if (url !== undefined) return url.toLowerCase().endsWith('.pdf');
  }

  console.log(camData);
  // ======================== Approve =============================================
  const [openlimitModel, setOpenLimitModel] = useState(false);
  const closeLimitModel = () => setOpenLimitModel(false);
  const [openQueryModel, setOpenQueryModel] = useState(false);
  const closeQueryModel = () => setOpenQueryModel(false);
  const approveInprogressFunction = () => {
    console.log(id, 'dd');

    setOpenLimitModel(true);
    // setCamId(id);
    // confirmAlert({
    //   customUI: ({ onClose }) => {
    //     return (
    //       <div
    //         className="custom-ui p-4"
    //         style={{
    //           backgroundColor: "#F5F5F5",
    //           boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    //         }}
    //       >
    //         <h2>Are you sure?</h2>
    //         <p>You want to approve this?</p>
    //         <p>Please enter assign limit</p>
    //         <input
    //           type="number"
    //           placeholder="Assign limit .."
    //           onClick={(e) => console.log(e.target.value)}
    //         />
    //         <span style={{ color: "red", fontSize: "12px" }}>{error}</span>
    //         <br />

    //         <Button
    //           variant="contained"
    //           onClick={onClose}
    //           className="me-2 m-3 ms-0"
    //         >
    //           No
    //         </Button>
    //         <Button
    //           variant="contained"
    //           onClick={() => {
    //             yesApproveInprogress();
    //             assignLimit ? onClose() : "";
    //           }}
    //         >
    //           Yes, Approve it!
    //         </Button>
    //       </div>
    //     );
    //   },
    // });
  };

  const notifyDecline = () => {
    toast.success(' Declined Sucessfully', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  const isUser = () => {
    toast.error('Error', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  // ======================================== Decline ===============================================
  const declineFunction = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: '#F5F5F5',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <h2>Are you sure?</h2>
            <p>You want to Decline this?</p>
            <Button variant="contained" onClick={onClose} className="me-2 m-3">
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                yesDicline({});
                onClose();
              }}
            >
              Yes, Decline it!
            </Button>
          </div>
        );
      },
    });
  };
  const yesDicline = () => {
    if (id) {
      axios
        .patch(
          `/api/client_management/change_prospects_status`,
          {
            user_id: id,
            prospect_status: 'declined',
          },
          { headers: { Authorization: `Bearee ${token}` } }
        )
        .then((response) => {
          console.log(response);
          // onClose();
          notifyDecline();
          getAllData();
        })
        .catch((error) => {
          console.log(error);
          // isUser();
        });
    }
  };

  return (
    <div
      className="dashboard-page-with-sidebar container-fluid pe-0 me-5"
      style={{ paddingTop: '80px' }}
    >
      {loading ? (
        <HomeData />
      ) : fetchError ? (
        <ClientViewMerchantInsight user_id={id} isOpen={true} />
      ) : (
        <>
          <div className="d-flex justify-content-between mt-2">
            <h2 style={{ color: '#0C3093' }} className="text-3xl font-bold">
              CAM View{' '}
            </h2>
            <div className="d-flex justify-content-end gap-2">
              {status === 'In_progess_orders' ? (
                <div className="d-flex gap-3">
                  {/*<Button
                  variant="contained"
                  style={{ backgroundColor: "#32a852" }}
                  onClick={() => approveInprogressFunction()}
                >
                  Approve
                </Button>*/}
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'gray' }}
                    onClick={() => {
                      setOpenQueryModel(true);
                    }}
                  >
                    Send your query
                  </Button>
                  {/*<Button
                  variant="contained"
                  style={{ backgroundColor: "#f05d6c" }}
                  onClick={() => declineFunction()}
                >
                  Reject
                </Button>*/}
                </div>
              ) : (
                ''
              )}

              <Button
                variant="contained"
                onClick={() => {
                  navigate(`/clientManagement`);
                }}
                style={{ backgroundColor: '#0C3094' }}
              >
                Back
              </Button>
            </div>
          </div>
          {camData ? (
            <div className="m-1 mt-3">
              <div className="mb-2">
                <span className="ms-2">
                  <img alt="" src={chatIcon} />
                </span>
                <Button type="button" onClick={personalClickHandler}>
                  <span className="personal-title fw-bolder">
                    Personal Discussion
                  </span>
                  <MdOutlineExpandMore
                    className="personal-title"
                    style={{ fontSize: '28px' }}
                  />
                </Button>
              </div>
              {personalExpandFlag ? (
                <>
                  <div className=" mb-2 mt-1">
                    <div className="m-0 p-0">
                      <Button
                        type="button"
                        className="fw-bolder"
                        onClick={personalInfoClickHandler}
                        style={{ fontSize: '28px' }}
                      >
                        <span className="personal-sub-title ">
                          PERSONAL INFORMATION
                        </span>
                        <MdOutlineExpandMore
                          className="personal-title"
                          style={{ fontSize: '28px' }}
                        />
                      </Button>
                    </div>
                    {personalInfo ? (
                      <>
                        {personalInfo ? (
                          <div className="view-info-box mt-3">
                            <div className="row p-4">
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Existing User
                                </div>
                                {camData?.is_existing_user === null ? (
                                  '--NA--'
                                ) : (
                                  <button
                                    disabled
                                    className={
                                      camData?.is_existing_user
                                        ? ' view-info-box-value mt-2 bg-success btn'
                                        : ' view-info-box-value mt-2bg-danger btn'
                                    }
                                    style={{
                                      color: camData?.is_existing_user
                                        ? '#fff'
                                        : '#000',
                                    }}
                                  >
                                    {camData?.is_existing_user ? 'Yes' : 'No'}
                                  </button>
                                )}
                              </div>

                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">Name</div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.name === null
                                    ? '--NA--'
                                    : camData?.name}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Mobile No
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.mobile_number === null
                                    ? '--NA--'
                                    : camData?.mobile_number}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">Email</div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.email_address === null
                                    ? '--NA--'
                                    : camData?.email_address}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    <div className="mt-2">
                      <div className="m-0 p-0">
                        <Button type="button" onClick={businessClickHandler}>
                          <span className="personal-sub-title ">
                            BUSINESS INFORMATION
                          </span>
                          <MdOutlineExpandMore
                            className="personal-title"
                            style={{ fontSize: '28px' }}
                          />
                        </Button>
                      </div>
                      {businessExpandFlag ? (
                        <div className="">
                          <div className="business-title fw-bolder">
                            {camData?.name_of_the_business
                              ? ''
                              : camData?.name_of_the_business}
                          </div>
                          <div className="business-address mt-2 fw-bolder">
                            {camData?.business_address !== null &&
                            camData?.business_address?.includes('@')
                              ? camData?.business_address.replace(/@@/g, '')
                              : camData?.business_address}
                          </div>
                          <div className="view-info-box p-3 mt-2">
                            <div className="row p-4">
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Merchant Type
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.merchant_category === null
                                    ? '--NA--'
                                    : camData?.merchant_category}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Business Type
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.business_type === null
                                    ? '--NA--'
                                    : camData?.business_type}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Name of Business
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.name_of_the_business === null
                                    ? '--NA--'
                                    : camData?.name_of_the_business}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Nature of Business
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.nature_of_business === null
                                    ? '--NA--'
                                    : camData?.nature_of_business}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Business vintage (no. of years)
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.business_vintage === null
                                    ? 0
                                    : camData?.business_vintage}{' '}
                                  Years
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Date of Incoporation
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.date_of_incorporation === null
                                    ? ''
                                    : moment(
                                        camData?.date_of_incorporation
                                      ).format('ll')}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="view-info-box mt-3">
                            <div className="row p-4">
                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  TOP 3 Suppliers
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.procure_supplies_from?.length === 0
                                    ? '--NA--'
                                    : ''}
                                  {camData?.procure_supplies_from?.map(
                                    (item, index) => (
                                      <span>
                                        {/* {item}{" "}
                                                  {camData?.procure_supplies_from
                                                    ?.length > 1 &&
                                                    index <
                                                      camData?.procure_supplies_from
                                                        ?.length -
                                                        1} */}
                                        {item}{' '}
                                        {camData?.procure_supplies_from
                                          ?.length > 1 &&
                                        index <
                                          camData?.procure_supplies_from
                                            ?.length -
                                            1
                                          ? ','
                                          : ''}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  TOP 3 Buyers
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.where_does_it_get_sold_to
                                    ?.length === 0
                                    ? '--NA--'
                                    : ''}
                                  {camData?.where_does_it_get_sold_to?.map(
                                    (item, index) => (
                                      <span>
                                        {item}{' '}
                                        {camData?.where_does_it_get_sold_to
                                          ?.length > 1 &&
                                        index <
                                          camData?.where_does_it_get_sold_to
                                            ?.length -
                                            1
                                          ? ','
                                          : ''}
                                      </span>
                                    )
                                  )}
                                </div>
                              </div>

                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key">
                                  What are the commodities involved?
                                </div>

                                <div className="view-info-box-value mt-2">
                                  {camData?.commodities?.length === 0 ? '' : ''}
                                  {camData?.commodities?.map((item, index) => (
                                    <span>
                                      {item}{' '}
                                      {camData?.commodities?.length > 1 &&
                                      index < camData?.commodities?.length - 1
                                        ? ','
                                        : ''}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="view-info-box mt-3">
                            <div className="row p-4">
                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  Revenue per month
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {!camData?.revenue ? (
                                    ''
                                  ) : (
                                    <> INR {amountFormat(camData?.revenue)}</>
                                  )}
                                </div>
                              </div>
                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  Margin in the business(%)
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {' '}
                                  {camData?.margin_in_business === ''
                                    ? '--NA--'
                                    : camData?.margin_in_business}
                                </div>
                              </div>

                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  Last Financial Year (FY2022-2023) Turnover"
                                </div>
                                <div className="view-info-box-value mt-2">
                                  INR{' '}
                                  {camData?.annual_turnover === ''
                                    ? ''
                                    : amountFormat(
                                        Number(camData?.annual_turnover),
                                        0
                                      )}{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className=" mb-2 mt-2">
                        <div className="m-0 p-0">
                          <Button
                            type="button"
                            onClick={residenceClickHandler}
                            style={{ fontSize: '28px' }}
                          >
                            <span className="personal-sub-title ">
                              RESIDENCE INFORMATION
                            </span>
                            <MdOutlineExpandMore
                              className="personal-title"
                              style={{ fontSize: '28px' }}
                            />
                          </Button>
                        </div>
                        {residenceExpandFlag ? (
                          <div>
                            <div className="view-info-box mt-3">
                              <div className="row p-4">
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    Residence Status (Rented/Owned)
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    {camData?.living_house_owned_rented ===
                                      null ||
                                    camData?.living_house_owned_rented ===
                                      ' ' ? (
                                      ''
                                    ) : (
                                      <button
                                        disabled
                                        className={
                                          camData?.living_house_owned_rented ===
                                            'Owned house' ||
                                          camData?.living_house_owned_rented ===
                                            'Owned House'
                                            ? 'bg-success view-residance-btn btn '
                                            : '   bg-danger  view-residance-btn btn'
                                        }
                                        style={{ color: '#fff' }}
                                      >
                                        {camData?.living_house_owned_rented}
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    Aadhar Card Number
                                  </div>

                                  <div className="view-info-box-value mt-2">
                                    {' '}
                                    {/* {camData?.adhaar_number === null ||
                              camData?.adhaar_number === ""
                                ? "No"
                                : camData?.adhaar_number}{" "} */}
                                    {camData?.adhaar_number ? (
                                      <button
                                        disabled
                                        className={
                                          ' bg-success view-residance-btn btn p-1'
                                        }
                                        style={{
                                          fontSize: '14px',
                                          borderRadius: '5px',
                                          color: '#fff',
                                        }}
                                      >
                                        Validated
                                      </button>
                                    ) : (
                                      'No'
                                    )}
                                  </div>
                                </div>
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    PAN Card Number
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    {/* {camData?.pan_number === null ||
                              camData?.pan_number === ""
                                ? "No"
                                : camData?.pan_number}{" "} */}
                                    {camData?.pan_number ? (
                                      <button
                                        disabled
                                        className={
                                          ' bg-success view-residance-btn btn p-1'
                                        }
                                        style={{
                                          fontSize: '14px',
                                          borderRadius: '5px',
                                          color: '#fff',
                                        }}
                                      >
                                        Validated
                                      </button>
                                    ) : (
                                      'No'
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="view-info-box mt-3">
                              <div className="row p-4">
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    Residence Address
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    {camData?.residence_address === null
                                      ? ' '
                                      : camData?.residence_address}{' '}
                                  </div>
                                </div>
                                <div className="col col-md-3 col-lg-3 col-sm-3">
                                  <div className="view-info-box-key">
                                    Residence Stability (No. of years)
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    {camData?.residence_stability === null
                                      ? '0'
                                      : camData?.residence_stability}{' '}
                                    Years
                                  </div>
                                </div>
                                <div className="col col-md-2 col-lg-2 col-sm-2">
                                  <div className="view-info-box-key">
                                    Rent value
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    INR{' '}
                                    {camData?.rent_value_house === ''
                                      ? '0'
                                      : camData?.rent_value_house}{' '}
                                  </div>
                                </div>
                                <div className="col col-md-3 col-lg-3 col-sm-3">
                                  <div className="view-info-box-key">
                                    Tentative Market Value
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    INR{' '}
                                    {camData?.tentative_market_value_house ===
                                    ''
                                      ? '0'
                                      : amountFormat(
                                          camData?.tentative_market_value_house,
                                          '0'
                                        )}{' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {/*Personal Discussion - end*/}
              {/* Additional Personal Discussion-Start*/}
              <div className="mb-2">
                <span className="ms-2">
                  <img alt="" src={chatIcon} />
                </span>
                <Button type="button" onClick={AdditionalClickHandler}>
                  <span className="personal-title fw-bolder">
                    Additional Personal Discussion
                  </span>
                  <MdOutlineExpandMore
                    className="personal-title"
                    style={{ fontSize: '28px' }}
                  />
                </Button>
                <div className=" mt-0 pt-0">
                  {additionalExpandFlag ? (
                    <div className="mb-2 mt-4">
                      <div className="personal-sub-title mt-3">
                        FAMILY INFORMATION
                      </div>
                      <div className="view-info-box mt-3">
                        <div className="row p-4">
                          <div className="col col-md-2 col-lg-2 col-sm-2">
                            <div className="view-info-box-key">Family Type</div>
                            <div className="view-info-box-value mt-2">
                              {camData?.family_type === null
                                ? '--NA--'
                                : camData?.family_type}
                            </div>
                          </div>
                          <div className="col col-md-2 col-lg-2 col-sm-2">
                            <div className="view-info-box-key">
                              Count of Family Members
                            </div>
                            <div className="view-info-box-value mt-2">
                              {camData?.no_of_family_members === null
                                ? ''
                                : camData?.no_of_family_members}
                            </div>
                          </div>
                          <div className="col col-md-3 col-lg-3 col-sm-3">
                            <div className="view-info-box-key">
                              Count of Female Members in Family
                            </div>
                            <div className="view-info-box-value mt-2">
                              {camData?.female_count === null
                                ? '--NA--'
                                : camData?.female_count}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="view-info-box mt-3">
                        <div className="row p-4">
                          <div className="col col-md-3 col-lg-3 col-sm-3">
                            <div className="view-info-box-key">
                              Involved in any criminal case in past
                            </div>
                            <div className="view-info-box-value mt-2">
                              {camData?.involved_in_any_criminal_case === null
                                ? '--NA--'
                                : camData?.involved_in_any_criminal_case}
                            </div>
                          </div>
                          <div className="col col-md-3 col-lg-3 col-sm-3">
                            <div className="view-info-box-key">
                              Involved in any civil case in past
                            </div>
                            <div className="view-info-box-value mt-2">
                              {camData?.involved_in_any_civil_case === null
                                ? '--NA--'
                                : camData?.involved_in_any_civil_case}
                            </div>
                          </div>
                          <div className="col col-md-3 col-lg-3 col-sm-3">
                            <div className="view-info-box-key">
                              Anyone working or residing aborad?
                            </div>
                            <div className="view-info-box-value mt-2">
                              {' '}
                              {camData?.abroad_family_members === null
                                ? '--NA--'
                                : camData?.abroad_family_members}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className=" mb-2 mt-2">
                        <div className="m-0 p-0">
                          <Button
                            type="button"
                            onClick={officeInfoClickHandler}
                            style={{ fontSize: '28px' }}
                          >
                            <span className="personal-sub-title ">
                              OFFICE INFORMATION
                            </span>
                            <MdOutlineExpandMore
                              className="personal-title"
                              style={{ fontSize: '28px' }}
                            />
                          </Button>
                        </div>
                        {officeInfoFlag ? (
                          <div>
                            <div className="view-info-box mt-3">
                              <div className="row p-4">
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    Are you working in Rented or Owned office ?
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    {camData?.living_office_owned_rented ===
                                      null ||
                                    camData?.living_office_owned_rented ===
                                      '' ? (
                                      '--NA--'
                                    ) : (
                                      <button
                                        disabled
                                        className={
                                          camData?.living_office_owned_rented ===
                                            'Rented Office' ||
                                          camData?.living_office_owned_rented ===
                                            'Rented office'
                                            ? 'bg-danger view-residance-btn btn'
                                            : '   bg-success view-residance-btn btn'
                                        }
                                        style={{ color: '#FFF' }}
                                      >
                                        {' '}
                                        {camData?.living_office_owned_rented}
                                      </button>
                                    )}
                                  </div>
                                </div>
                                {camData?.living_office_owned_rented ===
                                'Rented office' ? (
                                  ''
                                ) : (
                                  <>
                                    {' '}
                                    <div className="col col-md-4 col-lg-4 col-sm-4">
                                      <div className="view-info-box-key">
                                        Is your office Ancestral or Self
                                        Constructed ?
                                      </div>
                                      <div className="view-info-box-value mt-2">
                                        {camData?.is_your_ofc_ancestral_self ===
                                        null
                                          ? '--NA--'
                                          : camData?.is_your_ofc_ancestral_self}
                                      </div>
                                    </div>
                                    <div className="col col-md-4 col-lg-4 col-sm-4">
                                      <div className="view-info-box-key">
                                        Life of construction
                                      </div>
                                      <div className="view-info-box-value mt-2">
                                        {camData?.life_of_construction_office ===
                                        null
                                          ? '0'
                                          : camData?.life_of_construction_office}{' '}
                                        Years
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="view-info-box mt-3">
                              <div className="row p-4">
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    Size of land or office
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    {camData?.office_size_in_sq_feet === null
                                      ? '0'
                                      : camData?.office_size_in_sq_feet}{' '}
                                    sq.ft.
                                  </div>
                                </div>
                                {camData?.living_office_owned_rented ===
                                'Rented office' ? (
                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                    <div className="view-info-box-key">
                                      Office Rent
                                    </div>
                                    <div className="view-info-box-value mt-2">
                                      INR{' '}
                                      {camData?.rent_value_office === null ||
                                      camData?.rent_value_office === ''
                                        ? '0'
                                        : amountFormat(
                                            camData?.rent_value_office,
                                            '0'
                                          )}{' '}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                    <div className="view-info-box-key">
                                      Tentative market value
                                    </div>
                                    <div className="view-info-box-value mt-2">
                                      INR{' '}
                                      {camData?.tentative_market_value_office ===
                                        null ||
                                      camData?.tentative_market_value_office ===
                                        ''
                                        ? '0'
                                        : amountFormat(
                                            camData?.tentative_market_value_office,
                                            '0'
                                          )}{' '}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div> */}

                      <div className=" mb-2 mt-2">
                        <div className="m-0 p-0">
                          <Button
                            type="button"
                            onClick={vehiclesClickHandler}
                            style={{ fontSize: '28px' }}
                          >
                            <span className="personal-sub-title ">
                              VEHICLES DETAILS
                            </span>
                            <MdOutlineExpandMore
                              className="personal-title"
                              style={{ fontSize: '28px' }}
                            />
                          </Button>
                        </div>
                        {VehiclesFlag ? (
                          <div>
                            <div className="view-info-box mt-3">
                              <div className="row p-4">
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    Number of vehicles
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    {camData?.no_of_vehicals === null
                                      ? '--NA--'
                                      : camData?.no_of_vehicals}
                                  </div>
                                </div>
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    Name of vehicles
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    {/* {camData?.no_of_vehicals === null
                                              ? "--NA--"
                                              : camData?.no_of_vehicals}
                                            NEXON EV, Platina */}
                                    {camData?.make_model?.map((item, index) => (
                                      <span>
                                        {item}{' '}
                                        {camData?.make_model?.length > 1 &&
                                        index < camData?.make_model?.length - 1
                                          ? ','
                                          : ''}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                                <div className="col col-md-4 col-lg-4 col-sm-4">
                                  <div className="view-info-box-key">
                                    Tentative market value
                                  </div>
                                  <div className="view-info-box-value mt-2">
                                    INR{' '}
                                    {camData?.tentative_market_value_vehicle ===
                                    null
                                      ? '0'
                                      : amountFormat(
                                          camData?.tentative_market_value_vehicle,
                                          '0'
                                        )}{' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="m-0 p-0">
                        <Button
                          type="button"
                          onClick={businessDetailsClickHandler}
                          style={{ fontSize: '28px' }}
                        >
                          <span className="personal-sub-title ">
                            BUSINESS DETAILS
                          </span>
                          <MdOutlineExpandMore
                            className="personal-title"
                            style={{ fontSize: '28px' }}
                          />
                        </Button>
                      </div>
                      {businessDetailsExpandFlag ? (
                        <div>
                          <div className="view-info-box mt-3">
                            <div className="row p-4">
                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key">
                                  is your business ancestral or self Started?
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.is_your_business === null
                                    ? ''
                                    : camData?.is_your_business}{' '}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Life of Business
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.life_of_business === null
                                    ? '0'
                                    : camData?.life_of_business}{' '}
                                  years
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Experiance in Business
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.experience_in_business === null
                                    ? '0'
                                    : camData?.experience_in_business}{' '}
                                  years
                                </div>
                              </div>
                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key">
                                  Any other source of income & income value?
                                </div>
                                <div className="view-info-box-value mt-2">
                                  INR{' '}
                                  {camData?.any_other_income === null
                                    ? '0'
                                    : amountFormat(
                                        camData?.any_other_income,
                                        '0'
                                      )}{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      {/* <div className="m-0 p-0 mt-2 mb-2">
                        <Button
                          type="button"
                          onClick={surroundedClickHandler}
                          style={{ fontSize: '28px' }}
                        >
                          <span className="personal-sub-title ">
                            SURROUNDING
                          </span>
                          <MdOutlineExpandMore
                            className="personal-title"
                            style={{ fontSize: '28px' }}
                          />
                        </Button>
                      </div>
                      {SurroundingExpandFlag ? (
                        <div>
                          <div className="view-info-box mt-3">
                            <div className="row p-4">
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">Nature</div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.is_your_nature === null
                                    ? '--NA--'
                                    : camData?.is_your_nature}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Staff’s Nature
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.is_your_staff_nature === null
                                    ? '--NA--'
                                    : camData?.is_your_staff_nature}
                                </div>
                              </div>
                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key">
                                  Involve in some sort of charity?
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {' '}
                                  {camData?.involve_in_charity === null
                                    ? '--NA--'
                                    : camData?.involve_in_charity[0]}
                                </div>
                              </div>
                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key">
                                  Staff skill enchancing programs
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.staff_skill_enchance_prog === null
                                    ? '--NA--'
                                    : camData?.staff_skill_enchance_prog[0]}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="view-info-box mt-3">
                            <div className="row p-4">
                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key">
                                  How is your neighborhood?
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.neighbourhood === null
                                    ? '--NA--'
                                    : camData?.neighbourhood}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key"></div>
                                <div className="view-info-box-value mt-2"></div>
                              </div>
                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key"></div>
                                <div className="view-info-box-value mt-2"></div>
                              </div>
                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key"></div>
                                <div className="view-info-box-value mt-2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )} */}

                      <div className="m-0 p-0 mt-2 mb-2">
                        <Button
                          type="button"
                          onClick={financialClickHandler}
                          style={{ fontSize: '28px' }}
                        >
                          <span className="personal-sub-title ">FINANCIAL</span>
                          <MdOutlineExpandMore
                            className="personal-title"
                            style={{ fontSize: '28px' }}
                          />
                        </Button>
                      </div>
                      {FinancialExpandFlag ? (
                        <div>
                          <div className="view-info-box mt-3">
                            <div className="row p-4">
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Loans(in INR)
                                </div>
                                <div className="view-info-box-value mt-2">
                                  INR{' '}
                                  {camData?.total_amount_of_loan === null
                                    ? '0'
                                    : amountFormat(
                                        camData?.total_amount_of_loan,
                                        '0'
                                      )}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Purpose of Credit
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.purpose_of_loan === null
                                    ? '--NA--'
                                    : camData?.purpose_of_loan}
                                </div>
                              </div>
                              <div className="col col-md-4 col-lg-4 col-sm-4">
                                <div className="view-info-box-key">
                                  Obligation per month (in INR)
                                </div>
                                <div className="view-info-box-value mt-2">
                                  INR{' '}
                                  {camData?.emi_obligation_per_month === null
                                    ? '0'
                                    : amountFormat(
                                        camData?.emi_obligation_per_month,
                                        '0'
                                      )}
                                </div>
                              </div>
                              <div className="col col-md-2 col-lg-2 col-sm-2">
                                <div className="view-info-box-key">
                                  Total Income
                                </div>
                                <div className="view-info-box-value mt-2">
                                  INR{' '}
                                  {camData?.total_income === null
                                    ? '0'
                                    : amountFormat(camData?.total_income, '0')}
                                </div>
                              </div>
                              <div className="col col-md-1 col-lg-1 col-sm-1 ">
                                <div className="view-finance-box text-center justify-content-center p-0 m-0">
                                  <div className="mt-2">
                                    <div className="view-finance-box-text">
                                      FOIR{' '}
                                    </div>
                                    <div className="view-finance-box-text">
                                      {' '}
                                      {camData?.foir === null
                                        ? '0'
                                        : camData?.foir}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      {/* REF DETAILS */}
                      <div className="m-0 p-0">
                        <Button type="button" onClick={refDetailsClickHandler}>
                          <span className="personal-sub-title ">
                            Reference Details
                          </span>
                          <MdOutlineExpandMore
                            className="personal-title"
                            style={{ fontSize: '28px' }}
                          />
                        </Button>
                      </div>
                      {RefExpandFlag ? (
                        <div>
                          <div className="view-info-box mt-3">
                            <div className="row p-4">
                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  First Reference Name
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.Reference_Details === null
                                    ? '-'
                                    : camData?.Reference_Details[0]?.name1}
                                </div>
                              </div>
                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  First Reference Contact Number
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.Reference_Details === null
                                    ? '-'
                                    : camData?.Reference_Details[0]
                                        ?.contact_no1}
                                </div>
                              </div>
                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  Second Reference Name
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.Reference_Details === null ? (
                                    '-'
                                  ) : (
                                    <>
                                      {!camData?.Reference_Details[1]
                                        ? '-'
                                        : camData?.Reference_Details[1]?.name2}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col col-md-3 col-lg-3 col-sm-3">
                                <div className="view-info-box-key">
                                  Second Reference Contact Number
                                </div>
                                <div className="view-info-box-value mt-2">
                                  {camData?.Reference_Details === null ? (
                                    '-'
                                  ) : (
                                    <>
                                      {!camData?.Reference_Details[1]
                                        ? '-'
                                        : camData?.Reference_Details[1]
                                            ?.contact_no2}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {/* Additional Personal Discussion-End*/}
              {/* Documents-start */}
              <div className="mb-2 mt-2">
                <div className="view-document-title">
                  <div className="row ps-5 pe-5 ">
                    <div className="col col-8 col-md-8 col-lg-8 col-sm-8 mb-2">
                      <div
                        style={{ float: 'left' }}
                        className="view-document-title-text mt-2 p-1"
                      >
                        <div>Document</div>
                      </div>
                    </div>
                    {/* <div className="col col-4 col-md-4 col-lg-4 col-sm-4 ">
                <div style={{ float: "right" }}>
                  <Button
                    variant="contained"
                    className="view-document-title-btn m-2"
                    style={{
                      backgroundColor: "#e3e4e4",
                      color: "#000",
                    }}
                    onClick={handleAllDOCDownload}
                  >
                    Download All
                  </Button>
                </div>
              </div> */}
                  </div>
                </div>
                {/* KYC Doc */}
                <div className="m-2 mt-3">
                  <div>
                    <div className="m-0 p-0">
                      <Button
                        type="button"
                        onClick={kycDocClickHandler}
                        style={{ fontSize: '28px' }}
                      >
                        <span className="personal-sub-title ">
                          KYC DOCUMENTS
                        </span>
                        <MdOutlineExpandMore
                          className="personal-title"
                          style={{ fontSize: '28px' }}
                        />
                      </Button>
                    </div>
                    {kycDocumentFlag ? (
                      <div className="mt-3 mb-2">
                        <div className="row  mt-1">
                          <div className="col col-3 col-md-3 col-sm-12 col-lg-3">
                            <div className="view-kyc-doc-box p-3 ">
                              <div className="d-flex justify-content-center align-item-center">
                                <div className=" ">
                                  {camData?.identity_proof === null ? (
                                    <>
                                      <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                        <img
                                          alt=""
                                          src={fileIcon}
                                          // width={'100%'}
                                        />
                                      </div>

                                      <div className="doc-Not-Uploaded text-center mb-4">
                                        NOT UPLOADED
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {camData?.identity_proof[0]?.url ===
                                      '' ? (
                                        <>
                                          <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                            <img
                                              alt=""
                                              src={fileIcon}
                                              // width={'90%'}
                                            />
                                          </div>

                                          <div className="doc-Not-Uploaded text-center mb-4">
                                            NOT UPLOADED
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            style={{ float: 'right' }}
                                            className="pb-2"
                                          >
                                            <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                              <img
                                                alt=""
                                                src={fileIcon}
                                                // width={'100%'}
                                              />
                                            </div>

                                            <div
                                              className="doc-Not-Uploaded text-center mb-4"
                                              style={{
                                                backgroundColor: 'Green',
                                              }}
                                            >
                                              UPLOADED
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                  <div className="view-kyc-doc-title mt-2">
                                    (License / ID Card of Office )
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col col-3 col-md-3 col-sm-12 col-lg-3">
                            <div className="view-kyc-doc-box p-3">
                              <div className="d-flex justify-content-center align-item-center">
                                <div className="">
                                  {camData?.ownership_proof === null ||
                                  camData?.ownership_proof[0]?.url === '' ? (
                                    <>
                                      <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                        <img
                                          alt=""
                                          src={fileIcon}
                                          // width={'100%'}
                                        />
                                      </div>

                                      <div className="doc-Not-Uploaded text-center mb-4">
                                        NOT UPLOADED
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{ float: 'right' }}
                                        className="pb-2"
                                      >
                                        <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                          <img
                                            alt=""
                                            src={fileIcon}
                                            // width={'100%'}
                                          />
                                        </div>

                                        <div
                                          className="doc-Not-Uploaded text-center mb-4"
                                          style={{
                                            backgroundColor: 'Green',
                                          }}
                                        >
                                          UPLOADED
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="view-kyc-doc-title mt-2">
                                    Ownership Proof
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                {/* Bank Statement -Start*/}
                <div className="m-2">
                  <div>
                    <div className="m-0 p-0">
                      <Button
                        type="button"
                        onClick={banckStmtClickHandler}
                        style={{ border: 'none' }}
                      >
                        <span className="personal-sub-title ">
                          BANK STATEMENTS
                        </span>
                        <MdOutlineExpandMore
                          className="personal-title"
                          style={{ fontSize: '28px' }}
                        />
                      </Button>
                    </div>
                    {banckStmtFlag ? (
                      <>
                        <div className="mt-3 ">
                          <div className="row">
                            {camData?.bank_statement_last_12_month_url ===
                            null ? (
                              <div className="col col-4 col-md-4 col-sm-12 col-lg-4 mt-2">
                                <div className="view-kyc-doc-box p-3 ">
                                  <div className="d-flex justify-content-center align-item-center">
                                    <div className="text-center">
                                      <div className=" flex justify-content-center align-item-center mb-4 mt-3">
                                        <img
                                          alt=""
                                          src={fileIcon}
                                          // width={'80%'}
                                        />
                                      </div>

                                      <div className="doc-Not-Uploaded text-center mb-4 ms-4">
                                        NOT UPLOADED
                                      </div>
                                      <div className="view-kyc-doc-title mt-2">
                                        Financial Document
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              camData?.bank_statement_last_12_month_url?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      {console.log(item)}
                                      <div className="col col-md-6 col-lg-6 col-sm-6 mt-4">
                                        <div className="financial-doc-box-1 p-3">
                                          {/* <div style={{ float: "right" }}>
                                      <Button
                                        onClick={() =>
                                          downloadPDF(
                                            `${item?.url}`,
                                            `${item?.bank_name}.pdf`
                                          )
                                        }
                                      >
                                        <DownloadIcon className="dounload-icon-color" />
                                      </Button>
                                    </div> */}
                                          <div className="BANK1 fw-bolder p-2 text-center">
                                            BANK#{index + 1}
                                          </div>

                                          <div className="d-felx justify-content-center ">
                                            <div>
                                              <div className="">
                                                {item?.url === '' ||
                                                item?.url === null ? (
                                                  <>
                                                    <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                                      <img
                                                        alt=""
                                                        src={fileIcon}
                                                        // width={'60%'}
                                                      />
                                                    </div>

                                                    <div className="doc-Not-Uploaded text-center mb-4 ms-5">
                                                      NOT UPLOADED
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="p-2">
                                                      {isPDForJPEG(
                                                        item?.url
                                                      ) ? (
                                                        <>
                                                          <div
                                                            style={{
                                                              maxHeight:
                                                                '400px',
                                                              overflowY:
                                                                'scroll',
                                                              justifyContent:
                                                                'center',
                                                            }}
                                                          >
                                                            {console.log(
                                                              item?.url
                                                            )}
                                                            <div className="  ">
                                                              <Document
                                                                // width={900}
                                                                file={item?.url}
                                                                onLoadSuccess={
                                                                  onDocumentLoadSuccess
                                                                }
                                                                onLoadError={(
                                                                  error
                                                                ) =>
                                                                  console.error(
                                                                    'Error while loading document!',
                                                                    error
                                                                  )
                                                                }
                                                              >
                                                                <Page
                                                                  pageNumber={
                                                                    pageNumber
                                                                  }
                                                                />
                                                              </Document>
                                                            </div>
                                                            <div className="flex justify-between mt-5 pb-3">
                                                              <p className="">
                                                                Page{' '}
                                                                {pageNumber ||
                                                                  (numPages
                                                                    ? 1
                                                                    : '--')}{' '}
                                                                of{' '}
                                                                {numPages ||
                                                                  '--'}
                                                              </p>
                                                              <div className="flex gap-2">
                                                                <Button
                                                                  size="small"
                                                                  variant="contained"
                                                                  disabled={
                                                                    pageNumber <=
                                                                    1
                                                                  }
                                                                  onClick={
                                                                    previousPage
                                                                  }
                                                                  sx={{
                                                                    backgroundColor:
                                                                      '#040D43',
                                                                  }}
                                                                >
                                                                  Previous
                                                                </Button>
                                                                <Button
                                                                  size="small"
                                                                  className="ml-2"
                                                                  variant="contained"
                                                                  disabled={
                                                                    pageNumber >=
                                                                    numPages
                                                                  }
                                                                  onClick={
                                                                    nextPage
                                                                  }
                                                                  sx={{
                                                                    backgroundColor:
                                                                      '#040D43',
                                                                  }}
                                                                >
                                                                  Next
                                                                </Button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <img
                                                          className="img-fluid"
                                                          src={item?.url}
                                                          alt="File"
                                                          width={'100%'}
                                                        />
                                                      )}
                                                    </div>
                                                  </>
                                                )}

                                                <div className="view-kyc-doc-title mt-2 mb-2">
                                                  <div className="d-flex justify-content-center gap-5">
                                                    <div className="mt-3 text-center fw-bolder">
                                                      {item?.bank_name}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div className="text-center">
                                              <div className="fw-bolder p-1">
                                                {" "}
                                                Bank Statement Date
                                              </div>
                                              <span className="Date-range-of-Bank-Statement p-1">
                                             
                                                from :{" "}
                                                { item?.from !== "" ||item?.from !==null ?moment(item?.from).format(
                                                  "ll"
                                                ):""
                                              
                                            }
                                                {}{" "}
                                              </span>
                                              <div className="Date-range-of-Bank-Statement p-1">
                                                to :{" "}{
                                                  item?.to !== "" || item?.to !== null ?moment(item?.to).format("ll"):""
                                                }
                                                {}{" "}
                                              </div>
                                            </div> */}
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )
                            )}
                            {camData?.bank_statement_details_OCR?.length ===
                            0 ? (
                              <>
                                <div className="col col-4 col-md-4 col-sm-12 col-lg-4 mt-2">
                                  <div className="view-kyc-doc-box p-3 ">
                                    <div className="d-flex justify-content-center align-item-center">
                                      <div className="text-center">
                                        <div className=" flex justify-content-center align-item-center mb-4 mt-3">
                                          <img
                                            alt=""
                                            src={fileIcon}
                                            // width={'80%'}
                                          />
                                        </div>

                                        <div className="doc-Not-Uploaded text-center mb-4 ms-4">
                                          NOT UPLOADED
                                        </div>
                                        <div className="view-kyc-doc-title mt-2">
                                          Financial Document
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                {/* Bank Statement-End */}
                {/* Financial Doc */}
                <div className="m-2">
                  <div>
                    <div className="m-0 p-0">
                      <Button
                        type="button"
                        onClick={financialDocClickHandler}
                        style={{ border: 'none' }}
                      >
                        <span className="personal-sub-title ">
                          FINANCIAL DOCUMENTS
                        </span>
                        <MdOutlineExpandMore
                          className="personal-title"
                          style={{ fontSize: '28px' }}
                        />
                      </Button>
                    </div>
                    {financialDocFlag ? (
                      <div className="mt-3">
                        <div className="row">
                          {camData?.bank_statement_details_OCR?.length === 0 ? (
                            <>
                              <div className="col col-4 col-md-4 col-sm-12 col-lg-4 mt-2">
                                <div className="view-kyc-doc-box p-3 ">
                                  <div className="d-flex justify-content-center align-item-center">
                                    <div className="flex justify-content-center align-item-center">
                                      <div className="mb-4 mt-3">
                                        <img
                                          alt=""
                                          src={fileIcon}
                                          // width={'80%'}
                                        />
                                      </div>

                                      <div className="doc-Not-Uploaded text-center mb-4 ms-4">
                                        NOT UPLOADED
                                      </div>
                                      <div className="view-kyc-doc-title mt-2">
                                        Financial Document
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                          {camData?.bank_statement_details_OCR?.map(
                            (item, index) => {
                              return (
                                <>
                                  {item?.status === 'completed' ? (
                                    <>
                                      <div
                                        className="view-kyc-doc-box pe-5 ps-5 pt-3 pb-3 mt-3"
                                        style={{
                                          border: '2px solid #f1f4f9',
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col col-md-4 col-lg-4 col-sm-4 ">
                                            <div className="BANK1 fw-bolder p-2 text-center">
                                              BANK#{index + 1}
                                            </div>
                                            <div className="financial-doc-box-1 p-3">
                                              <div className="d-felx justify-content-center ">
                                                <div>
                                                  <div className="">
                                                    {item?.url === '' ||
                                                    item?.url === null ? (
                                                      <>
                                                        <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                                          <img
                                                            alt=""
                                                            src={fileIcon}
                                                            // width={'60%'}
                                                          />
                                                        </div>

                                                        <div className="doc-Not-Uploaded text-center mb-4 ms-5">
                                                          NOT UPLOADED
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div className="p-2">
                                                          {isPDForJPEG(
                                                            item?.url
                                                          ) ? (
                                                            <>
                                                              <div
                                                                style={{
                                                                  maxHeight:
                                                                    '400px',

                                                                  overflowY:
                                                                    'scroll',
                                                                  justifyContent:
                                                                    'center',
                                                                }}
                                                              >
                                                                <div className="  ">
                                                                  <Document
                                                                    // width={900}
                                                                    file={
                                                                      item?.url
                                                                    }
                                                                    onLoadSuccess={
                                                                      onDocumentLoadSuccess
                                                                    }
                                                                    onLoadError={(
                                                                      error
                                                                    ) =>
                                                                      console.error(
                                                                        'Error while loading document!',
                                                                        error
                                                                      )
                                                                    }
                                                                  >
                                                                    <Page
                                                                      pageNumber={
                                                                        pageNumber
                                                                      }
                                                                    />
                                                                  </Document>
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <img
                                                              className="img-fluid"
                                                              src={item?.url}
                                                              alt="File"
                                                              width={'100%'}
                                                            />
                                                          )}
                                                        </div>
                                                      </>
                                                    )}

                                                    <div className="view-kyc-doc-title mt-2 mb-2">
                                                      <div className="d-flex justify-content-center gap-5">
                                                        <div className="mt-3 text-center fw-bolder">
                                                          {item?.bank_name}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              <div className="fw-bolder p-1">
                                                {' '}
                                                Bank Statement Date
                                              </div>
                                              <span className="Date-range-of-Bank-Statement p-1">
                                                from :{' '}
                                                {moment(
                                                  item?.result
                                                    ?.bank_account_details
                                                    ?.starting_date
                                                ).format('ll')}{' '}
                                              </span>
                                              <div className="Date-range-of-Bank-Statement p-1">
                                                to :{' '}
                                                {moment(
                                                  item?.result
                                                    ?.bank_account_details
                                                    ?.ending_date
                                                ).format('ll')}{' '}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col col-md-8 col-lg-8 col-sm-8">
                                            <div>
                                              <div className="d-flex  gap-3">
                                                {/* <div>
                                          <Button
                                            variant="contained"
                                            style={{
                                              backgroundColor: "#4d5a88",
                                            }}
                                            onClick={() =>
                                              downloadPDF(
                                                `${item?.url}`,
                                                `${item?.bank_name}.pdf`
                                              )
                                            }
                                          >
                                            <DownloadIcon /> Download Bank
                                            Statement
                                          </Button>
                                        </div>
                                        <div>
                                          <Button
                                            variant="contained"
                                            style={{
                                              backgroundColor: "#4d5a88",
                                            }}
                                            onClick={() =>
                                              getFileToDownload(`${item?.url}`)
                                            }
                                          >
                                            <DownloadIcon /> Download Analysis
                                            in Excel
                                          </Button>
                                        </div> */}
                                                <div>
                                                  <Button
                                                    variant="contained"
                                                    style={{
                                                      backgroundColor:
                                                        '#4d5a88',
                                                    }}
                                                    // onClick={()=>ClickViewAnalysis(item)}
                                                    // // onClick={handleViewAnalysis(item)}
                                                    onClick={() =>
                                                      ClickViewAnalysis(item)
                                                    }
                                                  >
                                                    View
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                            <br />
                                            <div className="row mt-1">
                                              <div
                                                className="view-info-box "
                                                style={{
                                                  border: '2px solid #4d5a88',
                                                }}
                                              >
                                                <div className="row p-4">
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      Account Name
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.account_name === null
                                                        ? '--NA--'
                                                        : item?.result
                                                            ?.bank_account_details
                                                            ?.account_name}
                                                    </div>
                                                  </div>
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      Bank Name
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.bank_name === null
                                                        ? '--NA--'
                                                        : item?.result
                                                            ?.bank_account_details
                                                            ?.bank_name}
                                                    </div>
                                                  </div>
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      IFSC Code
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.ifsc === null
                                                        ? '--NA--'
                                                        : item?.result
                                                            ?.bank_account_details
                                                            ?.ifsc}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-2">
                                              <div
                                                className="view-info-box "
                                                style={{
                                                  border: '2px solid #4d5a88',
                                                }}
                                              >
                                                <div className="row p-4">
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      Account Number
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.account_number ===
                                                      null
                                                        ? '--NA--'
                                                        : item?.result
                                                            ?.bank_account_details
                                                            ?.account_number}
                                                    </div>
                                                  </div>
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      Current Balance
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      ₹{' '}
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.current_balance ===
                                                      null
                                                        ? '--NA--'
                                                        : amountFormat(
                                                            item?.result
                                                              ?.bank_account_details
                                                              ?.current_balance,
                                                            '--NA--'
                                                          )}
                                                    </div>
                                                  </div>
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      Joint Holder Name
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {
                                                        item?.result
                                                          ?.bank_account_details
                                                          ?.joint_holder_name[0]
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-2">
                                              <div
                                                className="view-info-box "
                                                style={{
                                                  border: '2px solid #4d5a88',
                                                }}
                                              >
                                                <div className="row p-4">
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      Account Type
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {
                                                        item?.result
                                                          ?.bank_account_details
                                                          ?.account_type
                                                      }
                                                    </div>
                                                  </div>
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      Email
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.email === null
                                                        ? '--NA--'
                                                        : item?.result
                                                            ?.bank_account_details
                                                            ?.email}
                                                    </div>
                                                  </div>
                                                  <div className="col col-md-4 col-lg-4 col-sm-4">
                                                    <div className="view-info-box-key">
                                                      Micr Code
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.micr_code === null
                                                        ? '--NA--'
                                                        : item?.result
                                                            ?.bank_account_details
                                                            ?.micr_code}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row mt-2">
                                              <div
                                                className="view-info-box "
                                                style={{
                                                  border: '2px solid #4d5a88',
                                                }}
                                              >
                                                <div className="row p-4">
                                                  <div className="col col-md-6 col-lg-6 col-sm-6">
                                                    <div className="view-info-box-key">
                                                      Address
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.address === null
                                                        ? '--NA--'
                                                        : item?.result
                                                            ?.bank_account_details
                                                            ?.address}
                                                    </div>
                                                  </div>
                                                  <div className="col col-md-3 col-lg-3 col-sm-3">
                                                    <div className="view-info-box-key">
                                                      Mobile
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.mobile === null
                                                        ? '--NA--'
                                                        : item?.result
                                                            ?.bank_account_details
                                                            ?.mobile}
                                                    </div>
                                                  </div>
                                                  <div className="col col-md-3 col-lg-3 col-sm-3">
                                                    <div className="view-info-box-key">
                                                      Monthly Average Balance
                                                    </div>
                                                    <div className="view-info-box-value mt-2">
                                                      {item?.result
                                                        ?.bank_account_details
                                                        ?.monthly_average_balance ===
                                                      null
                                                        ? '--NA--'
                                                        : amountFormat(
                                                            item?.result
                                                              ?.bank_account_details
                                                              ?.monthly_average_balance,
                                                            '--NA--'
                                                          )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )}

                                  {/* <div className="col col-8 col-md-8 col-sm-8 col-lg-4">
                                            <div className="row">
                                              <div className="col col-md-12 col-lg-12 col-sm-12">
                                                <div className="banck-statement-analysis ">
                                                  <div className="d-flex justify-content-end gap-5">
                                                    <div>
                                                      <Button
                                                        variant="contained"
                                                        style={{
                                                          backgroundColor: "#4d5a88",
                                                          zIndex: "-1",
                                                        }}
                                                      >
                                                        <exporticon /> Export this CAM
                                                      </Button>
                                                    </div>
                                                    <div>
                                                      <Button
                                                        variant="contained"
                                                        style={{
                                                          backgroundColor: "#4d5a88",
                                                          zIndex: "-1",
                                                        }}
                                                      >
                                                        <exporticon /> Export this CAM
                                                      </Button>
                                                    </div>
                                                    <div>
                                                      <Button
                                                        variant="contained"
                                                        style={{
                                                          backgroundColor: "#4d5a88",
                                                          zIndex: "-1",
                                                        }}
                                                      >
                                                        <exporticon /> Export this CAM
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>

              {/* Business Doc */}
              <div className=" m-2">
                <div>
                  <div className="m-0 p-0">
                    <Button
                      type="button"
                      onClick={businessDocClickHandler}
                      style={{ border: 'none' }}
                    >
                      <span className="personal-sub-title ">
                        BUSINESS DOCUMENTS
                      </span>
                      <MdOutlineExpandMore
                        className="personal-title"
                        style={{ fontSize: '28px' }}
                      />
                    </Button>
                  </div>
                </div>
                {businessDocExpandFlag ? (
                  <div className="mt-3 mb-2">
                    <div className="row ">
                      {camData?.audited_financial_last_2_years_url === null ? (
                        <>
                          {/* <div className="col col-4 col-md-4 col-lg-4 mt-2">
                                    <div className="view-kyc-doc-box p-3 text-center justify-content-center ">
                                    <div className="text-center mb-4 mt-3">
                                                <img
                                                  alt=""
                                                  src={fileIcon}
                                                  // width={"100%"}
                                                />
                                              </div>
            
                                              <div className="doc-Not-Uploaded text-center mb-4">
                                                NOT UPLOADED
                                              </div>
                                      <div className="view-kyc-doc-title mt-2">
                                        Audited financial of last year 2
                                      </div>
                                    </div>
                                  </div> */}
                          <div className="col col-4 col-md-4 col-sm-12 col-lg-4 mt-2">
                            <div className="view-kyc-doc-box p-3 ">
                              <div className="d-flex justify-content-center align-item-center">
                                <div className="text-center">
                                  <div className=" flex justify-content-center align-item-center mb-4 mt-3">
                                    <img
                                      alt=""
                                      src={fileIcon}
                                      // width={'80%'}
                                    />
                                  </div>

                                  <div className="doc-Not-Uploaded text-center mb-4 ms-4">
                                    NOT UPLOADED
                                  </div>
                                  <div className="view-kyc-doc-title mt-2">
                                    Audited financial of last year 2
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                      {camData?.audited_financial_last_2_years_url?.map(
                        (item, index) => {
                          return (
                            <div className="col col-4 col-md-4 col-sm-12 col-lg-4 mt-2">
                              <div className="view-kyc-doc-box p-3 ">
                                <div className="d-flex justify-content-center align-item-center">
                                  <div className=" ">
                                    {item.url === '' || item.url === null ? (
                                      <>
                                        <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                          <img
                                            alt=""
                                            src={fileIcon}
                                            // width={'100%'}
                                          />
                                        </div>

                                        <div className="doc-Not-Uploaded text-center mb-4">
                                          NOT UPLOADED
                                        </div>
                                        <div className="view-kyc-doc-title mt-2">
                                          Audited financial of last year 1
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {/* <div
                                    style={{ float: "right" }}
                                    className="pb-2 pe-0"
                                  >
                                    {" "}
                                    <Button
                                      onClick={() =>
                                        downloadPDF(
                                          `${item.url}`,
                                          "Audited financial of last year 2.pdf"
                                        )
                                      }
                                    >
                                      <DownloadIcon
                                        className="dounload-icon-color"
                                        style={{ fontSize: "30px" }}
                                      />
                                    </Button>
                                  </div> */}
                                        <div className="p-2">
                                          {isPDForJPEG(item.url) ? (
                                            <>
                                              <div
                                                style={{
                                                  maxHeight: '200px',
                                                  maxWidth: '300px',
                                                  overflowY: 'scroll',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                {console.log(item?.url)}
                                                <div className="  ">
                                                  <Document
                                                    width={900}
                                                    file={item?.url}
                                                    onLoadSuccess={
                                                      onDocumentLoadSuccess
                                                    }
                                                    onLoadError={(error) =>
                                                      console.error(
                                                        'Error while loading document!',
                                                        error
                                                      )
                                                    }
                                                  >
                                                    <Page
                                                      pageNumber={pageNumber}
                                                    />
                                                  </Document>
                                                </div>
                                                <div className="flex justify-between mt-5 pb-3">
                                                  <p className="">
                                                    Page{' '}
                                                    {pageNumber ||
                                                      (numPages
                                                        ? 1
                                                        : '--')}{' '}
                                                    of {numPages || '--'}
                                                  </p>
                                                  <div className="flex gap-2">
                                                    <Button
                                                      size="small"
                                                      variant="contained"
                                                      disabled={pageNumber <= 1}
                                                      onClick={previousPage}
                                                      sx={{
                                                        backgroundColor:
                                                          '#040D43',
                                                      }}
                                                    >
                                                      Previous
                                                    </Button>
                                                    <Button
                                                      size="small"
                                                      className="ml-2"
                                                      variant="contained"
                                                      disabled={
                                                        pageNumber >= numPages
                                                      }
                                                      onClick={nextPage}
                                                      sx={{
                                                        backgroundColor:
                                                          '#040D43',
                                                      }}
                                                    >
                                                      Next
                                                    </Button>
                                                  </div>
                                                </div>

                                                {/* <PDFViewer
                                                      document={{
                                                        url: `${item.url}`,
                                                      }}
                                                    /> */}
                                                {/* <Document
                                               
                                                  file={item?.url}
                                                  onLoadSuccess={onLoadSuccess}
                                                >
                                                  {numPages && (
                                                    <>
                                                      {showFullPdf ? (
                                                        Array.from(
                                                          new Array(numPages),
                                                          (_, i) => (
                                                            <Page
                                                              key={`page_${
                                                                i + 1
                                                              }`}
                                                              pageNumber={i + 1}
                                                            />
                                                          )
                                                        )
                                                      ) : (
                                                        <div
                                                          onClick={() =>
                                                            setShowFullPdf(true)
                                                          }
                                                        >
                                                          <Page
                                                            pageNumber={1}
                                                          />
                                                        </div>
                                                      )}
                                                    </>
                                                  )}
                                                </Document> */}
                                              </div>
                                            </>
                                          ) : (
                                            <img
                                              className="img-fluid"
                                              src={item?.url}
                                              alt="File"
                                              width={'100%'}
                                              // height={"40%"}
                                              // width="100%"
                                              // height={"200px"}
                                            />
                                          )}
                                        </div>
                                      </>
                                    )}
                                    <div className="view-kyc-doc-title mt-2">
                                      Audited financial of last year 2
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}

                      {camData?.provisional_financial_current_fy_url === null ||
                      camData?.provisional_financial_current_fy_url?.length ===
                        0 ? (
                        <>
                          <div className="col col-4 col-md-4 col-sm-12 col-lg-4 mt-2">
                            <div className="view-kyc-doc-box p-3 ">
                              <div className="d-flex justify-content-center align-item-center">
                                <div className="text-center">
                                  <div className=" d-flex justify-content-center align-item-center mb-4 mt-3">
                                    <img
                                      alt=""
                                      src={fileIcon}
                                      // width={'70%'}
                                    />
                                  </div>

                                  <div className="doc-Not-Uploaded text-center mb-4 ms-4">
                                    NOT UPLOADED
                                  </div>
                                  <div className="view-kyc-doc-title mt-2">
                                    Provisional financial of current FY
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      {camData?.provisional_financial_current_fy_url?.map(
                        (item, index) => {
                          return (
                            <div className="col col-4 col-md-4 col-sm-12 col-lg-4 mt-2">
                              <div className="view-kyc-doc-box p-3 ">
                                <div className="d-flex justify-content-center align-item-center">
                                  <div className="text-center ">
                                    {item.url === '' || item.url === null ? (
                                      <>
                                        <div className="d-flex justify-content-center align-item-center mb-4 mt-3">
                                          <img
                                            alt=""
                                            src={fileIcon}
                                            // width={'100%'}
                                          />
                                        </div>

                                        <div className="doc-Not-Uploaded text-center mb-4">
                                          NOT UPLOADED
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {/* <div
                                    style={{ float: "right" }}
                                    className="pb-2 pe-3"
                                  >
                                    {" "}
                                    <Button
                                      onClick={() =>
                                        downloadPDF(
                                          `${item.url}`,
                                          " Provisional financial of current FY.pdf"
                                        )
                                      }
                                    >
                                      <DownloadIcon
                                        className="dounload-icon-color"
                                        style={{ fontSize: "30px" }}
                                      />
                                    </Button>
                                  </div> */}
                                        <div className="p-2">
                                          {isPDForJPEG(item.url) ? (
                                            <>
                                              <div
                                                style={{
                                                  maxHeight: '200px',
                                                  maxWidth: '300px',
                                                  overflowY: 'scroll',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                {console.log(item?.url)}
                                                <div className="  ">
                                                  <Document
                                                    width={900}
                                                    file={item?.url}
                                                    onLoadSuccess={
                                                      onDocumentLoadSuccess
                                                    }
                                                    onLoadError={(error) =>
                                                      console.error(
                                                        'Error while loading document!',
                                                        error
                                                      )
                                                    }
                                                  >
                                                    <Page
                                                      pageNumber={pageNumber}
                                                    />
                                                  </Document>
                                                </div>
                                                <div className="flex justify-between mt-5 pb-3">
                                                  <p className="">
                                                    Page{' '}
                                                    {pageNumber ||
                                                      (numPages
                                                        ? 1
                                                        : '--')}{' '}
                                                    of {numPages || '--'}
                                                  </p>
                                                  <div className="flex gap-2">
                                                    <Button
                                                      size="small"
                                                      variant="contained"
                                                      disabled={pageNumber <= 1}
                                                      onClick={previousPage}
                                                      sx={{
                                                        backgroundColor:
                                                          '#040D43',
                                                      }}
                                                    >
                                                      Previous
                                                    </Button>
                                                    <Button
                                                      size="small"
                                                      className="ml-2"
                                                      variant="contained"
                                                      disabled={
                                                        pageNumber >= numPages
                                                      }
                                                      onClick={nextPage}
                                                      sx={{
                                                        backgroundColor:
                                                          '#040D43',
                                                      }}
                                                    >
                                                      Next
                                                    </Button>
                                                  </div>
                                                </div>

                                                {/* <PDFViewer
                                                      document={{
                                                        url: `${item.url}`,
                                                      }}
                                                    /> */}
                                                {/* <Document
                                               
                                                  file={item?.url}
                                                  onLoadSuccess={onLoadSuccess}
                                                >
                                                  {numPages && (
                                                    <>
                                                      {showFullPdf ? (
                                                        Array.from(
                                                          new Array(numPages),
                                                          (_, i) => (
                                                            <Page
                                                              key={`page_${
                                                                i + 1
                                                              }`}
                                                              pageNumber={i + 1}
                                                            />
                                                          )
                                                        )
                                                      ) : (
                                                        <div
                                                          onClick={() =>
                                                            setShowFullPdf(true)
                                                          }
                                                        >
                                                          <Page
                                                            pageNumber={1}
                                                          />
                                                        </div>
                                                      )}
                                                    </>
                                                  )}
                                                </Document> */}
                                              </div>
                                            </>
                                          ) : (
                                            <img
                                              className="img-fluid"
                                              src={item?.url}
                                              alt="File"
                                              width={'100%'}
                                            />
                                          )}
                                        </div>
                                      </>
                                    )}
                                    <div className="view-kyc-doc-title mt-2">
                                      Provisional financial of current FY
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}

                      {camData?.gst_statement_12_months_url === null ||
                      camData?.gst_statement_12_months_url?.length === 0 ? (
                        <>
                          <div className="col col-4 col-md-4 col-sm-12 col-lg-4 mt-2">
                            <div className="view-kyc-doc-box p-3 ">
                              <div className="d-flex justify-content-center align-item-center">
                                <div className="text-center">
                                  <div className="mb-4 mt-3 flex justify-content-center align-item-center">
                                    <img
                                      alt=""
                                      src={fileIcon}
                                      // width={'70%'}
                                    />
                                  </div>

                                  <div className="doc-Not-Uploaded text-center mb-4 ms-4">
                                    NOT UPLOADED
                                  </div>
                                  <div className="view-kyc-doc-title mt-2">
                                    GST statement of last 12 months
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      {camData?.gst_statement_12_months_url?.map(
                        (item, index) => {
                          return (
                            <div className="col col-4 col-md-4 col-lg-4 mt-2">
                              <div className="view-kyc-doc-box p-3 ">
                                <div className="d-flex justify-content-center align-item-center">
                                  <div className=" ">
                                    {item.url === '' || item.url === null ? (
                                      <>
                                        <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                          <img alt="" src={fileIcon} />
                                        </div>

                                        <div className=" text-center mb-4 ms-5">
                                          <div className="doc-Not-Uploaded">
                                            NOT UPLOADED
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {/* <div
                                  style={{ float: "right" }}
                                  className="pb-2 pe-3"
                                >
                                  {" "}
                                  <Button
                                    onClick={() =>
                                      downloadPDF(
                                        `${item.url}`,
                                        "  GST statement of last 12 months.pdf"
                                      )
                                    }
                                  >
                                    <DownloadIcon
                                      className="dounload-icon-color"
                                      style={{ fontSize: "30px" }}
                                    />
                                  </Button>
                                </div> */}
                                        <div className="p-2">
                                          {isPDForJPEG(item.url) ? (
                                            <>
                                              <div
                                                style={{
                                                  maxHeight: '200px',
                                                  maxWidth: '300px',
                                                  overflowY: 'scroll',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                {console.log(item?.url)}
                                                <div className="  ">
                                                  <Document
                                                    width={900}
                                                    file={item?.url}
                                                    onLoadSuccess={
                                                      onDocumentLoadSuccess
                                                    }
                                                    onLoadError={(error) =>
                                                      console.error(
                                                        'Error while loading document!',
                                                        error
                                                      )
                                                    }
                                                  >
                                                    <Page
                                                      pageNumber={pageNumber}
                                                    />
                                                  </Document>
                                                </div>
                                                <div className="flex justify-between mt-5 pb-3">
                                                  <p className="">
                                                    Page{' '}
                                                    {pageNumber ||
                                                      (numPages
                                                        ? 1
                                                        : '--')}{' '}
                                                    of {numPages || '--'}
                                                  </p>
                                                  <div className="flex gap-2">
                                                    <Button
                                                      size="small"
                                                      variant="contained"
                                                      disabled={pageNumber <= 1}
                                                      onClick={previousPage}
                                                      sx={{
                                                        backgroundColor:
                                                          '#040D43',
                                                      }}
                                                    >
                                                      Previous
                                                    </Button>
                                                    <Button
                                                      size="small"
                                                      className="ml-2"
                                                      variant="contained"
                                                      disabled={
                                                        pageNumber >= numPages
                                                      }
                                                      onClick={nextPage}
                                                      sx={{
                                                        backgroundColor:
                                                          '#040D43',
                                                      }}
                                                    >
                                                      Next
                                                    </Button>
                                                  </div>
                                                </div>

                                                {/* <PDFViewer
                                                      document={{
                                                        url: `${item.url}`,
                                                      }}
                                                    /> */}
                                                {/* <Document
                                               
                                                  file={item?.url}
                                                  onLoadSuccess={onLoadSuccess}
                                                >
                                                  {numPages && (
                                                    <>
                                                      {showFullPdf ? (
                                                        Array.from(
                                                          new Array(numPages),
                                                          (_, i) => (
                                                            <Page
                                                              key={`page_${
                                                                i + 1
                                                              }`}
                                                              pageNumber={i + 1}
                                                            />
                                                          )
                                                        )
                                                      ) : (
                                                        <div
                                                          onClick={() =>
                                                            setShowFullPdf(true)
                                                          }
                                                        >
                                                          <Page
                                                            pageNumber={1}
                                                          />
                                                        </div>
                                                      )}
                                                    </>
                                                  )}
                                                </Document> */}
                                              </div>
                                            </>
                                          ) : (
                                            <img
                                              className="img-fluid"
                                              src={item?.url}
                                              alt="File"
                                              width={'100%'}
                                              // height={"40%"}
                                              // width="100%"
                                              // height={"200px"}
                                            />
                                          )}
                                        </div>
                                      </>
                                    )}
                                    <div className="view-kyc-doc-title mt-2">
                                      GST statement of last 12 months
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* Other Doc */}
              <div className="mb-2 mt-2">
                <div>
                  <div className="m-0 p-0">
                    <Button
                      type="button"
                      onClick={otherDocClickHandler}
                      style={{ border: 'none' }}
                    >
                      <span className="personal-sub-title ">
                        OTHER DOCUMENTS
                      </span>
                      <MdOutlineExpandMore
                        className="personal-title"
                        style={{ fontSize: '28px' }}
                      />
                    </Button>
                  </div>
                </div>
                {otherDocExpandFlag ? (
                  <div className="mt-3 mb-2">
                    <div className="row">
                      <div className="col col-3 col-md-3 col-sm-12 col-lg-3">
                        <div className="d-flex justify-content-center ">
                          <div className="text-center">
                            <div className="view-kyc-doc-box p-3 ">
                              <div className="d-flex justify-content-center align-item-center">
                                <div className=" ">
                                  {camData?.additional_documents_recived_any
                                    ?.length === 0 ||
                                  camData?.additional_documents_recived_any ===
                                    null ||
                                  camData?.additional_documents_recived_any[0]
                                    ?.url === '' ? (
                                    <>
                                      <div className="flex justify-content-center align-item-center mb-4 mt-3">
                                        <img
                                          alt=""
                                          src={fileIcon}
                                          // width={'60%'}
                                        />
                                      </div>

                                      <div className="doc-Not-Uploaded  mb-4 ms-5">
                                        <div className="text-center ">
                                          {' '}
                                          NOT UPLOADED
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {/* <div
                                  style={{ float: "right" }}
                                  className="pb-2"
                                >
                                  {" "}
                                  <Button
                                    onClick={() =>
                                      isPDForJPEG(
                                        camData
                                          ?.additional_documents_recived_any[0]
                                          ?.url
                                      )
                                        ? downloadPDF(
                                            `${camData?.additional_documents_recived_any[0]?.url}`,
                                            "Other Document.pdf"
                                          )
                                        : downloadImage(
                                            `${camData?.additional_documents_recived_any[0]?.url}`,
                                            "Other Document.jpg"
                                          )
                                    }
                                  >
                                    <DownloadIcon className="dounload-icon-color" />
                                  </Button>
                                </div> */}
                                      <div className="p-2">
                                        {isPDForJPEG(
                                          camData
                                            ?.additional_documents_recived_any[0]
                                            ?.url
                                        ) ? (
                                          <div
                                            style={{
                                              maxHeight: '200px',
                                              maxWidth: '300px',
                                              overflowY: 'scroll',
                                              justifyContent: 'center',
                                            }}
                                          >
                                            {console.log(item?.url)}
                                            <div className="  ">
                                              <Document
                                                width={900}
                                                file={
                                                  camData
                                                    ?.additional_documents_recived_any[0]
                                                    ?.url
                                                }
                                                onLoadSuccess={
                                                  onDocumentLoadSuccess
                                                }
                                                onLoadError={(error) =>
                                                  console.error(
                                                    'Error while loading document!',
                                                    error
                                                  )
                                                }
                                              >
                                                <Page pageNumber={pageNumber} />
                                              </Document>
                                            </div>
                                            <div className="flex justify-between mt-5 pb-3">
                                              <p className="">
                                                Page{' '}
                                                {pageNumber ||
                                                  (numPages ? 1 : '--')}{' '}
                                                of {numPages || '--'}
                                              </p>
                                              <div className="flex gap-2">
                                                <Button
                                                  size="small"
                                                  variant="contained"
                                                  disabled={pageNumber <= 1}
                                                  onClick={previousPage}
                                                  sx={{
                                                    backgroundColor: '#040D43',
                                                  }}
                                                >
                                                  Previous
                                                </Button>
                                                <Button
                                                  size="small"
                                                  className="ml-2"
                                                  variant="contained"
                                                  disabled={
                                                    pageNumber >= numPages
                                                  }
                                                  onClick={nextPage}
                                                  sx={{
                                                    backgroundColor: '#040D43',
                                                  }}
                                                >
                                                  Next
                                                </Button>
                                              </div>
                                            </div>

                                            {/* <PDFViewer
                                                      document={{
                                                        url: `${item.url}`,
                                                      }}
                                                    /> */}
                                            {/* <Document
                                               
                                                  file={item?.url}
                                                  onLoadSuccess={onLoadSuccess}
                                                >
                                                  {numPages && (
                                                    <>
                                                      {showFullPdf ? (
                                                        Array.from(
                                                          new Array(numPages),
                                                          (_, i) => (
                                                            <Page
                                                              key={`page_${
                                                                i + 1
                                                              }`}
                                                              pageNumber={i + 1}
                                                            />
                                                          )
                                                        )
                                                      ) : (
                                                        <div
                                                          onClick={() =>
                                                            setShowFullPdf(true)
                                                          }
                                                        >
                                                          <Page
                                                            pageNumber={1}
                                                          />
                                                        </div>
                                                      )}
                                                    </>
                                                  )}
                                                </Document> */}
                                          </div>
                                        ) : (
                                          <img
                                            className="img-fluid"
                                            src={
                                              camData
                                                ?.additional_documents_recived_any[0]
                                                ?.url
                                            }
                                            alt="File"
                                            style={{
                                              width: '300px',
                                              height: '200px',
                                            }}
                                            // width={"100%"}
                                          />
                                        )}
                                      </div>
                                    </>
                                  )}
                                  <div className="view-kyc-doc-title mt-2">
                                    Additional documents recieved if any
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <div className="text-center  mt-5 pt-5">
              <h4 className="mt-5" style={{ color: 'red' }}>
                {' '}
                No details or records found.
              </h4>
            </div>
          )}
        </>
      )}
      <ClientAssignLimit
        openlimitModel={openlimitModel}
        closeLimitModel={closeLimitModel}
        getAllData={''}
        camId={id}
      />
      <ClientQuery
        openQueryModel={openQueryModel}
        closeQueryModel={closeQueryModel}
        camId={id}
      />
    </div>
  );
}

export default ClientView;
