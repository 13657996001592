import {
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { InputLabel } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { json } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid gray',
  boxShadow: 44,
  p: 3,
  borderRadius: '12px',
};
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ReportModel({
  piplineModelOpen,
  closeHnadlerForPiplineModel,
  value,
  getData,
}) {
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState('');
  const [isUpload, setIsUpload] = useState(false);

  const formData = new FormData();
  // ================= Upload function =======================
  const uploadExcelFile = () => {
    console.log('upload call');
    console.log(file);
    if (file) {
      setIsUpload(true);
      setFileError('');
    } else {
      setFileError('Please select file');
      setIsUpload(false);
    }
  };
  console.log(isUpload, 'upload');
  const data = {
    arr1: [
      { id: '101', name: 'abc' },
      { id: '111', name: 'abgc' },
      { id: '121', name: 'ahgbc' },
    ],
    arr2: [
      { id: '201', name: 'xyz' },
      { id: '211', name: 'xyzc' },
      { id: '221', name: 'xyzcd' },
    ],
    arr3: [
      { id: '301', name: 'pqr' },
      { id: '311', name: 'pqrc' },
      { id: '321', name: 'pqrcd' },
    ],
  };

  // Mapping all arrays
  const mappedData = {};
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      mappedData[prop] = data[prop].map((obj) => {
        // Return the entire object
        return obj;
      });
    }
  }

  console.log(mappedData.arr1);
  // ======================= Call API as per filter ===================
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (isUpload && fileError === '') {
      if (value === 'pipeline') {
        formData.append('file', file);
        axios
          .post(`/api/ubfc/ubfc_pipelines/excel_upload`, formData, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response?.data);
            setIsUpload(false);
            toast.info(`${response?.data?.message}`, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
            closeHnadlerForPiplineModel();
            setFile('');
            setFileError('');
            getData();
          })
          .catch((error) => {
            console.log(error);
            setIsUpload(false);
          });
      }
      if (value === 'disbursement') {
        formData.append('file', file);
        axios
          .post(`/api/ubfc/ubfc_disbursed/excel_upload`, formData, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response?.data);
            setIsUpload(false);
            toast.info(`${response?.data?.message}`, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
            closeHnadlerForPiplineModel();
            setFile('');
            setFileError('');
            getData();
          })
          .catch((error) => {
            console.log(error);
            setIsUpload(false);
          });
      }
      if (value === 'collection') {
        formData.append('file', file);
        axios
          .post(`/api/ubfc/ubfc_collection/excel_upload`, formData, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            console.log(response?.data);
            setIsUpload(false);
            toast.info(`${response?.data?.message}`, {
              position: 'top-right',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
            closeHnadlerForPiplineModel();
            setFile('');
            setFileError('');
            getData();
          })
          .catch((error) => {
            console.log(error);
            setIsUpload(false);
          });
      }
    } else {
      setIsUpload(false);
    }
  }, [isUpload]);

  return (
    <div>
      <Modal
        open={piplineModelOpen}
        onClose={closeHnadlerForPiplineModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style} className=" p-0">
          <Typography
            variant="h6"
            component="div"
            className="fw-bolder  p-3"
            style={{ backgroundColor: '#F3F3F3', color: '#100C5D' }}
          >
            Upload {value} file
          </Typography>
          <hr className="p-0 m-0" />
          <CardContent>
            {/* <InputLabel className="m-1">Select date</InputLabel>
            <TextField type="date" fullWidth className="mb-2" /> */}
            <InputLabel className="m-1">Upload file</InputLabel>
            <TextField
              type="file"
              fullWidth
              onChange={(e) => setFile(e.target.files[0])}
            />
            <span style={{ color: 'red', fontSize: '12px' }}>{fileError}</span>
          </CardContent>

          <CardActions className="d-flex justify-content-between">
            <Button
              size="large"
              variant="contained"
              onClick={() => uploadExcelFile()}
            >
              Upload
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={() => closeHnadlerForPiplineModel()}
              style={{ backgroundColor: '#A22531' }}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default ReportModel;
