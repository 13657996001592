import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ClientQuery({ openQueryModel, closeQueryModel, getAllData, camId }) {
  const [queryType, setQueryType] = useState();
  const [query, setQuery] = useState();
  const [queryError, setQueryError] = useState({});
  const [isQuery, setIsQuery] = useState(false);
  const options = [
    { value: "low_ayescore", label: "Low ayescore" },
    { value: "incomplete_documents", label: "Incomplete documents" },
    { value: "privious_dues", label: "Privious dues" },
  ];
  const token = localStorage.getItem("token");
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      borderColor: "#0C3094",
      backgroundColor: "#F5F5F5",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#0C3094",
      backgroundColor: "#F5F5F5",
    }),
  };

  const handleSelectChange = (selectedOption) => {
    // Handle selected option change here
    // console.log(selectedOption);
    setQueryType(selectedOption.value);
  };

  const submitHandler = () => {
    setIsQuery(true);
    const err = {};
    if (!query) {
      err.query = "Please enter query..";
    }
    if (!queryType) {
      err.queryType = "Please select query type";
    }
    setQueryError(err);
  };

  useEffect(() => {
    if (Object.keys(queryError).length === 0 && isQuery) {
      axios
        .patch(
          `/api/client_management/change_inprogess_status`,
          {
            user_id: camId,
            prospect_status: "query",
            query_reason: query,
            query_type: queryType,
          },
          {
            headers: { Authorization: `Bearee ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setIsQuery(false);
          closeQueryModel()
          getAllData()
        })
        .catch((error) => {
          console.log(error);
          setIsQuery(false);
        });
    }
    else{
        setIsQuery(false)
    }
  }, [isQuery]);
  return (
    <div>
      {" "}
      <Modal
        open={openQueryModel}
        onClose={closeQueryModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="custom-ui p-4"
            style={{
              backgroundColor: "#F5F5F5",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <h2>Are you sure?</h2>
            <p>You want to query this?</p>
            <p>Please select query reason</p>
            <Select
              options={options}
              onChange={handleSelectChange}
              placeholder="Program Name"
              styles={customStyles}
            />
            <br />
            <span style={{ color: "red", fontSize: "12px" }}>
              {queryError?.queryType}{" "}
            </span>
            <br />
            <input
              type="text"
              placeholder="Enter query..."
              className="p-2"
              style={{ borderRadius: "5px" }}
              onChange={(e) => setQuery(e.target.value)}
            />
            <br />
            <span style={{ color: "red", fontSize: "12px" }}>
              {queryError?.query}{" "}
            </span>
            <br />
            <Button
              variant="contained"
              onClick={closeQueryModel}
              className="me-2 m-3 ms-0"
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                submitHandler()
              }}
            >
              Yes, Query it!
            </Button>
          </div>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default ClientQuery;
