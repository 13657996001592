import React from 'react';
import { Button } from '@mui/material/';
import PDFViewer from 'pdf-viewer-reactjs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMemo } from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import ClearIcon from '@mui/icons-material/Clear';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  hight: '500',
  marginTop: '200px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,

  p: 4,
};

function DocModel({ open, handleClose, docAddress, docTitle }) {
  const [scroll, setScroll] = React.useState('paper');
  const url = docAddress;
  const pdfUrl = useMemo(() => ({ url: docAddress }));

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <div className="d-flex justify-content-between">
          <DialogTitle id="scroll-dialog-title">{docTitle}</DialogTitle>

          <div className=" text-center">
            <ClearIcon
              onClick={handleClose}
              sx={{ mt: 3, ml: 2, mr: 2 }}
              role="button"
            />
          </div>
        </div>

        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <PDFViewer
              document={pdfUrl}
              //  document={{ url: docAddress }}
              onDocumentClick={() => alert('Document was clicked')}
              onPrevBtnClick={(page) => alert(`Page ${page} selected`)}
              onNextBtnClick={(page) => alert(`Page ${page} selected`)}
              onZoom={(scale) => alert(`Zoom scale is ${scale}`)}
              onRotation={(angle) => alert(`Page angle is ${angle}`)}
            />
          </DialogContentText>
        </DialogContent>
        <div className=" d-flex justify-content-center  pt-3 pb-3">
          <Button
            style={{
              borderRadius: '24.3px',
              boxShadow: '0px 2px 4.8px 0.2px #000',
              color: '#fff',
              backgroundImage:
                'linear-gradient(to top, #9bb0da, #617db6, #183d8a)',
            }}
            variant="outlined"
            onClick={() => {
              handleDownload(docAddress, `${docTitle}.pdf`);
            }}
          >
            Download File
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default DocModel;
