import * as React from 'react';
import Box from '@mui/material/Box';
import { ThreeCircles } from 'react-loader-spinner';

export default function Loader() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '200px',
      }}
    >
      <ThreeCircles
        height="100"
        width="100"
        color="#4fa94d"
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor="#1e4ea6"
        innerCircleColor="#1e4ea6"
        middleCircleColor="#1e4ea6"
      />
    </Box>
  );
}
