import React from "react";
import { ThreeDots } from "react-loader-spinner";

function HomeData() {
  return (
    <div className="flex justify-center mb-2">
      <div
        style={{
          margin: "auto",
          
        }}
        
      >
        <ThreeDots
        
          height="50"
          width="50"
          radius="9"
          color="rgb(88, 99, 180)"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    </div>
  );
}

export default HomeData;
