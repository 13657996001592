import React, { useState, Suspense, lazy, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
const MarchentDetailsSiply = lazy(() =>
  import('../pages/MerchantDetailsSiply')
);
const MerchantDocumentsSiply = lazy(() =>
  import('../pages/MerchantDocumentsSiply')
);
const MerchantInsightSiply = lazy(() =>
  import('../pages/MerchantInsightSiply')
);
import AnanyaFinanceKPI from './AnanyaFinanceKPI';
const Admin = lazy(() => import('./AdminPanel/adminPanel'));
const MarchentDetails = lazy(() => import('../pages/MarchentDetails'));
const SideBar = lazy(() => import('./SideBar'));
const Navbar = lazy(() => import('./Navbar'));
const MerchantDocuments = lazy(() => import('../pages/MerchantDocuments'));
const MerchantInsight = lazy(() => import('../pages/MerchantInsight'));
const KPI = lazy(() => import('./KPI'));
const ActiveMain = lazy(() => import('./ActiveMain'));
const OrderTracker = lazy(() => import('./OrderTracker'));
const OrderDetails = lazy(() => import('./OrderDetails'));
const Login = lazy(() => import('./Login'));
import Loader from './common/Loader';
const OrderDetailsForSiply = lazy(() => import('./OrderDetailsForSiply'));
const OrderTrackerForSiply = lazy(() => import('./OrderTrackerForSiply'));
const JanaCuratedInvoice = lazy(() => import('../pages/janaCuratedInvoice'));
import ProspectiveInvoicedetails from './ProspectiveInvoiceDetails';
import Dashboard from './Dashboard/Dashboard';
import ClientManagement from './Client Management/ClientManagement';
import MapPage from './Client Management/MapPage';
import OrderManagement from './Order Management/OrderManagement';
import ClientView from './Client Management/ClientView';
import { AdminPanelSettings } from '@mui/icons-material';
import '../../src/tailwind.output.css';
import UBFCCurateds from './UBFC/UBFCCurateds';
import UbfcReport from './UBFCReports/UbfcReport';
import importsFirst from 'eslint-plugin-import/lib/rules/imports-first';
const FPODisbursement = lazy(() => import('./FPODisbursement/FPODisbursement'));
const UserManagement = lazy(() =>
  import('../components/AdminPanel/userManagement')
);
const Productmanagement = lazy(() =>
  import('../components/AdminPanel/productManagement')
);
const Productmanagementform = lazy(() =>
  import('../components/AdminPanel/productManagementForm')
);
const Productmanagementview = lazy(() =>
  import('../components/AdminPanel/productManagementFormView')
);
const AdminDashboard = lazy(() => import('../components/AdminPanel/dashboard'));
const Prospects = lazy(() => import('./Prospects'));

function Main() {
  const [state, setState] = useState(true);
  const [first_Name, setFirstName] = useState();
  const [last_Name, setLastName] = useState();
  const [isAuthenticated, setIsAuthenticaked] = useState(false);
  useEffect(() => {
    setFirstName(localStorage.getItem('first_Name'));
    setLastName(localStorage.getItem('last_Name'));
  }, []);
  const onBlurHandler = () => {
    setState(false);
  };

  const [dummy, setDummy] = useState(false);

  const refreshHandler = () => {
    setDummy(!dummy);
  };

  const onFocusHandler = () => {
    setState(true);
  };

  function isAuth() {
    const token = localStorage.getItem('token');
    if (token) return true;
  }
  const urlString = window.location.href;
  const isURL = urlString.endsWith('/');

  return (
    <div style={{ marginBottom: '100px' }}>
      <div className="App">
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
          <div>
            {!isURL && <Navbar state={state} setState={setState} />}
            {!isURL && (
              <SideBar
                state={state}
                setState={setState}
                // onFocusHandler={onFocusHandler}
                // onBlurHandler={onBlurHandler}
              />
            )}
            <Routes>
              <Route
                path="/home"
                element={
                  <Dashboard isOpen={state} refreshHandler={refreshHandler} />
                }
              />
            </Routes>
            <Routes>
              <Route
                path="/clientManagement"
                element={
                  <ClientManagement
                    isOpen={state}
                    refreshHandler={refreshHandler}
                  />
                }
              />
            </Routes>

            <Routes>
              <Route
                path="/clientManagement/View/:status/:id"
                element={<ClientView isOpen={state} />}
              />
            </Routes>
            <Routes>
              <Route
                path="/clientManagement/prospect"
                element={
                  <ClientManagement
                    isOpen={state}
                    refreshHandler={refreshHandler}
                  />
                }
              />
            </Routes>
            <Routes>
              <Route
                path="/clientManagement/inProgress"
                element={
                  <ClientManagement
                    isOpen={state}
                    refreshHandler={refreshHandler}
                  />
                }
              />
            </Routes>
            <Routes>
              <Route
                path="/clientManagement/mapPage"
                element={<MapPage isOpen={state} />}
              />
            </Routes>
            <Routes>
              <Route
                path="/clientManagement/details/:id"
                element={<OrderDetailsForSiply isOpen={state} />}
              />
            </Routes>

            <Routes>
              <Route
                path="/orderManagement"
                element={<OrderManagement isOpen={state} />}
              />
            </Routes>
            <Routes>
              <Route
                path="/orderManagement/disbursed"
                element={<OrderManagement isOpen={state} />}
              />
            </Routes>

            <Routes>
              <Route
                path="/orderManagement/available"
                element={<OrderManagement isOpen={state} />}
              />
            </Routes>
            <Routes>
              <Route
                path="/orderManagement/close"
                element={<OrderManagement isOpen={state} />}
              />
            </Routes>
            <Routes>
              <Route
                path="/orderManagement/close"
                element={<OrderManagement isOpen={state} />}
              />
            </Routes>

            <Routes>
              <Route
                path="/curatedOrders"
                element={<ActiveMain isOpen={state} />}
              />
              <Route
                path="/curatedOrders/merchentDetails/:id"
                element={<MarchentDetails isOpen={state} />}
              />
              <Route
                path="/orderTrackerForSiply/merchentDetails/:id"
                element={<MarchentDetailsSiply isOpen={state} />}
              />
              <Route
                path="/clientManagement/orderTrackerForSiply/merchentDetails/:id"
                element={<MarchentDetailsSiply isOpen={state} />}
              />
              <Route
                path="/curatedOrders/merchentDetails/merchentDocuments/:id"
                element={<MerchantDocuments isOpen={state} />}
              />
              {/* <Route
                path="/clientManagement/merchentDetails/merchentDocuments/:id"
                element={<MerchantDocuments isOpen={state} />}
              /> */}
              <Route
                path="/orderTrackerForSiply/merchentDetails/merchentDocuments/:id"
                element={<MerchantDocumentsSiply isOpen={state} />}
              />
              <Route
                path="/ubfc/curatedmerchant/merchentDocuments/:id"
                element={<MerchantDocumentsSiply isOpen={state} />}
              />
              <Route
                path="/kpi"
                element={<KPI isOpen={state} refreshHandler={refreshHandler} />}
              />
              <Route
                path="/ubfc/report"
                element={
                  <UbfcReport isOpen={state} refreshHandler={refreshHandler} />
                }
              />

              <Route
                path="/AnanyaFinance/kpi"
                element={
                  <AnanyaFinanceKPI
                    isOpen={state}
                    refreshHandler={refreshHandler}
                  />
                }
              />

              <Route
                path="/curatedOrders/merchentInsight/:id"
                element={<MerchantInsight isOpen={state} />}
              />
              <Route
                path="/orderTrackerForSiply/merchamtDetails/merchentInsight/:id"
                element={<MerchantInsightSiply isOpen={state} />}
              />
              <Route
                path="/orderTrackerForSiply"
                element={<OrderTrackerForSiply isOpen={state} />}
              />
              <Route
                path="/admin"
                element={<AdminDashboard isOpen={state} />}
              />
              <Route
                path="/admin/user-management"
                element={<UserManagement isOpen={state} />}
              />
              <Route
                path="/admin/dashboard"
                element={<AdminDashboard isOpen={state} />}
              />
              <Route
                path="/admin/product-management"
                element={<Productmanagement isOpen={state} />}
              />
              <Route
                path="/admin/product-management/add"
                element={<Productmanagementform isOpen={state} />}
              />
              <Route
                path="/admin/product-management/view/:id"
                element={<Productmanagementview isOpen={state} />}
              />
              <Route
                path="/ubfc/curatedmerchant"
                element={<UBFCCurateds isOpen={state} />}
              />
              {/* <Route
                path="/FPOdisbursement"
                element={<FPODisbursement isOpen={state} />}
              /> */}
              <Route
                path="FPOdisbursement"
                element={<FPODisbursement isOpen={state} />}
              />
              <Route
                path="/AnanyaFinance/orderTracker"
                element={<OrderTracker isOpen={state} />}
              />
              <Route
                path="/AnanyaFinance/orderTracker/details/:id"
                element={<OrderDetails isOpen={state} />}
              />
              <Route
                path="/orderTrackerForSiply/details/:id"
                element={<OrderDetailsForSiply isOpen={state} />}
              />
              <Route
                path="/orderManagement/details/:id"
                element={<OrderDetailsForSiply isOpen={state} />}
              />
              <Route
                path="/AnanyaFinance/prospectiveInvoices"
                element={<Prospects isOpen={state} />}
              />
              <Route
                path="/AnanyaFinance/prospectiveInvoices/details/:id"
                element={<ProspectiveInvoicedetails isOpen={state} />}
              />
            </Routes>
            <Routes>
              <Route
                path="/JanaSmallFinanceBank/curatedInvoice"
                element={<JanaCuratedInvoice isOpen={state} />}
              />
            </Routes>
          </div>
        </Suspense>
      </div>
    </div>
  );
}

export default Main;
