import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';
import '../UBFCReports/ubfcReport.css';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ReportModel from '../../pages/ReportModel';
import axios from 'axios';
import errors from 'eslint-plugin-import/config/errors';
import UBFCAssignLimit from '../UBFC/UBFCAssignLimit';
import Loader from '../common/Loader';
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
function UbfcReport({ isOpen, refreshHandler }) {
  const [value, setValue] = React.useState('pipeline');
  const [pipelineData, setPiplineData] = useState([]);
  const [piplineModelOpen, setPiplineModelOpen] = useState(false);
  const closeHnadlerForPiplineModel = () => setPiplineModelOpen(false);
  const [disbursedData, setDisbursedData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLoading(true);
  };
  const token = localStorage.getItem('token');
  const getData = () => {
    if (value === 'pipeline') {
      axios
        .get(`/api/ubfc/ubfc_pipelines_details`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response?.data);
          const arr = Object.entries(response?.data?.data);

          setPiplineData(arr);
          setLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (value === 'disbursement') {
      axios
        .get(`/api/ubfc/ubfc_disbursed_details`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response?.data);
          const arr = Object.entries(response?.data?.data);

          setDisbursedData(arr);
          setLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (value === 'collection') {
      axios
        .get(`/api/ubfc/ubfc_collection_details`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response?.data);
          const arr = Object.entries(response?.data?.data);

          setCollectionData(arr);
          setLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    getData();
  }, [value]);
  console.log(pipelineData);

  function downloadExcel(data, fileName) {
    const ws = utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    writeFile(wb, fileName);
  }
  return (
    <div
      style={{ paddingTop: '80px' }}
      className={
        isOpen
          ? 'ubfc-report-main-page-with-sidebar container-fluid pe-0 '
          : 'ubfc-report-main-page  ccontainer-fluid pe-0 '
      }
    >
      <TabContext value={value} className="m-2 mb-0">
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              style={{ backgroundColor: '#D8E0E0', borderRadius: '8px' }}
              value="pipeline"
              label="Pipeline "
              className="fw-bolder ms-3 me-3"
            />
            <Tab
              style={{ backgroundColor: '#D8E0E0', borderRadius: '8px' }}
              value="disbursement"
              label="Disbursement "
              className="fw-bolder ms-3 me-3"
            />
            <Tab
              style={{ backgroundColor: '#D8E0E0', borderRadius: '8px' }}
              value="collection"
              label="Collection"
              className="fw-bolder ms-3 "
            />
          </Tabs>
        </Box>
        <TabPanel value="pipeline">
          <div className="d-flex justify-content-end">
            {/* <input
              type="date"
              className="mb-2 ms-0 "
              value={date}
              onChange={(e) => setDate(e.target.value)}
            /> */}
            <Button
              className="mb-2 ms-0 "
              variant="outlined"
              onClick={() => setPiplineModelOpen(true)}
            >
              <AddIcon className="me-1" /> Upload pipeline file
            </Button>
            {/* <Button
              className="mb-3 ms-2"
              variant="contained"
              onClick={() => setPiplineModelOpen(true)}
            >
              <ArrowDownwardIcon className="me-1" /> Download pipeline file
            </Button> */}
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              {pipelineData?.map((item, index) => (
                <div className="col col-md-4 col-sm-4 col-lg-4 mt-2">
                  {console.log(item[1])}
                  <Card
                    sx={{ borderTop: '2px solid #000' }}
                    className="mt-2 p-2"
                  >
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Date: {moment(item[1][0]?.created_at).format('LL')}
                      </Typography>
                      <hr />
                    </CardContent>
                    <CardActions className="d-flex justify-content-around">
                      <Button
                        size="large"
                        variant="contained"
                        style={{
                          backgroundColor: '#636266',
                        }}
                        onClick={() =>
                          downloadExcel(
                            item[1],
                            `${value}_${moment(item[1][0]?.created_at).format(
                              'll'
                            )}.xlsx`
                          )
                        }
                      >
                        <ArrowDownwardIcon className="me-2" /> Download
                      </Button>
                    </CardActions>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </TabPanel>
        <TabPanel value="disbursement">
          <div className="d-flex justify-content-end">
            {/* <input
              type="date"
              className="mb-2 ms-0 "
              value={date}
              onChange={(e) => setDate(e.target.value)}
            /> */}
            <Button
              className="mb-2 ms-0 "
              variant="outlined"
              onClick={() => setPiplineModelOpen(true)}
            >
              <AddIcon className="me-1" /> Upload disbursement file
            </Button>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="row">
              {disbursedData?.map((item, index) => (
                <div className="col col-md-4 col-sm-4 col-lg-4 mt-2">
                  {console.log(item[1])}
                  <Card
                    sx={{ borderTop: '2px solid #000' }}
                    className="mt-2 p-2"
                  >
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Date: {moment(item[1][0]?.created_at).format('LL')}
                      </Typography>
                      <hr />
                    </CardContent>
                    <CardActions className="d-flex justify-content-around">
                      <Button
                        size="large"
                        variant="contained"
                        style={{
                          backgroundColor: '#636266',
                        }}
                        onClick={() =>
                          downloadExcel(
                            item[1],
                            `${value}_${moment(item[1][0]?.created_at).format(
                              'll'
                            )}.xlsx`
                          )
                        }
                      >
                        <ArrowDownwardIcon className="me-2" /> Download
                      </Button>
                    </CardActions>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </TabPanel>
        <TabPanel value="collection">
          <div className="d-flex justify-content-end">
            {/* <input
              type="date"
              className="mb-2 ms-0 "
              value={date}
              onChange={(e) => setDate(e.target.value)}
            /> */}
            <Button
              className="mb-2 ms-0 "
              variant="outlined"
              onClick={() => setPiplineModelOpen(true)}
            >
              <AddIcon className="me-1" /> Upload collection file
            </Button>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="row mx-0">
              {collectionData?.map((item, index) => (
                <div className="col col-md-4 col-sm-4 col-lg-4 mt-2">
                  {console.log(item[1])}
                  <Card
                    sx={{ borderTop: '2px solid #000' }}
                    className="mt-2 p-2"
                  >
                    <CardContent>
                      <Typography variant="h5" component="div">
                        Date: {moment(item[1][0]?.created_at).format('LL')}
                      </Typography>
                      <hr />
                    </CardContent>
                    <CardActions className="d-flex justify-content-around">
                      <Button
                        size="large"
                        variant="contained"
                        style={{
                          backgroundColor: '#636266',
                        }}
                        onClick={() =>
                          downloadExcel(
                            item[1],
                            `${value}_${moment(item[1][0]?.created_at).format(
                              'll'
                            )}.xlsx`
                          )
                        }
                      >
                        <ArrowDownwardIcon className="me-2" /> Download
                      </Button>
                    </CardActions>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </TabPanel>
      </TabContext>
      <ReportModel
        piplineModelOpen={piplineModelOpen}
        closeHnadlerForPiplineModel={closeHnadlerForPiplineModel}
        value={value}
        getData={getData}
      />
    </div>
  );
}

export default UbfcReport;
