import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import '../acess/css/ananya-kpi.css';
import DatamapsIndia from 'react-datamaps-india';
import img1 from '../acess/images/disbursement.gif';
import img2 from '../acess/images/total_transactions.gif';
import img3 from '../acess/images/collected_amount.gif';
import img4 from '../acess/images/total_sales.gif';
import { useNavigate } from 'react-router-dom';
import Loader from './common/Loader';

function AnanyaFinanceKPI({ isOpen, refreshHandler }) {
  const navigate = useNavigate();
  const [partner_disbursed_amount, setpartner_disbursed_amount] = useState();
  const [total_paid_back_amt, settotal_paid_back_amt] = useState();
  const [total_transactions, settotal_transactions] = useState();
  const [total_sales, settotal_sales] = useState();
  const [stateData, setStateData] = useState();
  const [loading, setLoading] = useState(true);

  // get ananya details
  const token = localStorage.getItem('token');
  useEffect(() => {
    axios
      .get(`/api/amounts?type=amount_disbursed`, {
        headers: {
          authorization: `Bearer ${token} `,
        },
      })
      .then((response) => {
        setpartner_disbursed_amount(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/api/amounts?type=amount_paid_back`, {
        headers: {
          authorization: `Bearer ${token} `,
        },
      })
      .then((response) => {
        settotal_paid_back_amt(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/api/amounts?type=total_transactions`, {
        headers: {
          authorization: `Bearer ${token} `,
        },
      })
      .then((response) => {
        settotal_transactions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    //partnersportal.ayekart.com/api/amounts?type=total_sales

    https: axios
      .get(`/api/amounts?type=total_sales`, {
        headers: {
          authorization: `Bearer ${token} `,
        },
      })
      .then((response) => {
        if (response?.data?.message === 'JSON Web Token Is Expired, Try Again')
          navigate('/');
        settotal_sales(response?.data?.total_sales);
        refreshHandler();
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`/api/kpi/home`, {
        headers: {
          authorization: `Bearer ${token} `,
        },
      })
      .then((response) => {
        let temp = response?.data?.buyer_data;
        setStateData({ ...temp });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div
      style={{ paddingTop: '80px' }}
      className={
        isOpen
          ? 'ananya-kpi-main-box-with-sidebar container-fluid '
          : 'ananya-kpi-main-box container-fluid '
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className=" row  d-flex justify-content-center mx-0  p-0 m-0 ">
            <div className="col col-12 col-md-3 col-lg-3  text-cente d-flex justify-content-center p-0 m-0">
              <div className="anannya-kpi-box1">
                <div className="row  d-flex justify-content-center mt-4">
                  {' '}
                  <img
                    src={img4}
                    alt=""
                    style={{ width: '120px', height: '100px' }}
                  />
                </div>

                <div className="row  d-flex justify-content-center align-item-center  mt-2">
                  {' '}
                  <div className="ananya-box1-no">
                    {total_sales === null
                      ? 0
                      : parseFloat(total_sales).toFixed(2)}
                  </div>
                </div>
                <div className="row  d-flex justify-content-center mt-2">
                  {' '}
                  <div className="anannya-kpi-box1-title">
                    Total Sales Amount
                  </div>
                  <div className="anannya-kpi-box1-title">(in Crore INR)</div>
                </div>
              </div>
            </div>
            <div className="col col-12 col-md-3 col-lg-3  text-center  d-flex justify-content-center p-0 m-0">
              <div className="anannya-kpi-box1 text-center">
                <div className="row  d-flex justify-content-center mt-4">
                  {' '}
                  <img
                    src={img1}
                    alt=""
                    style={{ width: '120px', height: '100px' }}
                  />
                </div>

                <div className="row  d-flex justify-content-center align-item-center  mt-2">
                  {' '}
                  <div className="ananya-box1-no">
                    {partner_disbursed_amount?.partner_disbursed_amount === null
                      ? 0
                      : parseFloat(
                          partner_disbursed_amount?.partner_disbursed_amount
                        ).toFixed(2)}
                  </div>
                </div>
                <div className="row  d-flex justify-content-center mt-2">
                  {' '}
                  <div className="anannya-kpi-box1-title">
                    Total Disbursed Amount
                  </div>
                  <div className="anannya-kpi-box1-title">(in Crore INR)</div>
                </div>
              </div>
            </div>

            <div className="col col-12 col-md-3 col-lg-3  text-cente d-flex justify-content-center p-0 m-0">
              <div className="anannya-kpi-box1">
                <div className="row  d-flex justify-content-center mt-4">
                  {' '}
                  <img
                    src={img3}
                    alt=""
                    style={{ width: '120px', height: '100px' }}
                  />
                </div>

                <div className="row  d-flex justify-content-center align-item-center  mt-2">
                  {' '}
                  <div className="ananya-box1-no">
                    {total_paid_back_amt?.total_paid_back_amt === null
                      ? 0
                      : parseFloat(
                          total_paid_back_amt?.total_paid_back_amt
                        ).toFixed(2)}
                  </div>
                </div>
                <div className="row  d-flex justify-content-center mt-2">
                  {' '}
                  <div className="anannya-kpi-box1-title">
                    Total amount collected
                  </div>
                  <div className="anannya-kpi-box1-title">(in Crore INR)</div>
                </div>
              </div>
            </div>

            <div className="col col-12 col-md-3 col-lg-3  text-center d-flex justify-content-center p-0 m-0">
              <div className="anannya-kpi-box1">
                <div className="row  d-flex justify-content-center mt-4">
                  {' '}
                  <img
                    src={img2}
                    alt=""
                    style={{ width: '120px', height: '100px' }}
                  />
                </div>

                <div className="row  d-flex justify-content-center align-item-center  mt-2">
                  {' '}
                  <div className="ananya-box1-no">
                    {total_transactions?.total_transactions === null
                      ? 0
                      : total_transactions?.total_transactions}
                  </div>
                </div>
                <div className="row  d-flex justify-content-center mt-2">
                  {' '}
                  <div className="anannya-kpi-box1-title">
                    Total Transactions{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Heat Map */}

          <div className="kpi-map-box container mt-5 p-0">
            <div className="map-chart-title pt-3">
              Heat Map Of Buyer's Geography (Number Of Transactions)
            </div>
            <div
              style={{
                position: 'relative',
                width: '500px',
                height: '400px',
                margin: 'auto',
              }}
              className=" d-flex justify-content-center"
            >
              <DatamapsIndia
                regionData={stateData}
                hoverComponent={({ value }) => {
                  return (
                    <div>
                      {value.name}:{value.value || 0}{' '}
                    </div>
                  );
                }}
                mapLayout={{
                  floating: false,
                  legendWidth: '',
                  legendHeight: '',
                  startColor: '#0541eb',
                  endColor: '#1e336e',
                  plotOptions: 'static',
                  hoverTitle: 'Count',
                  noDataColor: '#e1e4ed',
                  borderColor: '#f0f0f0',
                  hoverBorderColor: '#8D8D8D',
                  hoverColor: 'green',
                  projectionType: 'mercator',
                  startColor: '#b3d1ff',
                  endColor: '#005ce6',
                  hoverTitle: 'Count',
                  noDataColor: '#f5f5f5',
                  borderColor: '#8D8D8D',
                  hoverColor: 'green',
                  height: 10,
                  weight: 30,
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AnanyaFinanceKPI;
