import React, { useState } from 'react';
import '../Dashboard/dashboard.css';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import ProspectIcon from '../Dashboard/Images/ProspectIcon.svg';
import SanctionIcon from '../Dashboard/Images/SanctionIcon.svg';
import DisbursalIcon from '../Dashboard/Images/DisbuesalIcon.svg';
import ClientIcon from '../Dashboard/Images/client.svg';
import TransactionIcon from '../Dashboard/Images/transaction.svg';
import TotalDisbursed from '../Dashboard/Images/Total Disbursed.svg';
import AvilableIcons from '../Dashboard/Images/AvilableRecordsIcon.svg';
import Outstanding from '../Dashboard/Images/Outstanding.svg';
import ClientTabel from '../Client Management/ClientTabel';
import OrderManagementTabel from '../Order Management/OrderManagementTabel';
import ReactApexChart from 'react-apexcharts';
import { useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Loader from '../common/Loader';
import { Prev } from 'react-bootstrap/esm/PageItem';
import HomeData from '../common/HomeData';

function Dashboard({ isOpen, refreshHandler }) {
  const navigator = useNavigate();
  const [filter, setFilter] = React.useState('Lending to Ayekart');
  const [dataType, setDataType] = useState('order_based');
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dashboardCount, setDashboardCount] = useState();
  const [fytdByDate, setFytdByDate] = useState();
  const [data, setData] = useState(null);
  const [isFlag, setIsFlag] = useState(false);

  const formatCurrency = (value) => {
    let number = parseFloat(value);
    if (isNaN(number)) {
      return value;
    }
    if (number / 1e7 >= 1) {
      number = (number / 1e7).toFixed(2) + ' Cr';
    } else if (number / 1e5 >= 1) {
      number = (number / 1e5).toFixed(2) + ' Lac';
    } else if (number / 1e3 >= 1) {
      number = (number / 1e3).toFixed(2) + ' K';
    }

    return number;
  };

  useEffect(() => {
    setIsFlag(!isFlag);
    refreshHandler();
  }, []);

  // ======================================= Stop the working of URL back button =======================================//
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function (event) {
    window.history.go(1);
  };
  //=======================================================  Pai chart =============================================
  const [pieState, setPieState] = useState({
    series: [],
    options: {
      chart: {
        width: 500,
        // height: "100%",
        type: 'pie',
      },
      legend: {
        show: false,
      },
      labels: ['No due', 'On time', 'Over Due'],
      colors: ['#329DC9', '#FFA63D', '#FF603D'],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            },
          },
        },
      ],
    },
  });

  // ========================================= Bar chart ================================
  const [barState, setBarState] = useState({
    series: [
      {
        name: 'Total transactions done till date',
        data: [],
      },
    ],
    options: {
      chart: {
        // width: 500,
        // height: "100%",
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false, // <--- HERE
      },
      labels: [
        'Before Time',
        'On Time',
        'Late',
        '30 + DPD',
        '60 + DPD',
        '90 + DPD',
      ],
      colors: ['#2A9090', '#045656', '#0C3094'],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              // width: 500,
            },
          },
        },
      ],
    },
  });

  //=================Calculate amount in Indian format====================//
  function amountFormat(x, defaultValue = 0) {
    if (!x) {
      return defaultValue;
    }
    x = x.toString();
    var afterPoint = '';
    if (x?.indexOf('.') > 0)
      afterPoint = x.substring(x?.indexOf('.'), x?.length);
    x = Math.floor(x);
    x = x?.toString();
    var lastThree = x?.substring(x?.length - 3);
    var otherNumbers = x?.substring(0, x?.length - 3);
    if (otherNumbers != '') lastThree = ',' + lastThree;
    var res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
      lastThree +
      afterPoint;
    return res;
  }

  // ========================= Get Pei chart value ================================
  const fetchPeiChartData = async () => {
    // const response = await axios.get(`/api/merchant//users/kpis`, {
    const response = await axios.get(`/api/merchant//users/kpis`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (response && response?.data) {
      // console.log(response?.data, "new pie");
      // const arr = [];
      // arr.push(Number(response?.data?.paid_before_time));
      // arr.push(Number(response?.data?.paid_on_time));
      // arr.push(Number(response?.data?.overdue));
      // setPieState({
      //   ...pieState,
      //   series: [...arr],
      // });
    }
  };

  const getBarData = async () => {
    const response = await axios.get(`/api/home/prospects`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (response?.data) {
      const arr = [];
      const arr1 = [];
      arr.push(
        Number(
          response?.data?.repayment_bar_chart?.before_time_repayment[0]
            ?.paid_before_time
        )
      );
      arr1.push(
        Number(
          response?.data?.repayment_bar_chart?.before_time_repayment[0]
            ?.paid_before_time
        )
      );
      arr.push(
        Number(
          response?.data?.repayment_bar_chart?.on_time_repayment[0]
            ?.paid_on_time
        )
      );
      arr1.push(
        Number(
          response?.data?.repayment_bar_chart?.on_time_repayment[0]
            ?.paid_on_time
        )
      );
      arr.push(
        Number(
          response?.data?.repayment_bar_chart?.late_repayment[0]
            ?.paid_before_time
        )
      );
      arr1.push(
        Number(
          response?.data?.repayment_bar_chart?.late_repayment[0]
            ?.paid_before_time
        )
      );
      arr.push(
        Number(
          response?.data?.repayment_bar_chart?.thirty_dpd_repayment[0]
            ?.paid_30_dpd
        )
      );
      arr.push(
        Number(
          response?.data?.repayment_bar_chart?.sixty_dpd_repayment[0]
            ?.paid_60_dpd
        )
      );
      arr.push(
        Number(
          response?.data?.repayment_bar_chart?.ninety_dpd_repayment[0]
            ?.paid_90_dpd
        )
      );
      setPieState({
        ...pieState,
        series: [...arr1],
      });
      // setBarState({ ...barState, series: { data: [...arr] } });
      setBarState((Prev) => ({
        ...Prev,
        series: [{ ...Prev.series[0], data: [...arr] }],
      }));
    }
  };

  //==================================== get count API =========================================================
  const fetchDashboardCount = async () => {
    try {
      const response = await axios.get(`/api/home/dashboard_card_view`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status) {
        setData(response.data.data[0]);
      }
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
    }
  };

  const fetchFytdBydata = async () => {
    if (fromDate && toDate) {
      const response = await axios.get(
        `/api/home/fytd_disbursal?from_date=${fromDate}&to_date=${toDate}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        setFytdByDate(response.data);
      }
    } else {
      const response = await axios.get(`/api/home/fytd_disbursal`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      if (response?.data) {
        setFytdByDate(response.data);
      }
    }
  };

  const token = localStorage.getItem('token');
  useEffect(() => {
    fetchPeiChartData();
    fetchDashboardCount();
    getBarData();
  }, []);

  useEffect(() => {
    fetchFytdBydata();
  }, [fromDate, toDate]);

  return (
    <div
      className={
        isOpen
          ? 'dashboard-page-with-sidebar  container-fluid pe-0 me-5 '
          : 'dashboard-page  container-fluid pe-0 me-2'
      }
      style={{ paddingTop: '80px' }}
    >
      {/* {loading ? (
        <Loader />
      ) : ( */}
      <div>
        <div className="d-flex justify-content-between">
          <div className="text-3xl font-bold">
            <h2 style={{ color: '#0C3094' }}>Dashboard</h2>
          </div>
          <div className="p-1">
            <FormControl
              sx={{
                m: 1,
                minWidth: 220,
                '& fieldset': { border: 'none' },
              }}
              size="small"
            >
              <Select
                className="border-0 bg-white rounded-2xl shadow-md"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter}
                onChange={(e) => handleFilterChange(e)}
                sx={{
                  border: 'none',
                }}
              >
                <MenuItem value="Lending to Ayekart">
                  Lending to Ayekart
                </MenuItem>
                <MenuItem value="FPO ( Pre- Shipment Finance)">
                  {' '}
                  FPO ( Pre- Shipment Finance)
                </MenuItem>
                <MenuItem value=" MSME Order Funding (Reverse Factoring)">
                  {' '}
                  MSME Order Funding (Reverse Factoring)
                </MenuItem>
                <MenuItem value="Ayekart Invoice Discounting">
                  Ayekart Invoice Discounting
                </MenuItem>
                <MenuItem value="Retailer Financing (Anchor Distribution)">
                  Retailer Financing (Anchor Distribution)
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {/* <div className="d-flex justify-content-between mt-3">
        
        </div> */}
        <div className="mt-2 row mx-0 ">
          <div
            className="col col-md-2 col-sm-2 col-lg-2 p-0 ps-3 "
            role="button"
            // onClick={() => navigator('/clientManagement/prospect')}
          >
            <div className="prospects-box p-3">
              <div className="d-flex justify-content-between gap-3">
                {' '}
                <h5 style={{ color: '#1C6C8C' }} className="fw-bolder">
                  {' '}
                  Limit’s Alloted
                </h5>
                <img src={ProspectIcon} alt="" />{' '}
              </div>
              <div className="overflow-auto text-lg font-bold">
                ₹ {data ? formatCurrency(data.limit_alloted) : <HomeData />}
              </div>
            </div>
          </div>
          <div
            className="col col-md-2 col-sm-2 col-lg-2 ps-3 p-0"
            role="button"
            // onClick={() => navigator('/clientManagement/inProgress')}
          >
            <div className="Sanctions-box p-3">
              <div className="d-flex justify-content-between gap-3">
                {' '}
                <h5 style={{ color: '#A91818' }} className="fw-bolder">
                  {' '}
                  Total Disbursed
                </h5>
                <img src={TotalDisbursed} alt="" />{' '}
              </div>
              <div className="overflow-auto text-lg font-bold">
                {' '}
                ₹ {data ? formatCurrency(data.total_disbursed) : <HomeData />}
              </div>
            </div>
          </div>
          <div
            className="col col-md-2 col-sm-2 col-lg-2 ps-3 p-0"
            role="button"
          >
            <div className="Disbursal-box p-3">
              <div className="d-flex justify-content-between gap-3">
                {' '}
                <h5 style={{ color: '#183CA1' }} className="fw-bolder">
                  {' '}
                  Current Utilised
                </h5>
                <img src={DisbursalIcon} alt="" />{' '}
              </div>
              <div className="overflow-auto text-lg font-bold">
                {' '}
                ₹ {data ? formatCurrency(data.current_utilised) : <HomeData />}
              </div>
            </div>{' '}
          </div>
          <div
            className="col col-md-2 col-sm-2 col-lg-2 ps-3 p-0"
            role="button"
            // onClick={() => navigator('/orderManagement/available')}
          >
            <div className="AvailableOrders-box p-3">
              <div className="d-flex justify-content-between gap-2">
                {' '}
                <h5 style={{ color: '#774407' }} className="fw-bolder">
                  {' '}
                  Total Repayment
                </h5>
                <img src={SanctionIcon} alt="" />{' '}
              </div>
              <div className="overflow-auto text-lg font-bold">
                {' '}
                ₹ {data ? formatCurrency(data.total_repayment) : <HomeData />}
              </div>
            </div>{' '}
          </div>
          <div
            className="col col-md-2 col-sm-2 col-lg-2 ps-3 p-0"
            role="button"
            onClick={() => navigator('/orderManagement/available')}
          >
            <div className="OutstandingOrders-box p-3">
              <div className="d-flex justify-content-between ">
                {' '}
                <h5 style={{ color: '#0D2A7E' }} className="fw-bolder">
                  {' '}
                  Outstanding
                </h5>
                <img src={Outstanding} alt="" />{' '}
              </div>
              <div className="overflow-auto text-lg font-bold">
                {' '}
                ₹ {data ? formatCurrency(data.outstanding) : <HomeData />}
              </div>
            </div>{' '}
          </div>
          <div
            className="col col-md-2 col-sm-2 col-lg-2 ps-3 p-0"
            role="button"
          >
            <div className="Total-RepOrders-box  p-3">
              <div className="d-flex justify-content-between gap-3">
                {' '}
                <h5 style={{ color: '#834C0B' }} className="fw-bolder">
                  {' '}
                  Available Limit
                </h5>
                <img src={AvilableIcons} alt="" />{' '}
              </div>
              <div className="overflow-auto text-lg font-bold">
                {' '}
                ₹ {data ? formatCurrency(data.available_limit) : <HomeData />}
              </div>
            </div>{' '}
          </div>
        </div>
        <div className="mb-4 mt-4 space-x-5 ml-3">
          <TextField
            sx={{
              '& fieldset': { border: 'none' },
            }}
            className="border-0 bg-white rounded-2xl shadow-md"
            id="from-date"
            type="date"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
          />
          <TextField
            sx={{
              '& fieldset': { border: 'none' },
            }}
            className="border-0 bg-white rounded-2xl shadow-md"
            id="to-date"
            type="date"
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
          />
          {fromDate && toDate ? (
            <Button
              variant="outlined"
              className="mt-2 rounded-lg shadow-md text-lg font-bold"
              onClick={() => {
                setFromDate('');
                setToDate('');
              }}
            >
              Clear date
            </Button>
          ) : (
            ''
          )}
        </div>

        <div className="mt-4 row mx-0">
          <div className="col col-md-4 col-sm-4 col-lg-4 p-2">
            <div
              className="fytd-box p-4"
              role="button"
              // onClick={() => navigator("/orderManagement/disbursed")}
            >
              <h5 className="text-xl font-bold">Disbursal FYTD</h5>
              <div className="fytd-value-bg text-center mt-5">
                <div
                  className="text-3xl font-bold p-2"
                  style={{
                    background:
                      'linear-gradient(272.84deg, #0C3094 6.58%, #A2B4E6 91.71%)',
                    color: '#fff',
                    borderRadius: '12px',
                  }}
                >
                  {' '}
                  ₹ {amountFormat(fytdByDate?.fytd_disbursal)}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-5">
                <div className="p-3 border-solid border-1 bg-gray-300 border-gray-300 rounded-lg shadow-lg">
                  <div className="flex items-center">
                    <img className="mr-2" src={ClientIcon} alt="" />
                    <span className="text-sm font-bold">Clients</span>
                  </div>
                  <div className="mt-5 mb-4 p-2 text-center border-solid bg-white border-1 shadow-lg border-gray-300 rounded-lg">
                    <div className="font-semibold">
                      {amountFormat(fytdByDate?.clients_count)}
                    </div>
                  </div>
                </div>
                <div className="p-3 border-solid border-1 bg-gray-300 border-gray-300 rounded-lg shadow-lg">
                  <div className="flex items-center">
                    <img className="mr-2" src={TransactionIcon} alt="" />
                    <span className="text-sm font-bold ">Transactions</span>
                  </div>
                  <div className="mt-5 mb-4 p-2 text-center border-solid bg-white border-1 shadow-lg border-gray-300 rounded-lg">
                    <div className="font-semibold">
                      {amountFormat(fytdByDate?.transaction_counts)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md-8 col-sm-8 col-lg-8 p-2 ps-4">
            <div className="fytd-box p-4" role="button">
              <h5 className="text-xl font-bold">Repayment</h5>

              <div className="row mt-3 repayment-box">
                <div className="col col-md-6 col-sm-6 col-lg-6">
                  {pieState ? (
                    <div className="">
                      <div className="chart">
                        <ReactApexChart
                          options={pieState.options}
                          series={pieState.series}
                          type="pie"
                          // height={290}
                        />
                        <div className="row mt-2 pb-2">
                          <div className="d-flex justify-content-center">
                            <div className="d-flex gap-2 mt-2">
                              <div
                                className="legend-color-box"
                                style={{ backgroundColor: '#329DC9' }}
                              ></div>
                              <div className="me-2 text-sm font-semibold">
                                {' '}
                                Before Time
                              </div>
                            </div>
                            <div className="d-flex mt-2 gap-2">
                              <div
                                className="legend-color-box ms-2"
                                style={{ backgroundColor: '#FFA63D' }}
                              ></div>
                              <div className="me-2 text-sm font-semibold">
                                {' '}
                                On time
                              </div>
                            </div>
                            <div className="d-flex mt-2 gap-2">
                              <div
                                className="legend-color-box ms-2"
                                style={{ backgroundColor: '#FF603D' }}
                              ></div>
                              <div className="text-sm font-semibold">
                                Over Due
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <HomeData />
                  )}
                </div>
                <div className="col col-md-6 col-sm-6 col-lg-6">
                  <div className="">
                    {barState ? (
                      <div className="chart">
                        <ReactApexChart
                          options={barState.options}
                          series={barState.series}
                          type="bar"
                          height={300}
                        />
                      </div>
                    ) : (
                      <HomeData />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between m-2 mt-4">
          <div className="text-xl font-bold">Available Prospects</div>
          <div>
            <FormControl className="">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => {
                  setDataType(e.target.value);
                }}
                value={dataType}
              >
                <FormControlLabel
                  value="order_based"
                  control={<Radio />}
                  label="Order-Based"
                />
                <FormControlLabel
                  value="client-based"
                  control={<Radio />}
                  label="Client-Based"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        {dataType === 'order_based' ? (
          <div>
            {/* <TableContainer className=" mt-4">
              <Table size="large" aria-label="a dense table">
                <TableHead
                  style={{
                    backgroundColor: "#fff",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "12px",
                  }}
                >
                  <TableRow
                    className="pt-4 pb-4"
                    style={{ borderRadius: "5px" }}
                  >
                    <TableCell className="fw-bolder pt-3 pb-3">
                      Order Date
                    </TableCell>
                    <TableCell align="center" className="fw-bolder">
                      Order ID
                    </TableCell>
                    <TableCell align="center" className="fw-bolder">
                      Party
                    </TableCell>
                    <TableCell align="center" className="fw-bolder">
                      Commodity
                    </TableCell>
                    <TableCell align="center" className="fw-bolder">
                      Quantity
                    </TableCell>
                    <TableCell align="center" className="fw-bolder">
                      Total Value
                    </TableCell>
                    <TableCell align="center" className="fw-bolder">
                      Advanced Payment Amt
                    </TableCell>
                    <TableCell align="center" className="fw-bolder">
                      Tenure Requested (days)
                    </TableCell>
                    <TableCell align="center" className="fw-bolder">
                      Limit Available
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer> */}
            <OrderManagementTabel
              tabValue="opportunity_orders"
              filtervalue="available"
              search=""
            />
          </div>
        ) : (
          ''
        )}
        {dataType === 'client-based' ? (
          <>
            <ClientTabel
              tabValue="prospects_orders"
              filtervalue="available"
              search=""
            />
          </>
        ) : (
          // <div>
          //   <TableContainer className=" mt-4">
          //     <Table size="large" aria-label="a dense table">
          //       <TableHead
          //         style={{
          //           backgroundColor: '#fff',
          //           boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          //           borderRadius: '12px',
          //         }}
          //       >
          //         <TableRow
          //           className="pt-4 pb-4"
          //           style={{ borderRadius: '5px' }}
          //         >
          //           <TableCell className="fw-bolder pt-3 pb-3">
          //             Login Date
          //           </TableCell>
          //           <TableCell align="center" className="fw-bolder">
          //             Partner name
          //           </TableCell>
          //           <TableCell align="center" className="fw-bolder">
          //             Party Region
          //           </TableCell>
          //           <TableCell align="center" className="fw-bolder">
          //             Business Nature
          //           </TableCell>
          //           <TableCell align="center" className="fw-bolder">
          //             Business Type
          //           </TableCell>
          //           <TableCell align="center" className="fw-bolder">
          //             GST Turn over
          //           </TableCell>
          //           <TableCell align="center" className="fw-bolder">
          //             Vintage (in yrs)
          //           </TableCell>
          //           <TableCell align="center" className="fw-bolder">
          //             Age on Network
          //           </TableCell>
          //         </TableRow>
          //       </TableHead>
          //     </Table>
          //   </TableContainer>
          // </div>
          ''
        )}
      </div>
    </div>
  );
}

export default Dashboard;
