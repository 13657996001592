import '../acess/css/order-trackers-details.css';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import imgIcon from '../acess/images/layer-1-copy-5.png';
import fileIcon from '../acess/images/layer-1-copy-16.png';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import DocModel from '../pages/DocModel';
import ImageModel from '../pages/ImageModel';
import { CleaningServices } from '@mui/icons-material';
import Loader from './common/Loader';

const FileDownloadIcon = ({ isImage = false, onClick }) => {
  return (
    <CardContent className="text-center mt-2">
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        onClick={typeof onClick === 'function' ? onClick : () => {}}
      >
        <img
          style={{ cursor: 'pointer' }}
          src={isImage ? imgIcon : fileIcon}
          alt=""
        />
      </Typography>
    </CardContent>
  );
};

const useStyles = makeStyles((Theme) =>
  createStyles({
    root: {
      width: '80%',
    },
    button: {
      marginTop: Theme.spacing(1),
      marginRight: Theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: Theme.spacing(2),
    },
    resetContainer: {
      padding: Theme.spacing(3),
    },
  })
);

function getSteps() {
  return [
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell className="fw-bolder">Order Placed</TableCell>
              <TableCell align="right" sx={{ color: '#4363a4' }}>
                10 Jan 2023 9:45 AM
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                {' '}
                <span className="fw-bolder">Level 1</span>
                <div>
                  {' '}
                  Approved the request for SCF and assigned a tenure of 30 Days
                </div>
              </TableCell>
              <TableCell align="right" sx={{ color: '#4363a4' }}>
                {/* <CheckIcon />  */}
                Approved Ashutosh Singh 10 Jan 2023 01:57 PM
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                <span className="fw-bolder">Level 2</span>
                <div> Approved the request for SCF</div>
              </TableCell>
              <TableCell align="right" sx={{ color: '#4363a4' }}>
                Approved Debarshri Dutta 10 Jan 2023 2:30 PM
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                <span className="fw-bolder"> Operations</span>
                <div>Supporting Document Upload Confirmation -- Pending</div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                {' '}
                <span className="fw-bolder"> Operations</span>
                <div>
                  Confirmation on Amount Disbursal from Partner --Pending
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                {' '}
                <span className="fw-bolder"> Finance</span>
                <div>Amount details to be send via Email --Pending</div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                {' '}
                <span className="fw-bolder"> Finance</span>
                <div>Amount Paid to Supplier --- Confirmation Pending!!!</div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                {' '}
                <span className="fw-bolder"> Finance</span>
                <div>Amount Pending for Collection</div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                {' '}
                <span className="fw-bolder"> Operations</span>
                <div>Amount Collected --- Confirmation Pending!!!</div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              {' '}
              <TableCell>
                {' '}
                <span className="fw-bolder"> Operations</span>
                <div>Amount Collected --- Confirmation Pending!!!</div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0 ">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
              className="text-decoration-none"
            >
              <TableCell>
                {' '}
                <span className="fw-bolder">Finance</span>
                <div>Amount Transfer to NBFC --- Confirmation Pending!!!</div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
    <div className="step">
      <TableContainer component={Paper} className="m-0 p-0">
        <Table size="small" aria-label="a dense table" className="container">
          <TableHead style={{ backgroundColor: '#f0f3f8' }}>
            <TableRow
              style={{
                border: 'none',
                fontWeight: 'bolder',
                color: 'black',
                margin: '0 0 10px 0',
              }}
              sx={{ m: 5 }}
            >
              <TableCell>
                {' '}
                <span className="fw-bolder">Process Not Completed</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </div>,
  ];
}

function ProspectiveInvoicedetails({ isOpen }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [imgAddress, setImgAddress] = useState();
  const [imgName, setImgName] = useState();
  const isStepFailed = (step) => step === 1;
  const [orderDetails, setOrderDetails] = useState();
  const steps = getSteps();
  const [SCFTotal, setSCFTotal] = useState(0);
  const [docAddress, setDocAddress] = useState();
  const [docTitle, setDocTitle] = useState();
  const handleClose = () => setOpen(false);
  const handleImgClose = () => setOpenImg(false);
  const [facilitatorName, setFacilitatorName] = useState();
  const [loading, setLoading] = useState(true);
  const [shipmentDetails, setShipmentDetails] = useState([]);
  const [poBosDoc, setOpBosDoc] = useState();
  const [orderProductsData, setOrderProductsData] = useState([]);

  // Get order details by id
  const token = localStorage.getItem('token');
  useEffect(() => {
    axios
      .get(`/api/orders/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response?.data?.message === 'JSON Web Token Is Expired, Try Again')
          navigate('/');
        {
          setOrderProductsData(response?.data?.singleview_products);
          setOrderDetails(response?.data?.single_order[0]);
          setSCFTotal(Number(response.data.sourcing_total_sum[0].sum));
          setOpBosDoc(response?.data?.po_bos_doc_data[0]);
          setShipmentDetails(response?.data?.order_shipment_docs);
          setFacilitatorName(response?.data?.ayekart_only_bnpl);
        }
        setLoading(false);
      })
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  //Check URL
  function isPDForJPEG(url) {
    return url.endsWith('.pdf');
  }
  const handlePODoc = () => {
    if (!poBosDoc || !poBosDoc?.po_image_pdf_url) {
      return;
    }
    if (isPDForJPEG(poBosDoc?.po_image_pdf_url)) setOpen(true);
    setDocAddress(poBosDoc?.po_image_pdf_url);
    setDocTitle('PO File');
  };

  const handleBOSDoc = () => {
    if (!poBosDoc || !poBosDoc?.bos_image_pdf_url) {
      return;
    }
    setOpen(true);
    setDocAddress(poBosDoc?.bos_image_pdf_url);

    setDocTitle('BOS File');
  };

  const invoiceHandler = () => {
    if (!poBosDoc || !poBosDoc?.invoice_image_pdf_url) {
      return;
    }
    setOpen(true);
    setDocAddress(poBosDoc?.invoice_image_pdf_url);
    setDocTitle('BOS File');
  };

  const lrReceiptHandler = (item) => {
    if (!item || !item?.lr_receipt_url) {
      return;
    }

    if (isPDForJPEG(item?.lr_receipt_url)) {
      setOpen(true);
      setDocAddress(item?.lr_receipt_url);
      setDocTitle('LR Receipt');
    } else setOpenImg(true);
    setImgAddress(item?.lr_receipt_url);
    setImgName('LR Receipt');
  };

  const lorryImgHandler = (item) => {
    if (!item || !item?.lorry_image_url) {
      return;
    }
    if (isPDForJPEG(item?.lorry_image_url)) {
      setOpen(true);
      setDocAddress(item?.lorry_image_url);
      setDocTitle('Lorry Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.lorry_image_url);
      setImgName('Lorry Image');
    }
  };

  const truckLoadingImgHandler = (item) => {
    if (!item || !item?.truck_loading_image_url) {
      return;
    }
    if (isPDForJPEG(item?.truck_loading_image_url)) {
      setOpen(true);
      setDocAddress(item?.truck_loading_image_url);
      setDocTitle('Truck Loading Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.truck_loading_image_url);
      setImgName('Truck Loading Image');
    }
  };

  const kataParchiImgHandler = (item) => {
    if (!item || !item?.kata_parchi_image_url) {
      return;
    }
    if (isPDForJPEG(item?.kata_parchi_image_url)) {
      setOpen(true);
      setDocAddress(item?.kata_parchi_image_url);
      setDocTitle('Kata Parchi Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.kata_parchi_image_url);
      setImgName('Kata Parchi Image');
    }
  };

  const biltyImgHandler = (item) => {
    if (!item || !item?.bilty_image_url) {
      return;
    }
    if (isPDForJPEG(item?.bilty_image_url)) {
      setOpen(true);
      setDocAddress(item?.bilty_image_url);
      setDocTitle('Bilty Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.bilty_image_url);
      setImgName('Bilty Image');
    }
  };

  const customerAcceptanceImgHandler = (item) => {
    if (!item || !item?.customer_acceptance_note_url) {
      return;
    }
    if (isPDForJPEG(item?.customer_acceptance_note_url)) {
      setOpen(true);
      setDocAddress(item?.customer_acceptance_note_url);
      setDocTitle('Customer Acceptance Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.customer_acceptance_note_url);
      setImgName('Customer Acceptance Image');
    }
  };

  const challanImgHandler = (item) => {
    if (!item || !item?.challan_image_url) {
      return;
    }

    if (isPDForJPEG(item?.challan_image_url)) {
      setOpen(true);
      setDocAddress(item?.challan_image_url);
      setDocTitle('Challan Acceptance Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.challan_image_url);
      setImgName('Challan Acceptance Image');
    }
  };

  const InvoiceImgHandler = (item) => {
    if (!item || !item?.invoice_image_pdf_url) {
      return;
    }

    if (isPDForJPEG(item?.invoice_image_pdf_url)) {
      setOpen(true);
      setDocAddress(item?.invoice_image_pdf_url);
      setDocTitle('Invoice Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.invoice_image_pdf_url);
      setImgName('Invoice Image');
    }
  };

  const deliveryReceiptImgHandler = (item) => {
    if (!item || !item?.delivery_receipt_image_url) {
      return;
    }

    if (isPDForJPEG(item?.delivery_receipt_image_url)) {
      setOpen(true);
      setDocAddress(item?.delivery_receipt_image_url);
      setDocTitle('Delivery Receipt Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.delivery_receipt_image_url);
      setImgName('Delivery Receipt Image');
    }
  };

  const kataParchideliveryImgHandler = (item) => {
    if (!item || !item?.kata_parchi_delivery_image_url) {
      return;
    }
    if (isPDForJPEG(item?.kata_parchi_delivery_image_url)) {
      setOpen(true);
      setDocAddress(item?.kata_parchi_delivery_image_url);
      setDocTitle('Kata Parchi Delivery  Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.kata_parchi_delivery_image_url);
      setImgName('Kata Parchi Delivery  Image');
    }
  };

  const kataParchiShipmentImgHandler = (item) => {
    if (!item || !item?.kata_parchi_shipment_date_image_url) {
      return;
    }
    if (isPDForJPEG(item?.kata_parchi_shipment_date_image_url)) {
      setOpen(true);
      setDocAddress(item?.kata_parchi_shipment_date_image_url);
      setDocTitle('Kata Parchi Shipment  Image');
    } else {
      setOpenImg(true);
      setImgAddress(item?.kata_parchi_shipment_date_image_url);
      setImgName('Kata Parchi Shipment  Image');
    }
  };

  const backHandler = () => {
    navigate('/AnanyaFinance/prospectiveInvoices');
  };

  return (
    <div
      style={{ paddingTop: '80px' }}
      className={
        isOpen
          ? 'active-page-with-sidebar-order-tracker container '
          : 'active-page-order-tracker-details container'
      }
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between p-0 m-0 mx-0">
            <div className="order-tracker-details-path">
              {'Order Tracker > Order Details'}
            </div>
            <Button
              className="btn btn-primary "
              variant="contained"
              onClick={backHandler}
            >
              Back
            </Button>
          </div>

          <div className="buyer-details-box  row mx-0">
            <div className="col col-12 col-md-4">
              <span className="buyer-box-title">Buyer Details</span>

              <div className="buyer-box-sub-title mt-1">
                {orderDetails?.buyer_shop_name}
              </div>

              <div className="buyer-box-sub-title mt-1">
                {orderDetails?.buyer_city} {', '}
                {orderDetails?.buyer_state}
                {'- '}
                {orderDetails?.buyer_pincode}
              </div>
              <div className="buyer-box-sub-title mt-1">
                Category : {orderDetails?.buyer_category_name}
              </div>
            </div>
            <div className="col col-12 col-md-4">
              <span className="buyer-box-title">Seller Details</span>
              <div className="buyer-box-sub-title mt-2">
                {orderDetails?.seller_first_name}{' '}
                {orderDetails?.seller_last_name}
              </div>
              <div className="buyer-box-sub-title mt-1">
                Ayekart Fintech Private Limited Mumbai,Maharashtra-400097
              </div>
              <div className="buyer-box-sub-title mt-1">
                Category : {orderDetails?.seller_category_name}
              </div>
            </div>
            <div className="col col-12 col-md-4">
              <span className="buyer-box-title">Order Details</span>
              <div className="buyer-box-sub-title mt-2">
                <span className="fw-bolder"> Order ID :</span>{' '}
                {orderDetails?.order_id}
              </div>
              <div className="buyer-box-sub-title mt-1">
                <span className="fw-bolder"> Ordered Date :</span>{' '}
                {moment(orderDetails?.ordered_date).format('llll')}
              </div>
              <div className="buyer-box-sub-title mt-1">
                <span className="fw-bolder"> Payment Method :</span>{' '}
                {orderDetails?.payment_method}
              </div>
              <div className="buyer-box-sub-title mt-1">
                <span className="fw-bolder"> Facilitator Name: </span>
                {facilitatorName?.lending_partner}
              </div>
              <div className="buyer-box-sub-title mt-1">
                <span className="fw-bolder"> Collection Amount: </span>
                {facilitatorName?.paid_amount}
              </div>
              <div className="buyer-box-sub-title mt-1">
                <span className="fw-bolder"> Collection Status : </span>
                {facilitatorName?.collection_status}
              </div>
              <div className="buyer-box-sub-title mt-1">
                <span className="fw-bolder"> Advance Amount :</span>{' '}
                {facilitatorName?.advance_paid_amount}
              </div>
            </div>
          </div>

          <div className="buyer-details-box container ">
            <div className="d-flex gap-2">
              <span style={{ display: 'block' }}>
                {orderDetails?.buyer_shop_name}
              </span>{' '}
              <span className="Ordered-Products-title">
                {' '}
                Ordered Products ({orderProductsData?.length})
              </span>
            </div>

            <TableContainer component={Paper}>
              <Table size="large" aria-label="a dense table">
                <TableHead style={{ backgroundColor: '#d1e0fe' }}>
                  <TableRow
                    style={{
                      border: 'none',
                      fontWeight: 'bolder',
                      color: 'black',
                      margin: '0 0 10px 0',
                    }}
                    sx={{ m: 5 }}
                  >
                    <TableCell align="center">
                      <b>Product</b>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <b>Qty</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Measurement Unit</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderProductsData?.map((row, index) => (
                    <TableRow
                      style={{ backgroundColor: '#f4f7fd' }}
                      key={index}
                    >
                      <TableCell align="center">{row.product_name}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell component="th" scope="row">
                        {row.ordered_quantity}
                      </TableCell>

                      <TableCell align="center">
                        {row.measurement_unit}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {poBosDoc === undefined ? (
            ''
          ) : (
            <div
              style={{
                boxShadow: '0 0 3.4px 0.1px rgba(48, 82, 152, 0.18)',
                padding: '0 0 30px 0',
                borderRadius: '10px',
              }}
            >
              {poBosDoc === undefined ? (
                ''
              ) : (
                <div className="po-bill-invoice">
                  <span className="po-bill-invoice-title">
                    {' '}
                    PO Bill of Supply & Invoice
                  </span>
                </div>
              )}

              {poBosDoc === undefined ? (
                ''
              ) : (
                <div className="d-flex gap-3" style={{ paddingLeft: '20px' }}>
                  {poBosDoc?.po_image_pdf_url && (
                    <Card
                      sx={{
                        width: 200,
                        height: 200,
                        mt: 2,
                        border: '1px solid #e3e8e5',
                      }}
                    >
                      <CardMedia
                        image="/static/images/cards/contemplative-reptile.jpg"
                        title="green iguana"
                      />
                      <FileDownloadIcon onClick={handlePODoc} />
                      <CardActions className="d-flex justify-content-center">
                        <Typography
                          gutterBottom
                          component="div"
                          className="card-footer-title"
                        >
                          PO Image/File
                        </Typography>
                        <br />
                      </CardActions>
                      {poBosDoc?.po_image_pdf_url === null ? (
                        <div className="d-flex justify-content-center">
                          <div className="unuploaded-doc">Upload pending</div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="uploaded-doc">Uploaded</div>
                        </div>
                      )}
                    </Card>
                  )}

                  {poBosDoc?.bos_image_pdf_url && (
                    <Card
                      sx={{
                        width: 200,
                        height: 200,
                        mt: 2,
                        border: '1px solid #e3e8e5',
                      }}
                    >
                      <CardMedia
                        image="/static/images/cards/contemplative-reptile.jpg"
                        title="green iguana"
                      />
                      <FileDownloadIcon onClick={handleBOSDoc} />
                      <CardActions className="d-flex justify-content-center">
                        <Typography
                          gutterBottom
                          component="div"
                          className="card-footer-title"
                        >
                          BOS Image/File
                        </Typography>
                        <br />
                      </CardActions>
                      {poBosDoc?.bos_image_pdf_url == null ? (
                        <div className="d-flex justify-content-center">
                          <div className="unuploaded-doc">Upload pending</div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="uploaded-doc">Uploaded</div>
                        </div>
                      )}
                    </Card>
                  )}
                  {poBosDoc?.invoice_image_pdf_url && (
                    <Card
                      sx={{
                        width: 200,
                        height: 200,
                        mt: 2,
                        border: '1px solid #e3e8e5',
                      }}
                    >
                      <CardMedia
                        image="/static/images/cards/contemplative-reptile.jpg"
                        title="green iguana"
                      />
                      <FileDownloadIcon onClick={invoiceHandler} />
                      <CardActions className="d-flex justify-content-center">
                        <Typography
                          gutterBottom
                          component="div"
                          className="card-footer-title"
                        >
                          Proforma Image/File
                        </Typography>
                        <br />
                      </CardActions>
                      {poBosDoc?.invoice_image_pdf_url == null ? (
                        <div className="d-flex justify-content-center">
                          <div className="unuploaded-doc"> Upload pending </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="uploaded-doc"> Uploaded </div>
                        </div>
                      )}
                    </Card>
                  )}
                </div>
              )}
            </div>
          )}
          {open ? (
            <DocModel
              open={open}
              docAddress={docAddress}
              docTitle={docTitle}
              handleClose={handleClose}
            />
          ) : (
            ''
          )}

          {shipmentDetails?.map((item, index) => {
            return (
              <div
                className="container"
                style={{
                  boxShadow: '0 0 3.4px 0.1px rgba(48, 82, 152, 0.18)',
                  padding: '0 0 30px 0',
                  borderRadius: '10px',
                  marginTop: '10px',
                }}
              >
                <div className="po-bill-invoice">
                  <span className="po-bill-invoice-title">
                    {' '}
                    Shipment# {index + 1}
                  </span>
                </div>

                <div className="row m-2 justify-content-center">
                  <div className="col col-12 col-md-3 mt-2">
                    <Card
                      sx={{
                        border: '1px solid #e3e8e5',
                        p: 0,
                      }}
                    >
                      <CardContent className="p-2 m-0 shipment-heading-color">
                        <div className=" shipmentCareTitle ">
                          Date & Time Of Shipment
                        </div>
                      </CardContent>
                      <div className="m-2">
                        {item?.date_of_shipment} {item?.time_of_shipment}
                      </div>
                    </Card>
                  </div>
                  <div className="col col-12 col-md-3 mt-2">
                    <Card
                      sx={{
                        border: '1px solid #e3e8e5',
                        p: 0,
                      }}
                    >
                      <CardContent className="p-2 m-0 shipment-heading-color">
                        <div className="shipmentCareTitle">
                          Weight Slip Details
                        </div>
                      </CardContent>

                      <div className="m-2">{item?.weight_slip_details}</div>
                    </Card>
                  </div>
                  <div className="col col-12 col-md-3 mt-2">
                    <Card
                      sx={{
                        border: '1px solid #e3e8e5',
                        p: 0,
                      }}
                    >
                      <CardContent className=" p-2 m-0 shipment-heading-color">
                        <div className="shipmentCareTitle">Truck Details</div>
                      </CardContent>
                      <div className="m-2">{item?.truck_details}</div>
                    </Card>
                  </div>
                  <div className="col col-12 col-md-3 mt-2">
                    <Card
                      sx={{
                        border: '1px solid #e3e8e5',
                        p: 0,
                      }}
                    >
                      <CardContent className=" p-2 m-0 shipment-heading-color">
                        <div className="shipmentCareTitle">Driver Details</div>
                      </CardContent>
                      <div className="m-2">{item?.driver_details === null}</div>
                    </Card>
                  </div>
                </div>
                <div className="row m-2">
                  <div className="col col-12 col-md-3 mt-2">
                    <Card
                      sx={{
                        border: '1px solid #e3e8e5',
                        p: 0,
                      }}
                    >
                      <CardContent className="  p-2 m-0 shipment-heading-color">
                        <div className=" shipmentCareTitle">
                          Relationship Manager
                        </div>
                      </CardContent>

                      <div className="m-2">
                        {item?.relationship_manager_name}
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="row m-2  ">
                  {item?.lr_receipt_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => lrReceiptHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title"
                          >
                            LR Receipt
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.lr_receipt_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}

                  {item?.lorry_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => lorryImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title"
                          >
                            Lorry Image
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.lorry_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}

                  {item?.truck_loading_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => truckLoadingImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title"
                          >
                            Truck Loading Image
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.truck_loading_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
                  {item?.kata_parchi_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => kataParchiImgHandler(item)}
                          isImage
                        />
                        <CardActions className="d-flex justify-content-center">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title"
                          >
                            Kata Parchi Image/File
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.kata_parchi_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}

                  {item?.bilty_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => biltyImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title"
                          >
                            Bilty Image
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.bilty_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}

                  {item?.customer_acceptance_note_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => customerAcceptanceImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title"
                          >
                            Customer Acceptance Note
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.customer_acceptance_note_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
                </div>

                <div className="row">
                  {item?.challan_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                          marginLeft: '15px',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => challanImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title"
                          >
                            Challan Image/File
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.challan_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}

                  {item?.invoice_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => InvoiceImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title"
                          >
                            Invoice Image/File
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.invoice_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}

                  {item?.delivery_receipt_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => deliveryReceiptImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center p-0 m-0">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title p-0"
                          >
                            Delivery Receipt Image/File
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.delivery_receipt_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}

                  {item?.kata_parchi_delivery_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => kataParchideliveryImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center p-0 m-0">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title p-0"
                          >
                            Kata Parchi Delivery Image/File
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.kata_parchi_delivery_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
                  {item?.kata_parchi_shipment_date_image_url === null ? (
                    ''
                  ) : (
                    <div className="col col-12 col-md-4 col-lg-2">
                      <Card
                        sx={{
                          width: 200,
                          height: 200,
                          mt: 2,
                          border: '1px solid #e3e8e5',
                        }}
                      >
                        <CardMedia
                          image="/static/images/cards/contemplative-reptile.jpg"
                          title="green iguana"
                        />
                        <FileDownloadIcon
                          onClick={() => kataParchiShipmentImgHandler(item)}
                        />
                        <CardActions className="d-flex justify-content-center p-0 m-0">
                          <Typography
                            gutterBottom
                            component="div"
                            className="card-footer-title p-0"
                          >
                            Kata Parchi Shipment Image/File
                          </Typography>
                          <br />
                        </CardActions>
                        {item?.kata_parchi_shipment_date_image_url === null ? (
                          <div className="d-flex justify-content-center">
                            <div className="unuploaded-doc">
                              {' '}
                              Upload pending{' '}
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div className="uploaded-doc"> Uploaded </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {/* Image show Model */}
          <ImageModel
            open={openImg}
            handleClose={handleImgClose}
            imgAddress={imgAddress}
            imgName={imgName}
          />
        </>
      )}
    </div>
  );
}

export default ProspectiveInvoicedetails;
