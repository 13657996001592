import React, { useEffect, useState } from 'react';
import '../../acess/css/merchantInsight.css';
import SearchIcon from '@mui/icons-material/Search';
import ReactApexChart from 'react-apexcharts';
import MenuIcon from '@mui/icons-material/Menu';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { Row, Col } from 'react-bootstrap';
import img1 from '../../acess/images/layer-1-copy-10.png';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import insightImg4 from '../../acess/images/merchant insights icons/Total Outstanding.gif';
import insightImg3 from '../../acess/images/merchant insights icons/Total value of On or Before-time repayment.gif';
import insightImg2 from '../../acess/images/merchant insights icons/No of orders placed.gif';
import insightImg1 from '../../acess/images/merchant insights icons/Total transactions done till date.gif';
import insightImg5 from '../../acess/images/merchant insights icons/Total value of payments after 30 days.gif';
import insightImg6 from '../../acess/images/merchant insights icons/Average days of payments after 30 days.gif';
import { Button } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { CleaningServices } from '@mui/icons-material';
import Loader from '../../components/common/Loader';
const CHART_HEIGHT = 472;
const LEGEND_HEIGHT = 372;
const CHART_WIDTH = 450;
const ChartWrapperStyle = styled('div')(({ theme }) => ({}));

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial',
  },
});
function ClientViewMerchantInsight({ user_id, isOpen }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [merchantInsightData, setMerchantInsightData] = useState();
  const [totalOnSetValue, setTotalOnSetValue] = useState(0);
  const [totalOutStanding, setTotalOutstanding] = useState(0);
  const [pieState, setPieState] = useState({
    series: [45, 30, 45],
    options: {
      chart: {
        type: 'pie',
      },
      legend: {
        show: false,
      },
      labels: ['Beyond 45 Days', 'Within 30 Days', '31 to 45 Days'],
      colors: ['#1787ba', '#5ce1e6', '#2bb4d4'],
      responsive: [
        {
          breakpoint: 380,
          options: {
            chart: {
              width: 400,
            },
          },
        },
      ],
    },
  });

  const [lineState, setLineState] = useState({
    series: [
      {
        name: 'Total transactions done till date',
        data: [10, 20, 37, 40],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '60%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: ['2020', '2021', '2022', '2023'],
      },
    },
  });

  const [tableData, setTableData] = useState([]);

  function rowBgColorHandler(status) {
    if (status === 'Repayment Date not set') return '#ecdfb6';
    if (status === 'Pending Payment,Missed due date') return '#c3e1b8';
    if (status === 'Paid before due date') return '#b7c4e0';
    if (status === 'Paid On Time') return '#aeb8a8';
    else return '#fff';
    return '#dec0c0';
  }

  // Get order details by  merchent id
  const token = localStorage.getItem('token');
  const [financeCount, setFinanceCount] = useState(0);
  const [count, setCount] = useState(0);
  const [kycCount, setKycCount] = useState(0);
  const [finalDoc, setFinalDoc] = useState(0);
  const [buisnessDoc, setBuisnessDoc] = useState(0);
  const [kycFactors, setKycFactors] = useState(0);
  const [kycDoc, setKycDoc] = useState(0);
  const [totalvalueAfter30Days, setTotalValueAfter30Days] = useState(0);

  useEffect(() => {
    axios
      .get(`/api/merchant/${user_id}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response?.data?.message === 'JSON Web Token Is Expired, Try Again')
          navigate('/');

        //Sort dates by order_id (sTRING)
        const a = response?.data?.dates.sort(
          (a, b) => Number(a.order_id) - Number(b.order_id)
        );

        setTableData(response?.data?.dates);
        const countArray = [];
        const labelText = [];
        response?.data?.month_on_month_transactions?.map((item) => {
          countArray.push(Number(item?.order_count));
          labelText.push(item?.month_txt);
        });
        setMerchantInsightData(response?.data);
        // setLoading(false);
        setTotalOutstanding(
          response?.data?.b2b_buy_now_pay_later_5_transactions[2]
            ? response?.data?.b2b_buy_now_pay_later_5_transactions[2][0]?.sum
            : 0
        );
        setTotalValueAfter30Days(
          response?.data?.sp_repayment_date_less_than_amount_collect_date
        );
        if (
          response?.data?.single_merchant_profile_kyc?.aadharcard_image_url !==
            null &&
          response?.data?.single_merchant_profile_kyc?.pan_card_image_url !==
            null
        ) {
          setKycCount(3);
        }
        if (
          response?.data?.single_merchant_profile_kyc?.aadharcard_image_url !==
          null
        ) {
          setKycCount(2);
        }
        if (
          response?.data?.single_merchant_profile_kyc?.pan_card_image_url !==
          null
        ) {
          setKycCount(2);
        }
        if (
          response?.data?.single_merchant_profile_kyc?.aadharcard_image_url !==
            null &&
          response?.data?.single_merchant_profile_kyc
            ?.aadharcard_image_url[0] !== null
        ) {
          setKycCount(1);
        }

        if (
          response?.data?.single_merchant_profile_kyc?.aadharcard_image_url !==
            null &&
          response?.data?.single_merchant_profile_kyc
            ?.aadharcard_image_url[1] !== null
        ) {
          setKycCount(1);
        }
        if (
          response?.data?.single_merchant_profile_kyc?.aadharcard_image_url !==
            null &&
          response?.data?.single_merchant_profile_kyc
            ?.aadharcard_image_url[1] !== null &&
          response?.data?.single_merchant_profile_kyc?.pan_card_image_url !==
            null
        ) {
          setKycCount(2);
        }

        if (
          response?.data?.single_merchant_profile_kyc?.aadharcard_image_url !==
            null &&
          response?.data?.single_merchant_profile_kyc
            ?.aadharcard_image_url[0] !== null &&
          response?.data?.single_merchant_profile_kyc?.pan_card_image_url !==
            null
        ) {
          setKycCount(2);
        }
        if (response?.data?.merchant_kyc_info[0]?.gst_image !== null) {
          setCount(count + 1);
        }
        if (response?.data?.merchant_kyc_info[0]?.business_pan !== null) {
          setCount(count + 1);
        }
        if (response?.data?.merchant_kyc_info[0]?.shop_establishment !== null) {
          setCount(count + 1);
        }
        if (response?.data?.merchant_kyc_info[0]?.panchayat !== null) {
          setCount(count + 1);
        }
        if (response?.data?.merchant_kyc_info[0]?.partne_llp !== null) {
          setCount(count + 1);
        }
        if (response?.data?.merchant_kyc_info[0]?.fssai_license !== null) {
          setCount(count + 1);
        }
        // Finance Doc Count
        if (response?.data?.single_merch_other_docs === null) {
          // return setFinalDoc(0);
        } else {
          if (response?.data?.single_merch_other_docs?.pdc_image_url !== null) {
            setFinanceCount(financeCount + 1);
          }
          if (
            response?.data?.single_merch_other_docs
              ?.cancelled_cheque_image_url !== null
          ) {
            setFinanceCount(financeCount + 1);
          }
          if (
            response?.data?.single_merch_other_docs
              ?.balance_sheet_1_image_ur !== null
          ) {
            setFinanceCount(financeCount + 1);
          }
        }
        setTotalOnSetValue(
          response?.data
            ?.total_sp_repayment_date_greater_than_amount_collected_date[0]
            ?.total_sp_amount
        );
        setKycFactors(
          response?.data?.number_of_orders_placed_and_total_trans_date
        );
        const arr = [];

        arr.push(
          response?.data?.b2b_buy_now_pay_later_5_transactions[1]
            ?.paid_before_deadline
        );
        arr.push(
          response?.data?.b2b_buy_now_pay_later_5_transactions[1]
            ?.paid_on_time_deadline
        );
        arr.push(
          response?.data?.b2b_buy_now_pay_later_5_transactions[1]
            ?.paid_delayed_deadline
        );

        setPieState({ ...pieState, series: [...arr] });
        setLineState((preve) => ({
          ...preve,
          series: [
            {
              ...preve.series[0],
              data: [...countArray],
            },
          ],
          options: {
            ...preve.options,
            xaxis: {
              ...preve.options.xaxis,
              categories: [...labelText],
            },
          },
        }));
        setLoading(false);
      })
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  const handlePaymentStatus = (v1, v2) => {
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    let first_date = new Date(v1);
    first_date.setHours(0, 0, 0, 0);

    let second_date = new Date(v2);
    second_date.setHours(0, 0, 0, 0);

    let current_date = new Date();
    current_date.setHours(0, 0, 0, 0);
    if (v1 === null) {
      return 'Repayment Date not set';
    }
    if (first_date < current_date && v2 === null) {
      return 'Pending Payment,Missed due date';
    }
    if (first_date > current_date && v2 === null) {
      return 'Pending Payment,Missed due date';
    }

    if (v1 != null && v2 !== null) {
      first_date.setDate(first_date.getDate() + 5);

      if (first_date.valueOf() == second_date.valueOf()) {
        return 'Paid On Time';
      } else if (first_date > second_date) {
        return 'Paid before due date';
      } else if (first_date < second_date) {
        return 'Delayed payment';
      }
    }
  };

  function calculateKYCValue() {
    if (kycCount === 3) {
      return 100;
    }
    if (kycCount === 2) {
      return 50;
    }
    if (kycCount === 1) {
      return 25;
    }
    if (kycCount === 0) {
      return 0;
    }
  }

  function calculatebusinessValue() {
    if (count === 6) {
      return 100;
    }
    if (count === 3) {
      return 50;
    }
    if (count > 3 && count < 6) {
      return 60;
    }
    if (count === 2 || count === 1) {
      return 25;
    }
    if (count === 0) {
      return 0;
    }
  }

  function calculateFinanceValue() {
    if (financeCount === 3) {
      return 100;
    }
    if (financeCount === 2) {
      return 50;
    }
    if (financeCount === 1) {
      return 25;
    }
    if (financeCount === 0) {
      return 0;
    }
  }

  function amountFormat(value, options) {
    let nf = new Intl.NumberFormat('en-US');

    var val = Math.abs(value);
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lac';
    } else {
      val = nf.format(value);
    }
    return val;
  }

  useEffect(() => {
    const temPerecentage = calculateKYCValue();
    setKycDoc(temPerecentage ? temPerecentage : 0);
    const calculatedBusVal = calculatebusinessValue();
    setBuisnessDoc(calculatedBusVal ? calculatedBusVal : 0);
    const calcFinanceVal = calculateFinanceValue();
    setFinalDoc(calcFinanceVal ? calcFinanceVal : 0);
  }, [loading]);

  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button
          variant="contained"
          onClick={() => navigate(`/clientManagement`)}
        >
          Back
        </Button>
      </div>
      {loading ? (
        <div style={{ height: '100vh' }}>
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col justify-center">
          <div className="d-flex justify-content-between  mt-2 p-0 m-0">
            <div className="path"></div>
            <div>
              <div className="d-flex gap-2">
                <div>
                  <div className="Vibrant-GrowCaps-Private-Limited marker mt-3">
                    {' '}
                    {merchantInsightData?.merchant_kyc_info[0]?.name}
                  </div>
                  {/*<div className="Distributor" style={{ float: "right" }}>
                    {" "}
                    {merchantInsightData?.user_category}{" "}
                  </div>*/}
                </div>

                <div>
                  <img src={img1} />
                </div>
              </div>
            </div>
          </div>

          <div className="doc-status-box container mx-5 self-center">
            <div className="fw-bolder" style={{ fontSize: '18px' }}>
              Documents Status
            </div>
            <div className="row mx-5">
              <div
                className="col col-sm-3 col-md-3 col-lg-3 col-xxl-4"
                key={'KycDocuments'}
              >
                <div className="row mt-2">
                  <div className="col">
                    <div className="d-flex gap-3">
                      <div style={{ width: '60px', height: '60px' }}>
                        <CircularProgressbar
                          value={kycDoc}
                          size={500}
                          maxValue={100}
                          text={`${kycDoc}`}
                          strokeWidth={20}
                          styles={buildStyles({
                            textColor: 'black',
                            pathColor: 'rgba(0, 143, 251, 0.85)',
                            trailColor: '#bbd2f0',
                            width: '60px',
                            text: {
                              fontSize: '20px',
                              dominantBaseline: 'middle',
                              textAnchor: 'middle',
                            },
                          })}
                        />
                      </div>
                      <div className="Documents-Status-subtitle">
                        {'KYC Documents'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col col-sm-3 col-md-3 col-lg-3 col-xxl-4"
                key={'Business Documents'}
              >
                <div className="row mt-2">
                  <div className="col">
                    <div className="d-flex gap-3">
                      <div style={{ width: '60px', height: '60px' }}>
                        <CircularProgressbar
                          value={buisnessDoc}
                          size={500}
                          maxValue={100}
                          text={`${buisnessDoc}`}
                          strokeWidth={20}
                          styles={buildStyles({
                            textColor: 'black',
                            pathColor: 'rgba(0, 143, 251, 0.85)',
                            trailColor: '#bbd2f0',
                            width: '60px',
                            fontSize: '20px',
                            dominantBaseline: 'middle',
                            textAnchor: 'middle',
                          })}
                        />
                      </div>
                      <div className="Documents-Status-subtitle">
                        {'Business Documents'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col col-sm-3 col-md-3 col-lg-3 col-xxl-4"
                key={'Finance Documents'}
              >
                <div className="row mt-2">
                  <div className="col">
                    <div className="d-flex gap-3">
                      <div style={{ width: '60px', height: '60px' }}>
                        <CircularProgressbar
                          value={finalDoc}
                          size={500}
                          maxValue={100}
                          text={`${finalDoc}`}
                          strokeWidth={20}
                          styles={buildStyles({
                            textColor: 'black',
                            pathColor: 'rgba(0, 143, 251, 0.85)',
                            trailColor: '#bbd2f0',
                            width: '60px',
                            fontSize: '20px',
                            dominantBaseline: 'middle',
                            textAnchor: 'middle',
                          })}
                        />
                      </div>
                      <div className="Documents-Status-subtitle">
                        {'Finance Documents'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <div className="pie-box-insight container">
                <div className="text-center mt-5">
                  <h5>Repayment Status</h5>
                </div>

                <div className="chart ml-4">
                  <ReactApexChart
                    options={pieState && pieState.options && pieState.options}
                    series={pieState && pieState.series && pieState.series}
                    type="pie"
                  />

                  <div className="row mt-3">
                    <div className="d-flex justify-content-center">
                      <div>
                        <div className="d-flex gap-2">
                          <div
                            className="legend-color-box align-self-center"
                            style={{ backgroundColor: '#1787ba' }}
                          ></div>
                          <span
                            className="align-self-center"
                            style={{ fontSize: '10px' }}
                          >
                            {' '}
                            Beyond 45 Days
                          </span>
                        </div>
                        <div className="d-flex mt-1 gap-2">
                          <div
                            className="legend-color-box align-self-center"
                            style={{ backgroundColor: '#5ce1e6' }}
                          ></div>
                          <span
                            className="align-self-center"
                            style={{ fontSize: '10px' }}
                          >
                            {' '}
                            Within 30 Days
                          </span>
                        </div>
                        <div className="d-flex mt-1 gap-2">
                          <div
                            className="legend-color-box align-self-center"
                            style={{ backgroundColor: '#2bb4d4' }}
                          ></div>
                          <span
                            className="align-self-center"
                            style={{ fontSize: '10px' }}
                          >
                            31 to 45 Days
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line-box container mt-3">
                <div id="chart">
                  <ReactApexChart
                    options={
                      lineState && lineState?.options && lineState?.options
                    }
                    series={lineState && lineState?.series && lineState?.series}
                    type="bar"
                  />
                  <div className="d-flex gap-2 justify-content-center">
                    <div className="labelColor align-self-center" />
                    <div
                      style={{ fontWeight: '500px', fontSize: '10px' }}
                      className="align-self-center"
                    >
                      Total transactions done till date
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row mt-1 mx-5 container align-self-start "
              style={{ backgroundColor: '#fff', borderRadius: '15px' }}
            >
              <div
                style={{
                  fontSize: '30px',
                  fontWeight: '500',
                  marginBottom: '65px',
                }}
                className=" container ms-5 self-center text-center"
              >
                Key Factors on SCF
              </div>
              <div className="d-flex">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xxl-6">
                  <div className="d-flex gap-3 mb-4">
                    <div className="icon-color-box-blue">
                      <img
                        src={insightImg1}
                        alt=""
                        style={{ width: '60px', height: '70px' }}
                      />
                    </div>
                    <div>
                      <span className="color-box-text">
                        Total transactions done till date
                      </span>
                      <div className="color-box-number">
                        ₹{' '}
                        {amountFormat(
                          kycFactors?.total_amount
                            ? kycFactors?.total_amount
                            : 0
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex gap-3 mb-4">
                    <div className="icon-color-box-blue">
                      <img
                        src={insightImg3}
                        alt=""
                        style={{ width: '60px', height: '70px' }}
                      />
                    </div>
                    <div>
                      <span className="color-box-text">
                        Total value of On or Before-time repayment
                      </span>
                      <div className="color-box-number">
                        ₹ {amountFormat(totalOnSetValue)}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex gap-3">
                    <div className="icon-color-box-blue">
                      <img
                        src={insightImg5}
                        alt=""
                        style={{ width: '60px', height: '70px' }}
                      />
                    </div>
                    <div>
                      <span className="color-box-text fw-bolder">
                        Total value of payments after 30 days
                      </span>
                      <div className="color-box-number">
                        ₹ {amountFormat(totalvalueAfter30Days?.total_sp)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xxl-6">
                  <div className="d-flex gap-3 mb-4">
                    <div className="icon-color-box-blue">
                      <img
                        src={insightImg2}
                        alt=""
                        style={{ width: '60px', height: '70px' }}
                      />
                    </div>
                    <div>
                      <span className="color-box-text">
                        No of orders placed
                      </span>
                      <div className="color-box-number">
                        {kycFactors?.order_count ? kycFactors?.order_count : 0}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex gap-3 mb-4">
                    <div className="icon-color-box-blue">
                      <img
                        src={insightImg4}
                        alt=""
                        style={{ width: '60px', height: '70px' }}
                      />
                    </div>
                    <div>
                      <span className="color-box-text">Total Outstanding</span>
                      <div className="color-box-number">
                        ₹ {totalOutStanding === null ? 0 : totalOutStanding}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex gap-3 mb-4">
                    <div className="icon-color-box-blue">
                      <img
                        src={insightImg6}
                        alt=""
                        style={{ width: '60px', height: '70px' }}
                      />
                    </div>
                    <div>
                      <span className="color-box-text">
                        Average days of payments after 30 days
                      </span>
                      <div className="color-box-number">
                        {merchantInsightData
                          ?.b2b_buy_now_pay_later_5_transactions?.length > 0 &&
                        merchantInsightData
                          ?.b2b_buy_now_pay_later_5_transactions[1] !== '' &&
                        merchantInsightData
                          ?.b2b_buy_now_pay_later_5_transactions[1] !== null
                          ? merchantInsightData
                              ?.b2b_buy_now_pay_later_5_transactions[1]
                              ?.delayed_average
                          : 0}{' '}
                        Days
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div>
            <Row>
              <Col xs={12} md={4}>
                <div className="column-content mt-2" style={{ flex: 1 }}>
                  <div className="d-flex flex-column">
                    <div className="pie-box-insight container">
                      <div className="text-center mt-5">
                        <h5>Repayment Status</h5>
                      </div>

                      <div className=" ml-4">
                        <ReactApexChart
                          options={
                            pieState && pieState.options && pieState.options
                          }
                          series={
                            pieState && pieState.series && pieState.series
                          }
                          type="pie"
                          height={'210'}
                        />

                        <div className="row mt-3 ">
                          <div className="d-flex justify-content-center">
                            <div className="d-flex gap-2">
                              <div className="d-flex gap-2">
                                <div
                                  className="legend-color-box align-self-center"
                                  style={{ backgroundColor: '#1787ba' }}
                                ></div>
                                <span
                                  className="align-self-center"
                                  style={{ fontSize: '10px' }}
                                >
                                  {' '}
                                  Beyond 45 Days
                                </span>
                              </div>
                              <div className="d-flex mt-1 gap-2">
                                <div
                                  className="legend-color-box align-self-center"
                                  style={{ backgroundColor: '#5ce1e6' }}
                                ></div>
                                <span
                                  className="align-self-center"
                                  style={{ fontSize: '10px' }}
                                >
                                  {' '}
                                  Within 30 Days
                                </span>
                              </div>
                              <div className="d-flex mt-1 gap-2">
                                <div
                                  className="legend-color-box align-self-center"
                                  style={{ backgroundColor: '#2bb4d4' }}
                                ></div>
                                <span
                                  className="align-self-center"
                                  style={{ fontSize: '10px' }}
                                >
                                  31 to 45 Days
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="line-box container mt-2 mb-0">
                      <div id="chart">
                        <ReactApexChart
                          options={
                            lineState &&
                            lineState?.options &&
                            lineState?.options
                          }
                          series={
                            lineState && lineState?.series && lineState?.series
                          }
                          type="bar"
                          height={'210'}
                        />
                        <div className="d-flex gap-2 justify-content-center">
                          <div className="labelColor align-self-center" />
                          <div
                            style={{ fontWeight: '500px', fontSize: '10px' }}
                            className="align-self-center"
                          >
                            Total transactions done till date
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={8}>
                <div className="column-content" style={{ flex: 1 }}>
                  <div
                    className="row mt-2 container align-self-start "
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '15px',
                      boxShadow: '0 0 4.3px 0.7px rgba(14, 103, 159, 0.17)',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '30px',
                        fontWeight: '500',
                        marginBottom: '65px',
                      }}
                      className=" container ms-5 mt-5 self-center text-center"
                    >
                      Key Factors on SCF
                    </div>
                    {/* <div className="d-flex mt-2">
                      <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xxl-6">
                        <div className="d-flex gap-3 mb-4">
                          <div className="icon-color-box-blue">
                            <img
                              src={insightImg1}
                              alt=""
                              style={{ width: '60px', height: '70px' }}
                            />
                          </div>
                          <div>
                            <span className="color-box-text">
                              Total transactions done till date
                            </span>
                            <div className="color-box-number">
                              ₹{' '}
                              {amountFormat(
                                kycFactors?.total_amount
                                  ? kycFactors?.total_amount
                                  : 0
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex gap-3 mb-4">
                          <div className="icon-color-box-blue">
                            <img
                              src={insightImg3}
                              alt=""
                              style={{ width: '60px', height: '70px' }}
                            />
                          </div>
                          <div>
                            <span className="color-box-text">
                              Total value of On or Before-time repayment
                            </span>
                            <div className="color-box-number">
                              ₹ {amountFormat(totalOnSetValue)}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex gap-3">
                          <div className="icon-color-box-blue">
                            <img
                              src={insightImg5}
                              alt=""
                              style={{ width: '60px', height: '70px' }}
                            />
                          </div>
                          <div>
                            <span className="color-box-text fw-bolder">
                              Total value of payments after 30 days
                            </span>
                            <div className="color-box-number">
                              ₹ {amountFormat(totalvalueAfter30Days?.total_sp)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xxl-6">
                        <div className="d-flex gap-3 mb-4">
                          <div className="icon-color-box-blue">
                            <img
                              src={insightImg2}
                              alt=""
                              style={{ width: '60px', height: '70px' }}
                            />
                          </div>
                          <div>
                            <span className="color-box-text">
                              No of orders placed
                            </span>
                            <div className="color-box-number">
                              {kycFactors?.order_count
                                ? kycFactors?.order_count
                                : 0}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex gap-3 mb-4">
                          <div className="icon-color-box-blue">
                            <img
                              src={insightImg4}
                              alt=""
                              style={{ width: '60px', height: '70px' }}
                            />
                          </div>
                          <div>
                            <span className="color-box-text">
                              Total Outstanding
                            </span>
                            <div className="color-box-number">
                              ₹{' '}
                              {totalOutStanding === null ? 0 : totalOutStanding}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex gap-3 mb-4">
                          <div className="icon-color-box-blue">
                            <img
                              src={insightImg6}
                              alt=""
                              style={{ width: '60px', height: '70px' }}
                            />
                          </div>
                          <div>
                            <span className="color-box-text">
                              Average days of payments after 30 days
                            </span>
                            <div className="color-box-number">
                              {merchantInsightData
                                ?.b2b_buy_now_pay_later_5_transactions?.length >
                                0 &&
                              merchantInsightData
                                ?.b2b_buy_now_pay_later_5_transactions[1] !==
                                '' &&
                              merchantInsightData
                                ?.b2b_buy_now_pay_later_5_transactions[1] !==
                                null
                                ? merchantInsightData
                                    ?.b2b_buy_now_pay_later_5_transactions[1]
                                    ?.delayed_average
                                : 0}{' '}
                              Days
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div>
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="d-flex gap-3 mb-4">
                            <div className="icon-color-box-blue">
                              <img
                                src={insightImg1}
                                alt=""
                                style={{ width: '60px', height: '70px' }}
                              />
                            </div>
                            <div>
                              <span className="color-box-text">
                                Total transactions done till date
                              </span>
                              <div className="color-box-number">
                                ₹{' '}
                                {amountFormat(
                                  kycFactors?.total_amount
                                    ? kycFactors?.total_amount
                                    : 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-3 mb-4">
                            <div className="icon-color-box-blue">
                              <img
                                src={insightImg3}
                                alt=""
                                style={{ width: '60px', height: '70px' }}
                              />
                            </div>
                            <div>
                              <span className="color-box-text">
                                Total value of On or Before-time repayment
                              </span>
                              <div className="color-box-number">
                                ₹ {amountFormat(totalOnSetValue)}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-3">
                            <div className="icon-color-box-blue">
                              <img
                                src={insightImg5}
                                alt=""
                                style={{ width: '60px', height: '70px' }}
                              />
                            </div>
                            <div>
                              <span className="color-box-text fw-bolder">
                                Total value of payments after 30 days
                              </span>
                              <div className="color-box-number">
                                ₹{' '}
                                {amountFormat(totalvalueAfter30Days?.total_sp)}
                              </div>
                            </div>
                          </div>
                          {/* Add other content for the first column here */}
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                          <div className="d-flex gap-3 mb-4">
                            <div className="icon-color-box-blue">
                              <img
                                src={insightImg2}
                                alt=""
                                style={{ width: '60px', height: '70px' }}
                              />
                            </div>
                            <div>
                              <span className="color-box-text">
                                No of orders placed
                              </span>
                              <div className="color-box-number">
                                {kycFactors?.order_count
                                  ? kycFactors?.order_count
                                  : 0}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-3 mb-4">
                            <div className="icon-color-box-blue">
                              <img
                                src={insightImg4}
                                alt=""
                                style={{ width: '60px', height: '70px' }}
                              />
                            </div>
                            <div>
                              <span className="color-box-text">
                                Total Outstanding
                              </span>
                              <div className="color-box-number">
                                ₹{' '}
                                {totalOutStanding === null
                                  ? 0
                                  : totalOutStanding}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex gap-3 mb-4">
                            <div className="icon-color-box-blue">
                              <img
                                src={insightImg6}
                                alt=""
                                style={{ width: '60px', height: '70px' }}
                              />
                            </div>
                            <div>
                              <span className="color-box-text">
                                Average days of payments after 30 days
                              </span>
                              <div className="color-box-number">
                                {merchantInsightData
                                  ?.b2b_buy_now_pay_later_5_transactions
                                  ?.length > 0 &&
                                merchantInsightData
                                  ?.b2b_buy_now_pay_later_5_transactions[1] !==
                                  '' &&
                                merchantInsightData
                                  ?.b2b_buy_now_pay_later_5_transactions[1] !==
                                  null
                                  ? merchantInsightData
                                      ?.b2b_buy_now_pay_later_5_transactions[1]
                                      ?.delayed_average
                                  : 0}{' '}
                                Days
                              </div>
                            </div>
                          </div>
                          {/* Add other content for the second column here */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="d-flex justify-content-end mx-5 container self-center"></div>

          <div className="mt-5" style={{ borderRadius: '20px' }}>
            <TableContainer
              classes={{ root: classes.customTableContainer }}
              component={Paper}
              className="insight-table"
            >
              <Table
                stickyHeader
                aria-label="a dense table"
                sx={{ overflowX: 'initial' }}
              >
                <TableHead style={{ position: 'sticky' }}>
                  <TableRow>
                    <TableCell className="fw-bolder">Payment Status</TableCell>
                    <TableCell className="fw-bolder" align="center">
                      Date
                    </TableCell>
                    <TableCell className="fw-bolder" align="center">
                      Order ID
                    </TableCell>
                    <TableCell className="fw-bolder" align="center">
                      Amount
                    </TableCell>
                    <TableCell className="fw-bolder" align="center">
                      Expected Repayment Date
                    </TableCell>
                    <TableCell className="fw-bolder" align="center">
                      Actual Repayment Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        margin: '10px',
                        backgroundColor: rowBgColorHandler(
                          handlePaymentStatus(
                            item?.expected_repayment_date,
                            item?.actual_repayment_date
                          )
                        ),
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {handlePaymentStatus(
                          item?.expected_repayment_date,
                          item?.actual_repayment_date
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        {moment(item.order_placed_date).format('ll')}
                      </TableCell>
                      <TableCell align="center">{item.order_id}</TableCell>
                      <TableCell align="center">
                        {parseFloat(item.amount).toLocaleString()}
                      </TableCell>
                      <TableCell align="center">
                        {item.expected_repayment_date}
                      </TableCell>
                      <TableCell align="center">
                        {item.actual_repayment_date}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientViewMerchantInsight;
